import { getFriendlyFileSize } from "@citrite/citrix-files-ui";
import {
  Account,
  AccountsEntity,
  Api,
  StorageZone,
  StorageZones,
  WebMvcManageZoneEntity,
} from "@citrite/sf-api";
import { sort, SortParams, updateSortParams } from "@citrite/sf-sorting";
import {
  Button,
  Link,
  LoadingPrimary,
  PageTitle,
  SearchInput,
  Table,
  TableContainer,
  TBody,
  TD,
  TH,
  THead,
  THSort,
} from "@sharefiledev/flow-web";
import { useEffect, useState } from "react";
import React from "react";
import { useHistory } from "react-router-dom";

import { fetchTenantList, increaseLicense } from "../../api/services/tenantApi";
import { pendoSendTrackEvent, trackCreateTenant } from "../../tracking";
import {
  ENABLE_AFTER_TIME,
  LICENSE_PERCENTAGE,
  PLAN,
  t,
  TABLE_HEADERS,
} from "../../util";
import { logger } from "../../util/logger";
import { AccountObjIncreaseLicense } from "../../util/types";
import CancelTenantTrial from "../cancel-tenant-or-trial/CancelTenantOrTrial";
import ChangePlan from "../change-plan/ChangePlan";
import { ActionField } from "../common/action-field/ActionField";
import { BadgeComponent } from "../common/badge-component/BadgeComponent";
import { toastNotification } from "../common/toast-notification/toastNotificationHandler";
import {
  Buttons,
  RowStyle,
  Search,
  TotalLicenses,
  TRHover,
} from "./Tenant.styled";
import { tenantFilter } from "./tenantFilter";

export interface TenantType {
  account?: Account;
  storage?: number;
  status?: string;
}

export interface TenantObj {
  accountName?: string;
  licenceUsed?: boolean;
  planName?: string;
  storageZone?: string;
  storageUsed?: string;
  status?: string;
}

type Props = {
  manageZoneUrl: string;
  userContext: {
    get: () => {
      user: any;
      account: any;
    };
  };
};

const { sortDirections } = Api;

const Tenant = (props: Props) => {
  const manageZoneUrl = props.manageZoneUrl;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sortParams, setSortParams] = useState<SortParams>({});
  const [searchString, setSearchString] = useState<string>("");
  const [allTenants, setAllTenants] = useState<TenantType[] | undefined>([]);
  const [multiTenantStorageZones, setMultiTenantStorageZones] = useState<{
    [key: string]: string;
  }>({});
  const [zoneId, setZoneId] = useState<string>("");
  const [cancelAccountModal, setCancelAccountModal] = useState(false);
  const [changePlanModal, setChangePlanModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const history: any = useHistory();

  const getStatus = (account: Account) => {
    if (account?.IsCancelled) {
      return t("tenant-mgt-ui:tenant.cancelled");
    } else if (account?.IsFreeTrial) {
      return t("tenant-mgt-ui:tenant.trial");
    } else {
      return t("tenant-mgt-ui:tenant.paid");
    }
  };

  useEffect(() => {
    if (!isLoading) {
      const _tenants = history.location.state && history.location.state.params;
      if (_tenants && _tenants.length >= 0) {
        setIsLoading(true);
        _tenants && setAllTenants([...[], ..._tenants]);
        setSortParams({
          key: "account.CompanyName",
          direction: sortDirections.DESCENDING,
        });
      } else {
        const tenantsPromise = fetchTenantList(
          props.userContext.get().account.Id
        );
        WebMvcManageZoneEntity.getZones()
          .execute()
          .then((res: StorageZones) => {
            if (
              res &&
              res.MultiTenantZones &&
              res.MultiTenantZones.Zones &&
              res.MultiTenantZones.Zones.length > 0
            ) {
              const _multiTenantStorageZones: { [key: string]: string } = {};
              const zones = res.MultiTenantZones.Zones;
              zones.forEach(({ ZoneId, Title }: StorageZone) => {
                _multiTenantStorageZones[ZoneId] = Title;
              });
              setMultiTenantStorageZones(_multiTenantStorageZones);
            }
          });
        const mtzPromise = AccountsEntity.getZoneUsage().execute();
        Promise.all([tenantsPromise, mtzPromise])
          .then(([tenants, tenantUsage]) => {
            setIsLoading(true);
            const _allTenats: TenantType[] | undefined =
              tenants &&
              tenants.map((account: Account) => ({
                account,
                storage: getStorageUsed(account, tenantUsage),
                status: getStatus(account),
              }));
            _allTenats && setAllTenants(_allTenats);
            setSortParams({
              key: "account.CompanyName",
              direction: sortDirections.DESCENDING,
            });
          })
          .catch(() => setIsLoading(true));
      }
    }
  }, [isLoading]);

  const getStorageUsed = (tenant: Account, tenantZoneUsage: any) => {
    const { Id } = tenant;
    try {
      if (tenantZoneUsage && Id) {
        const tenantKeys = Object.keys(
          tenantZoneUsage.TenantsToZones[Id].ZonesToUsage
        );
        const zoneId = tenantKeys[1];
        setZoneId(zoneId);
        return tenantZoneUsage.TenantsToZones[Id].ZonesToUsage[tenantKeys[1]]
          .TotalFileSizeBytes;
      }
      return;
    } catch (e) {
      return 0;
    }
  };

  const sortItems = (updatedSortParams: SortParams) => {
    setSortParams(updateSortParams(sortParams, updatedSortParams));
  };

  const onFilteredTenants = () => {
    const properties = [
      "account.CompanyName",
      "account.Preferences.DefaultZone.Name",
    ];
    if (allTenants) {
      const itemsToSort = tenantFilter<TenantType>(
        searchString,
        properties,
        allTenants
      );
      return sort(itemsToSort, sortParams);
    }
    return [];
  };

  const getStorageUsageString = (tenant: TenantType) => {
    if (tenant && tenant.storage && tenant.storage > 0) {
      return getFriendlyFileSize(tenant.storage);
    }
    return t("tenant-mgt-ui:tenant.none");
  };

  const licensesAvailable = () => {
    const totalUsed =
      allTenants &&
      allTenants.reduce((prev, next) => {
        if (
          next &&
          next.account &&
          next.account.UserUsage &&
          next.account.UserUsage.EmployeeCount
        ) {
          return prev + next.account.UserUsage.EmployeeCount;
        }
        return 0;
      }, 0);
    const totalLicenses =
      allTenants &&
      allTenants.reduce((prev, next) => {
        if (
          next &&
          next.account &&
          next.account.UserUsage &&
          next.account.UserUsage.EmployeeMax
        ) {
          return prev + next.account.UserUsage.EmployeeMax;
        }
        return 0;
      }, 0);
    return `${totalUsed}/${totalLicenses}`;
  };

  const getZoneName = (tenant: TenantType) => {
    return tenant.storage > 0 ? multiTenantStorageZones[zoneId] : "";
  };

  if (!isLoading) {
    return <LoadingPrimary />;
  }

  const filteredTenants = onFilteredTenants();

  const addTenant = () => {
    pendoSendTrackEvent(trackCreateTenant);
    history.push({
      pathname: `${window.location.pathname}/add-tenant`,
      state: {
        params: allTenants,
      },
    });
  };

  const downloadFile = (data: string) => {
    const blob = new Blob([data], { type: "text/csv" });

    const a = document.createElement("a");
    a.download = `${t("tenant-mgt-ui:tenant.fileName")}.csv`;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const createFlatJson = (data: any) => {
    const flatArray = [];
    for (const val of data) {
      const obj: TenantObj = {};
      obj.accountName = val.account?.CompanyName;
      obj.licenceUsed = val.account?.UserUsage.EmployeeCount;
      obj.planName = renderPlanName(val.account?.PlanName);
      obj.storageZone = getZoneName(val);
      obj.storageUsed = getStorageUsageString(val);
      obj.status = getStatus(val.account);
      flatArray.push(obj);
    }
    return flatArray;
  };

  const downloadAsCsv = (e: any) => {
    e.preventDefault();
    const initialArray: string[] = [];
    const tableData = createFlatJson(filteredTenants);
    const headers = [TABLE_HEADERS.join(",")];
    const tenants = tableData.reduce((acc, tenant) => {
      const {
        accountName,
        status,
        licenceUsed,
        planName,
        storageZone,
        storageUsed,
      } = tenant;
      const details = [
        accountName,
        status,
        licenceUsed,
        planName,
        storageZone,
        storageUsed,
      ].join(",");
      acc.push(details);
      return acc;
    }, initialArray);
    const data = [...headers, ...tenants].join("\n");
    downloadFile(data);
  };

  const renderPlanName = (planName = "") => {
    const filteredPlanName = PLAN.filter((plan) => plan.value === planName);
    if (filteredPlanName.length > 0) {
      return filteredPlanName[0].title;
    }
    return planName;
  };

  const cancelTenant = (tenant: any) => {
    setSelectedItem(tenant);
    setCancelAccountModal(true);
  };

  const handleModalClose = (bool: boolean) => {
    setSelectedItem({});
    setCancelAccountModal(false);
    setChangePlanModal(false);
    if (bool) {
      setIsLoading(!bool);
    }
  };

  const changePlan = (tenant: any) => {
    setSelectedItem(tenant);
    setChangePlanModal(true);
  };

  const enableAfterTime = (tenant: any) => {
    let label = "";
    const addedMinutesToCreationDate =
      Date.parse(tenant?.account?.CreationDate) + ENABLE_AFTER_TIME * 60000;
    const currentDateInMillis = new Date().getTime();

    if (currentDateInMillis >= addedMinutesToCreationDate) {
      if (tenant?.account?.IsFreeTrial) {
        label = t("tenant-mgt-ui:modal.convertToPaid");
      } else {
        label = t("tenant-mgt-ui:modal.changePlan");
      }
    }
    return label;
  };

  const handleIncreaseLicenses = async (tenant: any) => {
    try {
      const { Id, UserMax } = tenant.account;
      const accountDetails: AccountObjIncreaseLicense = {
        Id: Id,
        UserMax: UserMax,
      };

      setIsLoading(true);
      const res: any = await increaseLicense(accountDetails);
      if (res) {
        setIsLoading(false);
        toastNotification({
          message: `Licenses increased successfully`,
          type: "SUCCESS",
        });
        logger.logInfo(`Licenses increased successfully`);
      } else {
        logger.logError(`Error while increasing licenses`);
      }
    } catch (err) {
      setIsLoading(false);
      logger.logError(`Error while increasing licenses: ${err}`);
      toastNotification({ message: `${err}`, type: "ERROR" });
    }
  };

  const enableIncreaseLicenseOption = (tenant: any) => {
    const employeeCount = tenant?.account?.UserUsage?.EmployeeCount;
    const userMax = tenant?.account?.UserMax;
    const ratio = (employeeCount / userMax) * 100;
    return ratio >= LICENSE_PERCENTAGE
      ? t("tenant-mgt-ui:modal.increaseLicense")
      : "";
  };

  const renderRow = (tenant: any) => {
    const dropdownOpts = [
      {
        children: tenant?.account?.IsFreeTrial
          ? t("tenant-mgt-ui:modal.cancelTrial")
          : t("tenant-mgt-ui:modal.cancelAccount"),
        onClick: () => cancelTenant(tenant),
      },
      {
        children: enableAfterTime(tenant),
        onClick: () => changePlan(tenant),
      },
      {
        children: enableIncreaseLicenseOption(tenant),
        onClick: () => handleIncreaseLicenses(tenant),
      },
    ];

    return (
      <TRHover
        key={tenant && tenant.account && tenant.account.Id}
        data-testid="table-tr"
      >
        <TD label={t("tenant-mgt-ui:tenant.account_name")}>
          <RowStyle isCancelled={tenant?.account?.IsCancelled}>
            <a
              target="_blank"
              href={`https://${tenant?.account?.Subdomain}.${tenant?.account?.ProductDefaults?.TopLevelDomain}`}
              rel="noreferrer"
            >
              {tenant.account && tenant.account.CompanyName}
            </a>
          </RowStyle>
        </TD>
        <TD label={t("tenant-mgt-ui:tenant.status")}>
          <RowStyle isCancelled={tenant?.account?.IsCancelled}>
            <BadgeComponent status={tenant?.status} />
          </RowStyle>
        </TD>
        <TD label={t("tenant-mgt-ui:tenant.license_used")}>
          <RowStyle isCancelled={tenant?.account?.IsCancelled}>
            <span>
              {tenant.account &&
                tenant.account.UserUsage &&
                tenant.account.UserUsage.EmployeeCount}
            </span>
          </RowStyle>
        </TD>
        <TD label={t("tenant-mgt-ui:tenant.planName")}>
          <RowStyle isCancelled={tenant?.account?.IsCancelled}>
            <span>{renderPlanName(tenant.account?.PlanName)}</span>
          </RowStyle>
        </TD>
        <TD label={t("tenant-mgt-ui:tenant.storage_zone")}>
          <RowStyle isCancelled={tenant?.account?.IsCancelled}>
            {tenant.account &&
            tenant.account.Preferences &&
            tenant.account.Preferences.DefaultZone &&
            tenant.account.Preferences.DefaultZone.IsMultiTenant ? (
              <Link.Secondary
                to={`${manageZoneUrl}/${tenant.account.Preferences.DefaultZone.Id}`}
              >
                {getZoneName(tenant)}
              </Link.Secondary>
            ) : (
              <span>{getZoneName(tenant)}</span>
            )}
          </RowStyle>
        </TD>
        <TD label={t("tenant-mgt-ui:tenant.storage_used")}>
          <RowStyle isCancelled={tenant?.account?.IsCancelled}>
            <span>{getStorageUsageString(tenant)}</span>
          </RowStyle>
        </TD>
        <TD label="options" data-testid="table-options">
          <RowStyle isCancelled={tenant?.account?.IsCancelled}>
            {!tenant?.account?.IsCancelled && (
              <ActionField options={dropdownOpts} />
            )}
          </RowStyle>
        </TD>
      </TRHover>
    );
  };

  return (
    <div>
      <PageTitle>{t("tenant-mgt-ui:tenant.tenant_management")}</PageTitle>
      <div>
        <span>{t("tenant-mgt-ui:tenant.contact_support")}</span>
        <a
          href="https://bit.ly/cspsharefile"
          target="_blank"
          rel="noopener noreferrer"
          style={{ margin: "0 4px" }}
        >
          {t("tenant-mgt-ui:tenant.contact_support_link")}
        </a>
      </div>
      <Search data-testid="search-tenant">
        <SearchInput
          placeholder={t("tenant-mgt-ui:tenant.search_tenants")}
          onChange={(value) => setSearchString(value)}
          width="300px"
          value={searchString}
        />
        <Buttons>
          <Button.Secondary onClick={addTenant}>
            {t("tenant-mgt-ui:tenant.add_tenant")}
          </Button.Secondary>

          {allTenants && allTenants.length > 0 && (
            <Button.Secondary onClick={(e) => downloadAsCsv(e)}>
              {t("tenant-mgt-ui:tenant.download_csv")}
            </Button.Secondary>
          )}
        </Buttons>
      </Search>
      <TableContainer>
        <Table>
          <THead>
            <THSort
              sortKey="account.CompanyName"
              initialDirection={sortDirections.DESCENDING}
              sortParams={sortParams}
              sort={sortItems}
            >
              {t("tenant-mgt-ui:tenant.account_name")}
            </THSort>
            <THSort
              sortKey="status"
              initialDirection={sortDirections.DESCENDING}
              sortParams={sortParams}
              sort={sortItems}
            >
              {t("tenant-mgt-ui:tenant.status")}
            </THSort>
            <THSort
              sortKey="account.UserUsage.EmployeeCount"
              initialDirection={sortDirections.DESCENDING}
              sortParams={sortParams}
              sort={sortItems}
            >
              {t("tenant-mgt-ui:tenant.license_used")}
            </THSort>
            <THSort
              sortKey="account.PlanName"
              initialDirection={sortDirections.DESCENDING}
              sortParams={sortParams}
              sort={sortItems}
            >
              {t("tenant-mgt-ui:tenant.planName")}
            </THSort>
            <THSort
              sortKey="account.Preferences.DefaultZone.Name"
              initialDirection={sortDirections.DESCENDING}
              sortParams={sortParams}
              sort={sortItems}
            >
              {t("tenant-mgt-ui:tenant.storage_zone")}
            </THSort>
            <THSort
              sortKey="storage"
              initialDirection={sortDirections.DESCENDING}
              sortParams={sortParams}
              sort={sortItems}
              width="100px"
            >
              {t("tenant-mgt-ui:tenant.storage_used")}
            </THSort>
            <TH />
          </THead>
          <TBody>
            {filteredTenants &&
              filteredTenants.map((tenant) => renderRow(tenant))}
          </TBody>
        </Table>
      </TableContainer>
      <p>
        <span>{t("tenant-mgt-ui:tenant.tenants")}</span>
        <span>{allTenants && allTenants.length}</span>
        <TotalLicenses>
          {t("tenant-mgt-ui:tenant.total_licenses")}
          &nbsp;
          {licensesAvailable()}
        </TotalLicenses>
        {searchString && (
          <span>
            {t("tenant-mgt-ui:tenant.matching", {
              keyword: searchString,
            })}
          </span>
        )}
      </p>
      {cancelAccountModal && (
        <CancelTenantTrial data={selectedItem} onClose={handleModalClose} />
      )}
      {changePlanModal && (
        <ChangePlan data={selectedItem} onClose={handleModalClose} />
      )}
    </div>
  );
};

export default Tenant;
