interface Window {
  pendo?: any;
}

// todo move this to piral appshell extension
export const pendoSendTrackEvent = (event: string, metadata: any = {}) => {
  try {
    const pendo = (window as unknown as Window).pendo;
    if (!pendo?.track) {
      return;
    }
    const metadataWithProductName = {
      ...metadata,
      product: "ShareFile - TenantManangement",
      type: event,
    };
    pendo.track(event, metadataWithProductName);
  } catch (error) {
    alert("An error occurred" + error);
  }
};
