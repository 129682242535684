import axios from "axios";

import { API_PATH } from "./constants/constants";

const apiClient = axios.create({
  baseURL: window.origin + API_PATH,
  headers: {
    "X-BFF-CSRF": "true",
  },
});

apiClient.interceptors.response.use(
  (response) => {
    if (response.data && Array.isArray(response.data)) {
      return response.data.map((item) => {
        return {
          ...item,
          due_date:
            item.due_date && item.due_date !== 0
              ? new Date(item.due_date * 1000)
              : null,
        };
      });
    } else {
      return response.data;
    }
  },
  (error) => {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else if (error.request) {
      throw new Error("No response received");
    } else {
      throw new Error("Request setup error");
    }
  }
);

export default apiClient;
