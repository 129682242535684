import { TextField } from "@sharefiledev/flow-web";
import React from "react";

import { MINIMUM_PHONE_NUMBER_LENGTH, t } from "../../../util";
import { Container, Label, PhoneNumberField } from "./PhoneNumber.styled";

type Props = {
  countryCode: string;
  phoneNumber: string;
  label: string;
  required: boolean;
  onChange: (val: string) => void;
  showCharacterLimit: boolean;
  maxLength: number | undefined;
};

const PhoneNumber = (props: Props) => {
  const {
    phoneNumber,
    required,
    onChange,
    label,
    countryCode,
    showCharacterLimit,
    maxLength,
  } = props;
  return (
    <>
      <Label>
        {label} {required && <span>*</span>}
      </Label>
      <Container>
        <TextField label="" maxWidth={70} value={countryCode} readOnly />
        <PhoneNumberField data-testid="phone_number">
          <TextField
            label=""
            value={phoneNumber}
            required={required}
            onChange={onChange}
            warningMessage={
              phoneNumber.trim().length > 0 &&
              phoneNumber.length < MINIMUM_PHONE_NUMBER_LENGTH
                ? t(`tenant-mgt-ui:phoneNumber.minCharacters`)
                : ""
            }
            maxLength={maxLength}
            showCharacterLimit={showCharacterLimit}
          />
        </PhoneNumberField>
      </Container>
    </>
  );
};

export default PhoneNumber;
