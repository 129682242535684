import { ReactNode } from "react";
import React from "react";

import { FormContext, IFormContext } from "./FormContext";

interface Props extends IFormContext {
  children: ReactNode;
}

export const FormContextProvider = (props: Props) => {
  return (
    <FormContext.Provider
      value={{
        tenantInfo: props.tenantInfo,
        onTenantInfo: props.onTenantInfo,
        isFinishBtnClicked: props.isFinishBtnClicked,
        onFinishBtnClick: props.onFinishBtnClick,
        userContext: props.userContext,
      }}
    >
      {props.children}
    </FormContext.Provider>
  );
};
