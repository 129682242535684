/* istanbul ignore file */
import { createScopedInstance, getLanguage } from "@citrite/translate";

import { languages } from "../locales";

export { getLanguage };

export const { t: tmp } = createScopedInstance({
  resources: languages,
  namespace: "tenant-mgt-ui",
});

export const t: (str: string, opts?: any) => string = tmp;
