import { Link, ThemeColor, useThemeColor } from "@sharefiledev/flow-web";
import React, { useMemo, useState } from "react";

import { changePlan, convertTrialToPaid } from "../../api/services/tenantApi";
import {
  pendoSendTrackEvent,
  trackChangePlanInitiated,
  trackChangePlanSuccess,
  trackTrialToPaidInitiated,
  trackTrialToPaidSuccess,
} from "../../tracking";
import {
  ADVANCED_PLAN,
  ADVANCED_PLAN_ENT,
  PLANS,
  PREMIUM_PLAN_ENT,
  STANDARD_PLAN,
  STANDARD_PLAN_ENT,
  t,
} from "../../util";
import { logger } from "../../util/logger";
import { AccountObjChangePlan, AccountObjTrialUpgrade } from "../../util/types";
import { ModalTemplate } from "../common/modal-template/ModalTemplate";
import RadioGroup, { RadioGroupItem } from "../common/radio-group/RadioGroup";
import { toastNotification } from "../common/toast-notification/toastNotificationHandler";
import { SectionText, Text } from "./ChangePlan.styled";

type Props = {
  data?: any;
  onClose?(bool: boolean): void;
};

const ChangePlan = ({ data, onClose }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [plan, setPlan] = useState<string>(data?.account?.PlanName);
  const IsFreeTrial = data?.account?.IsFreeTrial;

  const metadataTextColor = useThemeColor(ThemeColor.metadataText);
  const secondaryContainerBackgroundColor = useThemeColor(
    ThemeColor.secondaryContainerBackground
  );
  const warningBorderColor = useThemeColor(ThemeColor.warningBorder);
  const warningBackgroundColor = useThemeColor(ThemeColor.warningBackground);
  const successBorderColor = useThemeColor(ThemeColor.successBorder);
  const successBackgroundColor = useThemeColor(ThemeColor.successBackground);

  const planPriority: { [key: string]: number } = {
    "ShareFile SO Standard": 1,
    "ShareFile SO Advanced": 2,
    "ShareFile SO Premium": 3,
  };

  const header = IsFreeTrial
    ? t("tenant-mgt-ui:modal.convertToPaid")
    : t("tenant-mgt-ui:modal.changePlan");

  const buttonText = IsFreeTrial
    ? {
        saveButton: t("tenant-mgt-ui:modal.convertAccount"),
        closeButton: t("tenant-mgt-ui:modal.cancel"),
      }
    : {
        saveButton: t("tenant-mgt-ui:modal.changePlan"),
        closeButton: t("tenant-mgt-ui:modal.cancel"),
      };

  const handlePlanChange = async () => {
    const {
      Id,
      BaseUsers,
      PlanTrack,
      IsFreeTrial,
      AdditionalUsers,
      UserMax,
      Address1,
      Address2,
      City,
      Zip,
      State,
      Country,
      UserUsage,
    } = data.account;

    if (IsFreeTrial) {
      const accountDetailsTrial: AccountObjTrialUpgrade = {
        Id: Id,
        PlanName: plan,
        PlanTrack: PlanTrack,
        EmployeeCount: UserUsage.EmployeeCount,
        AdditionalUsers: AdditionalUsers,
        UserMax: UserMax,
        Address1: Address1,
        Address2: Address2,
        City: City,
        State: State,
        Zip: Zip,
        Country: Country,
      };
      await handleConvertTrialToPaid(accountDetailsTrial);
    } else {
      const accountDetails: AccountObjChangePlan = {
        Id: Id,
        PlanName: plan,
        Code:
          plan === STANDARD_PLAN
            ? STANDARD_PLAN_ENT
            : plan === ADVANCED_PLAN
            ? ADVANCED_PLAN_ENT
            : PREMIUM_PLAN_ENT,
        BaseUsers: BaseUsers,
        AdditionalUsers: AdditionalUsers,
        UserMax: UserMax,
      };
      await handleChangePlan(accountDetails);
    }
  };

  const handleConvertTrialToPaid = async (
    accountDetails: AccountObjTrialUpgrade
  ) => {
    setIsLoading(true);
    pendoSendTrackEvent(trackTrialToPaidInitiated);
    try {
      const res: any = await convertTrialToPaid(accountDetails);
      if (res) {
        pendoSendTrackEvent(trackTrialToPaidSuccess);
        setIsLoading(false);
        toastNotification({
          message: `Account converted to paid successfully`,
          type: "SUCCESS",
        });
        logger.logInfo(`Account converted to paid successfully`);
        closeModal(true);
      } else {
        closeModal();
        logger.logError(`Error while converting account`);
      }
    } catch (err) {
      closeModal();
      setIsLoading(false);
      logger.logError(`Error while converting account: ${err}`);
      toastNotification({ message: `${err}`, type: "ERROR" });
    }
  };

  const handleChangePlan = async (accountDetails: AccountObjChangePlan) => {
    setIsLoading(true);
    pendoSendTrackEvent(trackChangePlanInitiated);
    try {
      const res: any = await changePlan(accountDetails);
      if (res) {
        pendoSendTrackEvent(trackChangePlanSuccess);
        setIsLoading(false);
        toastNotification({
          message: `Plan changed successfully`,
          type: "SUCCESS",
        });
        logger.logInfo(`Plan changed successfully`);
        closeModal(true);
      } else {
        closeModal();
        logger.logError(`Error while changing the plan`);
      }
    } catch (err) {
      closeModal();
      setIsLoading(false);
      logger.logError(`Error while changing the plan: ${err}`);
      toastNotification({ message: `${err}`, type: "ERROR" });
    }
  };

  const closeModal = (bool = false) => {
    onClose && onClose(bool);
  };

  const checkCurrentPlan = useMemo(
    () => (item: RadioGroupItem) => {
      return !IsFreeTrial && planPriority[item.value] === planPriority[plan];
    },
    []
  );

  const modifyPlanItem = useMemo(
    () => (item: RadioGroupItem) => {
      let badgeText = "";
      let badgeColor = "";
      let badgeBackgroundColor = "";
      let _item = item;
      if (planPriority[item.value] === planPriority[plan]) {
        badgeText = "No Change";
        badgeColor = metadataTextColor;
        badgeBackgroundColor = secondaryContainerBackgroundColor;
      } else if (planPriority[item.value] < planPriority[plan]) {
        badgeText = "Downgrade";
        badgeColor = warningBorderColor;
        badgeBackgroundColor = warningBackgroundColor;
      } else if (planPriority[item.value] > planPriority[plan]) {
        badgeText = "Upgrade";
        badgeColor = successBorderColor;
        badgeBackgroundColor = successBackgroundColor;
      }
      _item = {
        ...item,
        badgeText: badgeText,
        badgeColor: badgeColor,
        badgeBackgroundColor: badgeBackgroundColor,
      };
      return _item;
    },
    []
  );

  const getHtmlObject = (text: string) => ({
    __html:
      text &&
      text.replace(
        "{accountName}",
        `<b>${data.account && data.account.CompanyName}</b>`
      ),
  });

  return (
    <>
      {
        <ModalTemplate
          isOpen
          header={header}
          buttonProps={{
            saveButtonProps: {
              buttonText: buttonText.saveButton,
              onClick: handlePlanChange,
              active: isLoading,
            },
            cancelButtonProps: {
              buttonText: buttonText.closeButton,
              onClick: () => closeModal(),
              disabled: isLoading,
            },
          }}
        >
          <>
            <Text>
              {IsFreeTrial ? (
                <div
                  dangerouslySetInnerHTML={getHtmlObject(
                    t("tenant-mgt-ui:modal.convertPlanBody.convertAccount")
                  )}
                />
              ) : (
                <div
                  dangerouslySetInnerHTML={getHtmlObject(
                    t("tenant-mgt-ui:modal.convertPlanBody.changeAccount")
                  )}
                />
              )}
            </Text>

            {PLANS &&
              PLANS.map((item: RadioGroupItem) => {
                return (
                  <div key={item.value}>
                    <RadioGroup
                      item={modifyPlanItem(item)}
                      selectedItem={plan}
                      onClick={() => setPlan(item.value)}
                      isError={plan.trim().length === 0}
                      isDisabled={checkCurrentPlan(item)}
                    />
                  </div>
                );
              })}
            <br />
            <SectionText>
              {t("tenant-mgt-ui:accountDetails.plan.subTitle")}{" "}
              <Link.Primary
                href="https://www.sharefile.com/pricing"
                target="_blank"
              >
                {t("tenant-mgt-ui:accountDetails.plan.learnAboutPalnTypes")}
              </Link.Primary>
            </SectionText>
          </>
        </ModalTemplate>
      }
    </>
  );
};

export default ChangePlan;
