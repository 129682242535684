import { PiletApi } from "@sharefiledev/sharefile-appshell";
import React from "react";
import {
  Route,
  Router as ReactRouter,
  Switch,
  useHistory,
} from "react-router-dom";

import AddTenantForm from "../components/add-tenant-form/AddTenantForm";
import Tenant from "../components/tenant/Tenant";

type Props = {
  manageZoneUrl: string;
  app: PiletApi;
};

const AppRoute = (props: Props) => {
  const piletUserContext =
    props && props.app && props.app.sf && props.app.sf.piletUserContext;
  return (
    <ReactRouter history={useHistory()}>
      <Switch>
        <Route
          path="/settings/admin/advancedpreferences/managetenants/add-tenant"
          component={() => <AddTenantForm userContext={piletUserContext} />}
        />
        <Route
          path="/settings/admin/advancedpreferences/managetenants"
          component={() => (
            <Tenant
              userContext={piletUserContext}
              manageZoneUrl={props.manageZoneUrl}
            />
          )}
        />
      </Switch>
    </ReactRouter>
  );
};

export default AppRoute;
