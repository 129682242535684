import styled from "@emotion/styled";
import { ModalBody } from "@sharefiledev/flow-web";

export const Header = styled.h2`
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 29px;
  line-height: 34px;
  display: flex;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonGroupLeft = styled.div`
  padding: 12px;
`;

export const ModalBodyWrapper = styled(ModalBody)`
  margin-top: 10px;
  margin-bottom: 15px;
`;
