import {
  Button,
  DrawerToggleIcon,
  Dropdown,
  DropdownItem,
} from "@sharefiledev/flow-web";
import React from "react";

import { DropdownWrapper } from "./ActionField.styled";

type actonFieldProps = {
  options: DropdownItem[];
};

export const ActionField = ({ options }: actonFieldProps) => {
  return (
    <DropdownWrapper
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <Dropdown options={options}>
        {(buttonProps) => (
          <Button.IconOnly
            {...buttonProps}
            title=""
            icon={DrawerToggleIcon}
            size={16}
          />
        )}
      </Dropdown>
    </DropdownWrapper>
  );
};
