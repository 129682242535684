import styled from "@emotion/styled";
import { fromThemeColors, layout, ThemeColor } from "@sharefiledev/flow-web";

export const StyledSection = styled.div<{ isDefaultOpen: boolean }>`
  border: 1px solid ${fromThemeColors(ThemeColor.border)};
  padding: ${layout.mediumSpace};
  box-shadow: 2px 2px 2px 0px ${fromThemeColors(ThemeColor.border)};
  border-radius: 4px;
  > button {
    width: 100%;
    cursor: ${(props) => (props.isDefaultOpen ? "default" : "cursor")};
    cursor: ;
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

export const CollapsableSubTitle = styled.span`
  font-size: 14px;
`;
export const SectionTitle = styled.span`
  font-size: 18px;
`;
