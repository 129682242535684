import {
  notifyError,
  notifyInfo,
  notifySuccess,
  notifyWarning,
} from "@sharefiledev/flow-web";

import { DEFAULT_ERROR_NOTIFICATION_TIMEOUT } from "../../../util/constants";

type Prop = {
  message: string;
  type?: "INFO" | "WARNING" | "SUCCESS" | "ERROR";
};

export const toastNotification = ({ message, type }: Prop) => {
  switch ((type || "").toUpperCase()) {
    case "INFO": {
      notifyInfo(message);
      break;
    }
    case "SUCCESS": {
      notifySuccess(message);
      break;
    }
    case "ERROR": {
      notifyError(message, { timeout: DEFAULT_ERROR_NOTIFICATION_TIMEOUT });
      break;
    }
    case "WARNING": {
      notifyWarning(message);
      break;
    }
    default: {
      notifyInfo(message);
    }
  }
};
