/* tslint:disable */
/* This file was generated by @citrite/translate. Manual changes will be lost. */
import { Language } from "@citrite/translate";
export type locale =
  | "de"
  | "en"
  | "es"
  | "fr"
  | "it"
  | "ja"
  | "ko"
  | "nl"
  | "pt-BR"
  | "ru"
  | "zh-CN";
export const languages: Language[] = [
  {
    key: "de",
    content: {
      accountDetails: {
        accountType: {
          paidService: "Kostenpflichtiger Service",
          subTitle:
            "Den Benutzern zugewiesene Lizenzen werden Ihnen in Rechnung gestellt.",
          title: "Kontotyp",
          trial: "Testversion",
          trialInfo: "30-Tage-Testversion mit begrenzter Speicherkapazität",
        },
        plan: {
          advanced: "Advanced",
          learnAboutPalnTypes: "Info zu Abonnementtypen",
          premium: "Premium",
          standard: "Standard",
          subTitle: "Benötigen Sie Hilfe bei der Auswahl?",
          title: "Abonnement",
        },
        subDomain: {
          checkAvailability: "Verfügbarkeit prüfen",
          checkAvailabilityApiErrorMsg:
            "Die angegebene Unterdomäne wird von einem anderen Konto verwendet",
          checkAvailabilityApiSuccessMsg: "Verfügbar",
          controlPlane: "Steuerungsebene",
          options: {
            "0": "Wählen Sie Ihr Land",
            "1": "USA",
            "2": "Europa (Deutschland)",
          },
          subTitle:
            "Ihre Unterdomäne ist die eindeutige URL für Ihr ShareFile-Konto. Sie können dies später ändern.",
          title: "Unterdomäne",
        },
        supportInfo: {
          areaCode: "Vorwahl",
          countryCode: "Land",
          email: "E-Mail",
          enableSupportInfo: "Support-Kontaktdaten für Mandanten bereitstellen",
          name: "Name",
          phoneNumber: "Telefonnummer",
          subTitle:
            "Geben Sie hier die Support-Kontaktinformationen für Mandanten ein. Bei der Eingabe sind alle Felder erforderlich.",
          title: "Supportinformationen",
        },
        title: "Kontodetails",
      },
      addTenant: "Mandant hinzufügen",
      addTenantInfo: {
        adminDetails: {
          email: "E-Mail",
          firstName: "Vorname",
          lastName: "Nachname",
          title: "Hauptadministrator",
        },
        bussinessDetails: {
          address: "Adresse",
          areaCode: "Vorwahl",
          city: "Ort",
          companyName: "Firmenname",
          country: "Land",
          phoneNumber: "Telefonnummer",
          postalCode: "Postleitzahl",
          postalCodeErrorMsg: "Ungültiger Code",
          state: "Bundesland/Kanton",
          title: "Unternehmensdetails",
        },
        subTitle: "Geben Sie Administrator- und Mandantendetails ein",
        termsOfService: "Ich habe Folgendes gelesen, verstanden und stimme zu:",
        termsOfServiceLinkText: "Nutzungsbedingungen",
        title: "Mandanteninformationen hinzufügen",
      },
      addTenantSubTitle:
        "Gehen Sie wie folgt vor, um einen Mandanten hinzuzufügen.",
      addTenantSuccesMsg: "Mandant erfolgreich erstellt",
      backBtn: "Zurück",
      country: {
        AC: "Ascension Island",
        AD: "Andorra",
        AE: "Vereinigte Arabische Emirate",
        AF: "Afghanistan",
        AG: "Antigua und Barbuda",
        AI: "Anguilla",
        AL: "Albanien",
        AM: "Armenien",
        AO: "Angola",
        AQ: "Antarktis",
        AR: "Argentinien",
        AS: "Amerikanisch-Samoa",
        AT: "Österreich",
        AU: "Australien",
        AW: "Aruba",
        AX: "Alandinseln",
        AZ: "Aserbaidschan",
        BA: "Bosnien und Herzegowina",
        BB: "Barbados",
        BD: "Bangladesch",
        BE: "Belgien",
        BF: "Burkina Faso",
        BG: "Bulgarien",
        BH: "Bahrain",
        BI: "Burundi",
        BJ: "Benin",
        BL: "Saint-Barthélemy",
        BM: "Bermuda",
        BN: "Brunei Darussalam",
        BO: "Bolivien",
        BQ: "Bonaire, Sint Eustatius und Saba",
        BR: "Brasilien",
        BS: "Bahamas",
        BT: "Bhutan",
        BV: "Bouvetinsel",
        BW: "Botsuana",
        BY: "Belarus",
        BZ: "Belize",
        CA: "Kanada",
        CC: "Kokosinseln (Keeling)",
        CD: "Kongo, Demokratische Republik",
        CF: "Zentralafrikanische Republik",
        CG: "Kongo",
        CH: "Schweiz",
        CI: "Cote d'Ivoire",
        CK: "Cookinseln",
        CL: "Chile",
        CM: "Kamerun",
        CN: "China",
        CO: "Kolumbien",
        CR: "Costa Rica",
        CU: "Kuba",
        CV: "Kap Verde",
        CW: "Curaçao",
        CX: "Weihnachtsinsel",
        CY: "Zypern",
        CZ: "Tschechische Republik",
        DE: "Deutschland",
        DJ: "Dschibuti",
        DK: "Dänemark",
        DM: "Dominica",
        DO: "Dominikanische Republik",
        DZ: "Algerien",
        EC: "Ecuador",
        EE: "Estland",
        EG: "Ägypten",
        EH: "Westsahara",
        ER: "Eritrea",
        ES: "Spanien",
        ET: "Äthiopien",
        FI: "Finnland",
        FJ: "Fidschi",
        FK: "Falklandinseln",
        FM: "Föderierte Staaten von Mikronesien",
        FO: "Färöer",
        FR: "Frankreich",
        GA: "Gabun",
        GB: "Großbritannien",
        GD: "Grenada",
        GE: "Georgien",
        GF: "Französisch-Guayana",
        GG: "Guernsey",
        GH: "Ghana",
        GI: "Gibraltar",
        GL: "Grönland",
        GM: "Gambia",
        GN: "Guinea",
        GP: "Guadeloupe",
        GQ: "Äquatorialguinea",
        GR: "Griechenland",
        GS: "Südgeorgien und die Südlichen Sandwichinseln",
        GT: "Guatemala",
        GU: "Guam",
        GW: "Guinea-Bissau",
        GY: "Guyana",
        HK: "Hongkong",
        HM: "Heard und McDonaldinseln",
        HN: "Honduras",
        HR: "Kroatien",
        HT: "Haiti",
        HU: "Ungarn",
        ID: "Indonesien",
        IE: "Irland",
        IL: "Israel",
        IM: "Insel Man",
        IN: "Indien",
        IO: "Britisches Territorium im Indischen Ozean",
        IQ: "Irak",
        IR: "Iran",
        IS: "Island",
        IT: "Italien",
        JE: "Jersey",
        JM: "Jamaika",
        JO: "Jordanien",
        JP: "Japan",
        KE: "Kenia",
        KG: "Kirgisistan",
        KH: "Kambodscha",
        KI: "Kiribati",
        KM: "Komoren",
        KN: "St. Kitts und Nevis",
        KP: "Nordkorea",
        KR: "Südkorea",
        KW: "Kuwait",
        KY: "Kaimaninseln",
        KZ: "Kasachstan",
        LA: "Laos",
        LB: "Libanon",
        LC: "St. Lucia",
        LI: "Liechtenstein",
        LK: "Sri Lanka",
        LR: "Liberia",
        LS: "Lesotho",
        LT: "Litauen",
        LU: "Luxemburg",
        LV: "Lettland",
        LY: "Libyen",
        MA: "Marokko",
        MC: "Monaco",
        MD: "Moldau",
        ME: "Montenegro",
        MF: "Saint Martin (französischer Teil)",
        MG: "Madagaskar",
        MH: "Marshallinseln",
        MK: "Nordmazedonien",
        ML: "Mali",
        MM: "Myanmar",
        MN: "Mongolei",
        MO: "Macao",
        MP: "Nördliche Marianen",
        MQ: "Martinique",
        MR: "Mauretanien",
        MS: "Montserrat",
        MT: "Malta",
        MU: "Mauritius",
        MV: "Malediven",
        MW: "Malawi",
        MX: "Mexiko",
        MY: "Malaysia",
        MZ: "Mosambik",
        NA: "Namibia",
        NC: "Neukaledonien",
        NE: "Niger",
        NF: "Norfolkinsel",
        NG: "Nigeria",
        NI: "Nicaragua",
        NL: "Niederlande",
        NO: "Norwegen",
        NP: "Nepal",
        NR: "Nauru",
        NU: "Niue",
        NZ: "Neuseeland",
        OM: "Oman",
        OS: "Südossetien",
        PA: "Panama",
        PE: "Peru",
        PF: "Französisch-Polynesien",
        PG: "Papua-Neuguinea",
        PH: "Philippinen",
        PK: "Pakistan",
        PL: "Polen",
        PM: "St. Pierre und Miquelon",
        PN: "Pitcairn",
        PR: "Puerto Rico",
        PS: "Palästina",
        PT: "Portugal",
        PW: "Palau",
        PY: "Paraguay",
        QA: "Katar",
        RE: "Reunion",
        RO: "Rumänien",
        RS: "Serbien",
        RU: "Russland",
        RW: "Ruanda",
        SA: "Saudi-Arabien",
        SB: "Salomonen",
        SC: "Seychellen",
        SD: "Sudan",
        SE: "Schweden",
        SG: "Singapur",
        SH: "St. Helena",
        SI: "Slowenien",
        SJ: "Spitzbergen und Jan Mayen",
        SK: "Slowakei",
        SL: "Sierra Leone",
        SM: "San Marino",
        SN: "Senegal",
        SO: "Somalia",
        SR: "Surinam",
        SS: "Südsudan",
        ST: "Sao Tome und Principe",
        SV: "El Salvador",
        SX: "Sint Maarten",
        SY: "Syrien",
        SZ: "Swasiland",
        TA: "Tristan da Cunha",
        TC: "Turks- und Caicosinseln",
        TD: "Tschad",
        TF: "Französische Süd- und Antarktisgebiete",
        TG: "Togo",
        TH: "Thailand",
        TJ: "Tadschikistan",
        TK: "Tokelau",
        TL: "Timor-Leste",
        TM: "Turkmenistan",
        TN: "Tunesien",
        TO: "Tonga",
        TR: "Türkei",
        TT: "Trinidad und Tobago",
        TV: "Tuvalu",
        TW: "Taiwan",
        TZ: "Tansania",
        UA: "Ukraine",
        UG: "Uganda",
        UM: "United States Minor Outlying Islands",
        US: "USA",
        UY: "Uruguay",
        UZ: "Usbekistan",
        VA: "Heiliger Stuhl (Staat Vatikanstadt)",
        VC: "St. Vincent und die Grenadinen",
        VE: "Venezuela",
        VG: "Jungferninseln, Britisch",
        VI: "Jungferninseln, USA",
        VN: "Vietnam",
        VU: "Vanuatu",
        WF: "Wallis- und Futunainseln",
        WS: "Samoa",
        XK: "Kosovo",
        YE: "Jemen",
        YT: "Mayotte",
        ZA: "Südafrika",
        ZM: "Sambia",
        ZW: "Simbabwe",
      },
      finishBtn: "Fertig stellen",
      finishBtnInfo: "Das ShareFile-Konto wird erstellt.",
      modal: {
        cancel: "Abbrechen",
        cancelAccount: "Konto kündigen",
        cancelAccountBody: {
          cancelAccount:
            "Sind Sie sicher, dass Sie das Konto {accountName} kündigen möchten?",
          cancelTrialAccount:
            "Sind Sie sicher, dass Sie das Testkonto {accountName} kündigen möchten?",
          para_2:
            "Beachten Sie, dass durch die Kündigung des Kontos der Zugriff des Mandanten auf alle ShareFile-Dienste am Ende des aktuellen Abrechnungszeitraums blockiert wird.",
          para_3:
            "Diese Aktion ist irreversibel und kann sich auf die laufenden Projekte des Mandanten und den Zugriff auf gespeicherte Daten auswirken.",
        },
        cancelTrial: "Testabo kündigen",
        changePlan: "Abonnement ändern",
        continueTrial: "Testabo fortsetzen",
        convertAccount: "Konto umwandeln",
        convertPlanBody: {
          changeAccount: "Ändern Sie das Abonnement des Kontos {accountName}.",
          convertAccount:
            "Wandeln Sie das Konto {accountName} von einer Testversion in ein kostenpflichtiges Abonnement um.",
          helpText: "Info zu Abonnementtypen",
          needHelp: "Benötigen Sie Hilfe bei der Auswahl? ",
        },
        convertToPaid: "In Bezahlabo umwandeln",
        increaseLicense: "Lizenz erhöhen",
        keepAccount: "Konto behalten",
        plans: {
          advanced: "2 - Fortgeschritten",
          advancedSubText:
            "E-Mail-Plugins, erweiterte Zusammenarbeit und Sicherheit",
          premium: "3 - Prämie",
          premiumSubText:
            "Unbegrenzte elektronische Signaturen, automatisierte Workflows, Dokumentenanfragen, Projekträume, Integrationen",
          standard: "1 – Standard",
          standardSubText:
            "Speichern, synchronisieren, teilen; mobile Apps, unbegrenzte Kunden",
        },
      },
      phoneNumber: {
        minCharacters: "Es sind mindestens vier Ziffern erforderlich.",
      },
      reactSelect: {
        noResults: "Keine Ergebnisse gefunden",
        select: "Land suchen",
      },
      storageZone: {
        addToMultiTenant: "Zu Mehrmandanten hinzufügen (vom Partner gehostet)",
        addToMultiTenantDescription:
          "Vom Partner erstellte mandantenfähige StorageZones.",
        addToMultiTenantInfo:
          "Mit mandantenfähigen StorageZones können Sie eine einzige StorageZone für alle Ihre Kunden erstellen und verwalten.",
        learnMore: "Weitere Informationen",
        sharefileCloudHosted: "In ShareFile-Cloud gehostet",
        sharefileCloudHostedDescription:
          "Alles wird in der ShareFile-Cloud gespeichert und verwaltet.",
        standrdPartnerHosted: "Standard (von Partner gehostet)",
        standrdPartnerHostedDescription:
          "Sie können später eine dedizierte StorageZone einrichten.",
        subTitle: "Wählen Sie die Speicherzone des Kunden aus.",
        title: "Speicherzone",
      },
      tenant: {
        account_name: "Mandantenkonto",
        add_tenant: " Mandant hinzufügen",
        cancelled: "Gekündigt",
        contact_support:
          "Zum Bearbeiten der Mandanten vervollständigen Sie bitte das Formular",
        contact_support_link: "CSP ShareFile-Kundenkontoanforderungsformular",
        download_csv: "Als CSV-Datei herunterladen",
        fileName: "Mandant",
        license_used: "Lizenzen",
        matching: "übereinstimmend mit {{keyword}}",
        none: "Ohne",
        paid: "Bezahlt",
        planName: "Abonnement",
        search_tenants: "Mandanten suchen",
        status: "Status",
        storage_used: "Verwendeter Speicher",
        storage_zone: "Speicherzone",
        tenant_management: "Mandantenverwaltung",
        tenants: "Mandanten: ",
        total_licenses: "Insgesamt verwendete Lizenzen:",
        trial: "Testversion",
      },
    },
  },
  {
    key: "en",
    content: {
      accountDetails: {
        accountType: {
          paidService: "Paid Service",
          subTitle: "You will be billed for licenses assigned to users.",
          title: "Account Type",
          trial: "Trial",
          trialInfo: "30 days trial with limited storage",
        },
        plan: {
          advanced: "Advanced",
          learnAboutPalnTypes: "Learn about plan types",
          premium: "Premium",
          standard: "Standard",
          subTitle: "Need help choosing?",
          title: "Plan",
        },
        subDomain: {
          checkAvailability: "Check Availability",
          checkAvailabilityApiErrorMsg:
            "The subdomain you specified is used by another account",
          checkAvailabilityApiSuccessMsg: "Available",
          controlPlane: "Control Plane",
          options: {
            "0": "Select your country",
            "1": "United States",
            "2": "Europe (Germany)",
          },
          subTitle:
            "Your subdomain is your unique URL for your ShareFile account. You can change this later.",
          title: "Subdomain",
        },
        supportInfo: {
          areaCode: "Area Code",
          countryCode: "Country",
          email: "Email",
          enableSupportInfo: "Provide a support contact for tenants",
          name: "Name",
          phoneNumber: "Phone Number",
          subTitle:
            "Enter the support contact details for tenants here. All fields are required if entering.",
          title: "Support Information",
        },
        title: "Account Details",
      },
      addTenant: "Add a Tenant",
      addTenantInfo: {
        adminDetails: {
          email: "Email",
          firstName: "First Name",
          lastName: "Last Name",
          title: "Master Admin",
        },
        bussinessDetails: {
          address: "Address",
          areaCode: "Area Code",
          city: "City",
          companyName: "Company Name",
          country: "Country",
          phoneNumber: "Phone Number",
          postalCode: "Zip or Postal Code",
          postalCodeErrorMsg: "Invalid Code",
          state: "State/Province",
          title: "Business Details",
        },
        subTitle: "Enter admin and tenant details",
        termsOfService: "I've read, understand and agree to the",
        termsOfServiceLinkText: "Terms of Service",
        title: "Add Tenant Information",
      },
      addTenantSubTitle: "To add a tenant, complete these steps below.",
      addTenantSuccesMsg: "Tenant created successfully",
      backBtn: "Back",
      country: {
        AC: "Ascension Island",
        AD: "Andorra",
        AE: "United Arab Emirates",
        AF: "Afghanistan",
        AG: "Antigua and Barbuda",
        AI: "Anguilla",
        AL: "Albania",
        AM: "Armenia",
        AO: "Angola",
        AQ: "Antarctica",
        AR: "Argentina",
        AS: "American Samoa",
        AT: "Austria",
        AU: "Australia",
        AW: "Aruba",
        AX: "Aland Islands",
        AZ: "Azerbaijan",
        BA: "Bosnia and Herzegovina",
        BB: "Barbados",
        BD: "Bangladesh",
        BE: "Belgium",
        BF: "Burkina Faso",
        BG: "Bulgaria",
        BH: "Bahrain",
        BI: "Burundi",
        BJ: "Benin",
        BL: "Saint Barthélemy",
        BM: "Bermuda",
        BN: "Brunei Darussalam",
        BO: "Bolivia",
        BQ: "Bonaire, Sint Eustatius and Saba",
        BR: "Brazil",
        BS: "Bahamas",
        BT: "Bhutan",
        BV: "Bouvet Island",
        BW: "Botswana",
        BY: "Belarus",
        BZ: "Belize",
        CA: "Canada",
        CC: "Cocos (Keeling) Islands",
        CD: "Congo, Democratic Republic of the",
        CF: "Central African Republic",
        CG: "Congo",
        CH: "Switzerland",
        CI: "Cote d'Ivoire",
        CK: "Cook Islands",
        CL: "Chile",
        CM: "Cameroon",
        CN: "China",
        CO: "Colombia",
        CR: "Costa Rica",
        CU: "Cuba",
        CV: "Cape Verde",
        CW: "Curaçao",
        CX: "Christmas Island",
        CY: "Cyprus",
        CZ: "Czech Republic",
        DE: "Germany",
        DJ: "Djibouti",
        DK: "Denmark",
        DM: "Dominica",
        DO: "Dominican Republic",
        DZ: "Algeria",
        EC: "Ecuador",
        EE: "Estonia",
        EG: "Egypt",
        EH: "Western Sahara",
        ER: "Eritrea",
        ES: "Spain",
        ET: "Ethiopia",
        FI: "Finland",
        FJ: "Fiji",
        FK: "Falkland Islands",
        FM: "Federated States of Micronesia",
        FO: "Faroe Islands",
        FR: "France",
        GA: "Gabon",
        GB: "United Kingdom",
        GD: "Grenada",
        GE: "Georgia",
        GF: "French Guiana",
        GG: "Guernsey",
        GH: "Ghana",
        GI: "Gibraltar",
        GL: "Greenland",
        GM: "Gambia",
        GN: "Guinea",
        GP: "Guadeloupe",
        GQ: "Equatorial Guinea",
        GR: "Greece",
        GS: "South Georgia and the South Sandwich Islands",
        GT: "Guatemala",
        GU: "Guam",
        GW: "Guinea-Bissau",
        GY: "Guyana",
        HK: "Hong Kong",
        HM: "Heard Island and McDonald Islands",
        HN: "Honduras",
        HR: "Croatia",
        HT: "Haiti",
        HU: "Hungary",
        ID: "Indonesia",
        IE: "Ireland",
        IL: "Israel",
        IM: "Isle of Man",
        IN: "India",
        IO: "British Indian Ocean Territory",
        IQ: "Iraq",
        IR: "Iran",
        IS: "Iceland",
        IT: "Italy",
        JE: "Jersey",
        JM: "Jamaica",
        JO: "Jordan",
        JP: "Japan",
        KE: "Kenya",
        KG: "Kyrgyzstan",
        KH: "Cambodia",
        KI: "Kiribati",
        KM: "Comoros",
        KN: "Saint Kitts and Nevis",
        KP: "North Korea",
        KR: "South Korea",
        KW: "Kuwait",
        KY: "Cayman Islands",
        KZ: "Kazakhstan",
        LA: "Laos",
        LB: "Lebanon",
        LC: "Saint Lucia",
        LI: "Liechtenstein",
        LK: "Sri Lanka",
        LR: "Liberia",
        LS: "Lesotho",
        LT: "Lithuania",
        LU: "Luxembourg",
        LV: "Latvia",
        LY: "Libya",
        MA: "Morocco",
        MC: "Monaco",
        MD: "Moldova",
        ME: "Montenegro",
        MF: "Saint Martin (French Part)",
        MG: "Madagascar",
        MH: "Marshall Islands",
        MK: "North Macedonia",
        ML: "Mali",
        MM: "Myanmar",
        MN: "Mongolia",
        MO: "Macao",
        MP: "Northern Mariana Islands",
        MQ: "Martinique",
        MR: "Mauritania",
        MS: "Montserrat",
        MT: "Malta",
        MU: "Mauritius",
        MV: "Maldives",
        MW: "Malawi",
        MX: "Mexico",
        MY: "Malaysia",
        MZ: "Mozambique",
        NA: "Namibia",
        NC: "New Caledonia",
        NE: "Niger",
        NF: "Norfolk Island",
        NG: "Nigeria",
        NI: "Nicaragua",
        NL: "Netherlands",
        NO: "Norway",
        NP: "Nepal",
        NR: "Nauru",
        NU: "Niue",
        NZ: "New Zealand",
        OM: "Oman",
        OS: "South Ossetia",
        PA: "Panama",
        PE: "Peru",
        PF: "French Polynesia",
        PG: "Papua New Guinea",
        PH: "Philippines",
        PK: "Pakistan",
        PL: "Poland",
        PM: "Saint Pierre and Miquelon",
        PN: "Pitcairn",
        PR: "Puerto Rico",
        PS: "Palestine",
        PT: "Portugal",
        PW: "Palau",
        PY: "Paraguay",
        QA: "Qatar",
        RE: "Reunion",
        RO: "Romania",
        RS: "Serbia",
        RU: "Russia",
        RW: "Rwanda",
        SA: "Saudi Arabia",
        SB: "Solomon Islands",
        SC: "Seychelles",
        SD: "Sudan",
        SE: "Sweden",
        SG: "Singapore",
        SH: "Saint Helena",
        SI: "Slovenia",
        SJ: "Svalbard and Jan Mayen",
        SK: "Slovakia",
        SL: "Sierra Leone",
        SM: "San Marino",
        SN: "Senegal",
        SO: "Somalia",
        SR: "Suriname",
        SS: "South Sudan",
        ST: "Sao Tome and Principe",
        SV: "El Salvador",
        SX: "Sint Maarten",
        SY: "Syria",
        SZ: "Swaziland",
        TA: "Tristan da Cunha",
        TC: "Turks and Caicos Islands",
        TD: "Chad",
        TF: "French Southern Territories",
        TG: "Togo",
        TH: "Thailand",
        TJ: "Tajikistan",
        TK: "Tokelau",
        TL: "Timor-Leste",
        TM: "Turkmenistan",
        TN: "Tunisia",
        TO: "Tonga",
        TR: "Turkey",
        TT: "Trinidad and Tobago",
        TV: "Tuvalu",
        TW: "Taiwan",
        TZ: "Tanzania",
        UA: "Ukraine",
        UG: "Uganda",
        UM: "United States Minor Outlying Islands",
        US: "United States",
        UY: "Uruguay",
        UZ: "Uzbekistan",
        VA: "Holy See (Vatican City State)",
        VC: "Saint Vincent and the Grenadines",
        VE: "Venezuela",
        VG: "Virgin Islands, British",
        VI: "Virgin Islands, U.S.",
        VN: "Vietnam",
        VU: "Vanuatu",
        WF: "Wallis and Futuna",
        WS: "Samoa",
        XK: "Kosovo",
        YE: "Yemen",
        YT: "Mayotte",
        ZA: "South Africa",
        ZM: "Zambia",
        ZW: "Zimbabwe",
      },
      finishBtn: "Finish",
      finishBtnInfo: "The ShareFile account will be created.",
      modal: {
        cancel: "Cancel",
        cancelAccount: "Cancel account",
        cancelAccountBody: {
          cancelAccount:
            "Are you sure you want to cancel the {accountName} account?",
          cancelTrialAccount:
            "Are you sure you want to cancel the {accountName} trial account?",
          para_2:
            "Please be aware that canceling the account will block the tenant's access to all ShareFile services at the end of the current billing period.",
          para_3:
            "This action is irreversible and may affect the tenant's ongoing projects and access to stored data.",
        },
        cancelTrial: "Cancel trial",
        changePlan: "Change plan",
        continueTrial: "Continue trial",
        convertAccount: "Convert account",
        convertPlanBody: {
          changeAccount: "Change the {accountName} account's plan.",
          convertAccount:
            "Convert the {accountName} account from a trial to a paid plan.",
          helpText: "Learn about plan types",
          needHelp: "Need help choosing? ",
        },
        convertToPaid: "Convert to paid",
        increaseLicense: "Increase license",
        keepAccount: "Keep account",
        plans: {
          advanced: "2 - Advanced",
          advancedSubText: "Email plugins, advanced collaboration and security",
          premium: "3 - Premium",
          premiumSubText:
            "Unlimited e-signatures, automated workflows, document requests, project spaces, integrations",
          standard: "1 - Standard",
          standardSubText: "Store, sync, share; mobile apps, unlimited clients",
        },
      },
      phoneNumber: {
        minCharacters: "Minimum four digits required.",
      },
      reactSelect: {
        noResults: "No Results Found",
        select: "Search country",
      },
      storageZone: {
        addToMultiTenant: "Add to Multi-Tenant (Partner-hosted)",
        addToMultiTenantDescription:
          "Multi-tenant Storage zones created by the partner.",
        addToMultiTenantInfo:
          "Multi-tenant Storage zones allow you to create and manage a single Storage zone for all of your customers.",
        learnMore: "Learn more",
        sharefileCloudHosted: "ShareFile Cloud Hosted",
        sharefileCloudHostedDescription:
          "Everything will be stored and managed in the ShareFile Cloud.",
        standrdPartnerHosted: "Standard (Partner-hosted)",
        standrdPartnerHostedDescription:
          "Setup a dedicated Storage Zone later.",
        subTitle: "Select the customer's storage zone.",
        title: "Storage Zone",
      },
      tenant: {
        account_name: "Tenant account",
        add_tenant: " Add Tenant",
        cancelled: "Cancelled",
        contact_support: "To modify your tenants, please complete the form",
        contact_support_link: "CSP ShareFile Customer Account Request",
        download_csv: "Download as CSV",
        fileName: "Tenant",
        license_used: "Licenses",
        matching: "matching {{keyword}}",
        none: "None",
        paid: "Paid",
        planName: "Plan",
        search_tenants: "Search Tenants",
        status: "Status",
        storage_used: "Storage used",
        storage_zone: "Storage zone",
        tenant_management: "Tenant Management",
        tenants: "Tenants: ",
        total_licenses: "Total licenses used:",
        trial: "Trial",
      },
    },
  },
  {
    key: "es",
    content: {
      accountDetails: {
        accountType: {
          paidService: "Servicio de pago",
          subTitle: "Se le facturarán las licencias asignadas a los usuarios.",
          title: "Tipo de cuenta",
          trial: "Prueba",
          trialInfo: "Prueba de 30 días con almacenamiento limitado",
        },
        plan: {
          advanced: "Advanced",
          learnAboutPalnTypes: "Más información acerca de los tipos de planes",
          premium: "Premium",
          standard: "Standard",
          subTitle: "¿Necesita ayuda para elegir?",
          title: "Plan",
        },
        subDomain: {
          checkAvailability: "Comprobar disponibilidad",
          checkAvailabilityApiErrorMsg:
            "El subdominio especificado está siendo utilizado por otra cuenta",
          checkAvailabilityApiSuccessMsg: "Disponible",
          controlPlane: "Plano de control",
          options: {
            "0": "Seleccione su país",
            "1": "Estados Unidos",
            "2": "Europa (Alemania)",
          },
          subTitle:
            "Su subdominio es la URL única para su cuenta de ShareFile. Puede cambiar esto más tarde.",
          title: "Subdominio",
        },
        supportInfo: {
          areaCode: "Código de área",
          countryCode: "País",
          email: "Correo electrónico",
          enableSupportInfo:
            "Proporcionar un contacto de asistencia técnica para los arrendatarios",
          name: "Nombre",
          phoneNumber: "Número de teléfono",
          subTitle:
            "Introduzca aquí los datos de contacto de asistencia de los arrendatarios. Todos los campos son obligatorios.",
          title: "Información sobre asistencia",
        },
        title: "Detalles de la cuenta",
      },
      addTenant: "Agregar un arrendatario",
      addTenantInfo: {
        adminDetails: {
          email: "Correo electrónico",
          firstName: "Nombre",
          lastName: "Apellido",
          title: "Administrador principal",
        },
        bussinessDetails: {
          address: "Dirección",
          areaCode: "Código de área",
          city: "Ciudad",
          companyName: "Nombre de la empresa:",
          country: "País",
          phoneNumber: "Número de teléfono",
          postalCode: "Código postal o ZIP",
          postalCodeErrorMsg: "Código no válido",
          state: "Estado/Provincia",
          title: "Detalles de la empresa",
        },
        subTitle:
          "Introduzca los detalles del administrador y del arrendatario",
        termsOfService: "He leído y acepto las",
        termsOfServiceLinkText: "Condiciones de servicio ",
        title: "Agregar información del arrendatario",
      },
      addTenantSubTitle:
        "Para agregar un arrendatario, complete los pasos que se indican a continuación.",
      addTenantSuccesMsg: "Arrendatario creado correctamente",
      backBtn: "Atrás",
      country: {
        AC: "Isla Ascensión",
        AD: "Andorra",
        AE: "Emiratos Árabes Unidos",
        AF: "Afganistán",
        AG: "Antigua y Barbuda",
        AI: "Anguila",
        AL: "Albania",
        AM: "Armenia",
        AO: "Angola",
        AQ: "Antártida",
        AR: "Argentina",
        AS: "Samoa Americana",
        AT: "Austria",
        AU: "Australia",
        AW: "Aruba",
        AX: "Islas Aland",
        AZ: "Azerbaiyán",
        BA: "Bosnia y Herzegovina",
        BB: "Barbados",
        BD: "Bangladesh",
        BE: "Bélgica",
        BF: "Burkina Faso",
        BG: "Bulgaria",
        BH: "Bahréin",
        BI: "Burundi",
        BJ: "Benín",
        BL: "San Bartolomé",
        BM: "Bermudas",
        BN: "Brunéi Darussalam",
        BO: "Bolivia",
        BQ: "Bonaire, San Eustaquio y Saba",
        BR: "Brasil",
        BS: "Bahamas",
        BT: "Bután",
        BV: "Isla Bouvet",
        BW: "Botsuana",
        BY: "Bielorrusia",
        BZ: "Belice",
        CA: "Canadá",
        CC: "Islas Cocos (Keeling)",
        CD: "Congo, República Democrática del",
        CF: "República Centroafricana",
        CG: "Congo",
        CH: "Suiza",
        CI: "Costa de Marfil",
        CK: "Islas Cook",
        CL: "Chile",
        CM: "Camerún",
        CN: "China",
        CO: "Colombia",
        CR: "Costa Rica",
        CU: "Cuba",
        CV: "Islas de Cabo Verde",
        CW: "Curazao",
        CX: "Isla de Navidad",
        CY: "Chipre",
        CZ: "República Checa",
        DE: "Alemania",
        DJ: "Yibuti",
        DK: "Dinamarca",
        DM: "Dominica",
        DO: "República Dominicana",
        DZ: "Argelia",
        EC: "Ecuador",
        EE: "Estonia",
        EG: "Egipto",
        EH: "Sáhara Occidental",
        ER: "Eritrea",
        ES: "España",
        ET: "Etiopía",
        FI: "Finlandia",
        FJ: "Islas Fiyi",
        FK: "Islas Malvinas",
        FM: "Estados Federados de Micronesia",
        FO: "Islas Feroe",
        FR: "Francia",
        GA: "Gabón",
        GB: "Reino Unido",
        GD: "Granada",
        GE: "Georgia",
        GF: "Guayana Francesa",
        GG: "Guernsey",
        GH: "Ghana",
        GI: "Gibraltar",
        GL: "Groenlandia",
        GM: "Gambia",
        GN: "Guinea",
        GP: "Guadalupe",
        GQ: "Guinea Ecuatorial",
        GR: "Grecia",
        GS: "Islas Georgias del Sur y Sandwich del Sur",
        GT: "Guatemala",
        GU: "Guam",
        GW: "Guinea-Bissau",
        GY: "Guayana",
        HK: "Hong Kong",
        HM: "Isla Heard e Islas McDonald",
        HN: "Honduras",
        HR: "Croacia",
        HT: "Haití",
        HU: "Hungría",
        ID: "Indonesia",
        IE: "Irlanda",
        IL: "Israel",
        IM: "Isla de Man",
        IN: "India",
        IO: "Territorio Británico del Océano Índico",
        IQ: "Irak",
        IR: "Irán",
        IS: "Islandia",
        IT: "Italia",
        JE: "Jersey",
        JM: "Jamaica",
        JO: "Jordania",
        JP: "Japón",
        KE: "Kenia",
        KG: "Kirguistán",
        KH: "Camboya",
        KI: "República de Kiribati",
        KM: "Comoras",
        KN: "San Cristóbal y Nieves",
        KP: "Corea del Norte",
        KR: "Corea del Sur",
        KW: "Kuwait",
        KY: "Islas Caimán",
        KZ: "Kazajistán",
        LA: "Laos",
        LB: "Líbano",
        LC: "Santa Lucía",
        LI: "Liechtenstein",
        LK: "Sri Lanka",
        LR: "Liberia",
        LS: "Lesoto",
        LT: "Lituania",
        LU: "Luxemburgo",
        LV: "Letonia",
        LY: "Libia",
        MA: "Marruecos",
        MC: "Mónaco",
        MD: "Moldavia",
        ME: "Montenegro",
        MF: "San Martín (parte francesa)",
        MG: "Madagascar",
        MH: "Islas Marshall",
        MK: "Macedonia del Norte",
        ML: "Malí",
        MM: "Myanmar",
        MN: "Mongolia",
        MO: "Macao",
        MP: "Islas Marianas del Norte",
        MQ: "Martinica",
        MR: "Mauritania",
        MS: "Monserrat",
        MT: "Malta",
        MU: "Mauricio",
        MV: "Maldivas",
        MW: "Malaui",
        MX: "México",
        MY: "Malasia",
        MZ: "Mozambique",
        NA: "Namibia",
        NC: "Nueva Caledonia",
        NE: "Níger",
        NF: "Isla Norfolk",
        NG: "Nigeria",
        NI: "Nicaragua",
        NL: "Países Bajos",
        NO: "Noruega",
        NP: "Nepal",
        NR: "Nauru",
        NU: "Niue",
        NZ: "Nueva Zelanda",
        OM: "Omán",
        OS: "Osetia del Sur",
        PA: "Panamá",
        PE: "Perú",
        PF: "Polinesia Francesa",
        PG: "Papúa Nueva Guinea",
        PH: "Filipinas",
        PK: "Pakistán",
        PL: "Polonia",
        PM: "San Pedro y Miquelón",
        PN: "Pitcairn",
        PR: "Puerto Rico",
        PS: "Palestina",
        PT: "Portugal",
        PW: "Palaos",
        PY: "Paraguay",
        QA: "Qatar",
        RE: "Reunión",
        RO: "Rumania",
        RS: "Serbia",
        RU: "Rusia",
        RW: "Ruanda",
        SA: "Arabia Saudita",
        SB: "Islas Salomón",
        SC: "Seychelles",
        SD: "Sudán",
        SE: "Suecia",
        SG: "Singapur",
        SH: "Santa Elena",
        SI: "Eslovenia",
        SJ: "Svalbard y Jan Mayen",
        SK: "Eslovaquia",
        SL: "Sierra Leona",
        SM: "San Marino",
        SN: "Senegal",
        SO: "Somalia",
        SR: "Surinam",
        SS: "Sudán del Sur",
        ST: "Santo Tomé y Príncipe",
        SV: "El Salvador",
        SX: "San Martín",
        SY: "Siria",
        SZ: "Suazilandia",
        TA: "Tristán de Acuña",
        TC: "Islas Turcas y Caicos",
        TD: "Chad",
        TF: "Territorios Australes Franceses",
        TG: "Togo",
        TH: "Tailandia",
        TJ: "Tayikistán",
        TK: "Islas Tokelau",
        TL: "Timor Oriental",
        TM: "Turkmenistán",
        TN: "Túnez",
        TO: "Tonga",
        TR: "Turquía",
        TT: "Trinidad y Tobago",
        TV: "Tuvalu",
        TW: "Taiwán",
        TZ: "Tanzania",
        UA: "Ucrania",
        UG: "Uganda",
        UM: "Islas Ultramarinas Menores de los Estados Unidos",
        US: "Estados Unidos",
        UY: "Uruguay",
        UZ: "Uzbekistán",
        VA: "Santa Sede (Estado de la Ciudad del Vaticano)",
        VC: "San Vicente y las Granadinas",
        VE: "Venezuela",
        VG: "Islas Vírgenes Británicas",
        VI: "Islas Vírgenes de EE. UU.",
        VN: "Vietnam",
        VU: "Vanuatu",
        WF: "Islas Wallis y Futuna",
        WS: "Samoa",
        XK: "Kósovo",
        YE: "Yemen",
        YT: "Mayotte",
        ZA: "Sudáfrica",
        ZM: "Zambia",
        ZW: "Zimbabue",
      },
      finishBtn: "Finalizar",
      finishBtnInfo: "Se creará la cuenta de ShareFile.",
      modal: {
        cancel: "Cancelar",
        cancelAccount: "Cancelar cuenta",
        cancelAccountBody: {
          cancelAccount: "¿Seguro que quiere cancelar la cuenta {accountName}?",
          cancelTrialAccount:
            "¿Seguro que quiere cancelar la cuenta de evaluación {accountName}?",
          para_2:
            "Tenga en cuenta que, al cancelar la cuenta, bloqueará el acceso del arrendatario a todos los servicios de ShareFile al final del período de facturación actual.",
          para_3:
            "Esta acción es irreversible y puede afectar a los proyectos en curso del arrendatario y al acceso a los datos almacenados.",
        },
        cancelTrial: "Cancelar evaluación",
        changePlan: "Cambiar plan",
        continueTrial: "Continuar evaluación",
        convertAccount: "Convertir cuenta",
        convertPlanBody: {
          changeAccount: "Cambiar el plan de la cuenta {accountName}.",
          convertAccount:
            "Convertir la cuenta {accountName} de un plan de evaluación a un plan pago.",
          helpText: "Más información acerca de los tipos de planes",
          needHelp: "¿Necesita ayuda para elegir? ",
        },
        convertToPaid: "Convertir a pago",
        increaseLicense: "Aumentar licencia",
        keepAccount: "Mantener cuenta",
        plans: {
          advanced: "2 - Advanced",
          advancedSubText:
            "Plug-ins de correo electrónico, colaboración avanzada y seguridad",
          premium: "3 - Premium",
          premiumSubText:
            "Firmas electrónicas ilimitadas, flujos de trabajo automatizados, solicitudes de documentos, espacios para proyectos, integraciones",
          standard: "1 - Standard",
          standardSubText:
            "Almacenar, sincronizar, compartir; aplicaciones móviles, clientes ilimitados",
        },
      },
      phoneNumber: {
        minCharacters: "Se requiere un mínimo de cuatro dígitos.",
      },
      reactSelect: {
        noResults: "No se encontraron resultados",
        select: "Buscar país",
      },
      storageZone: {
        addToMultiTenant: "Agregar a multiarrendatario (alojado por un socio)",
        addToMultiTenantDescription:
          "Zonas de almacenamiento de multiarrendatario creadas por el socio.",
        addToMultiTenantInfo:
          "Las zonas de almacenamiento de multiarrendatario le permiten crear y administrar una sola zona de almacenamiento para todos sus clientes.",
        learnMore: "Más información",
        sharefileCloudHosted: "ShareFile alojado en la nube",
        sharefileCloudHostedDescription:
          "Todo se almacenará y se administrará en ShareFile Cloud.",
        standrdPartnerHosted: "Estándar (alojado por un socio)",
        standrdPartnerHostedDescription:
          "Configure una zona de almacenamiento dedicada más adelante.",
        subTitle: "Seleccione la zona de almacenamiento del cliente.",
        title: "Zona de almacenamiento",
      },
      tenant: {
        account_name: "Cuenta de arrendatario",
        add_tenant: " Agregar arrendatario",
        cancelled: "Cancelado",
        contact_support: "Para modificar arrendatarios, complete el formulario",
        contact_support_link:
          "formulario de solicitud de cuenta de cliente de ShareFile CSP",
        download_csv: "Descargar como CSV",
        fileName: "Arrendatario",
        license_used: "Licencias",
        matching: "coincidencias con {{keyword}}",
        none: "Ninguno",
        paid: "Pagado",
        planName: "Plan",
        search_tenants: "Buscar arrendatarios",
        status: "Estado",
        storage_used: "Almacenamiento usado",
        storage_zone: "Zona de almacenamiento",
        tenant_management: "Administración de arrendatarios",
        tenants: "Arrendatarios: ",
        total_licenses: "Total de licencias utilizadas:",
        trial: "Prueba",
      },
    },
  },
  {
    key: "fr",
    content: {
      accountDetails: {
        accountType: {
          paidService: "Service payant",
          subTitle:
            "Les licences attribuées aux utilisateurs vous seront facturées.",
          title: "Type de compte",
          trial: "Évaluation",
          trialInfo: "Essai de 30 jours avec stockage limité",
        },
        plan: {
          advanced: "Advanced",
          learnAboutPalnTypes: "En savoir plus sur les types de plans",
          premium: "Premium",
          standard: "Standard",
          subTitle: "Besoin d'aide pour choisir ?",
          title: "Plan",
        },
        subDomain: {
          checkAvailability: "Vérifier la disponibilité",
          checkAvailabilityApiErrorMsg:
            "Le sous-domaine que vous avez spécifié est utilisé par un autre compte",
          checkAvailabilityApiSuccessMsg: "Disponible",
          controlPlane: "Plan de contrôle",
          options: {
            "0": "Sélectionnez votre pays",
            "1": "États-Unis",
            "2": "Europe (Allemagne)",
          },
          subTitle:
            "Votre sous-domaine est l'URL unique de votre compte ShareFile. Vous pouvez le changer plus tard.",
          title: "Sous-domaine",
        },
        supportInfo: {
          areaCode: "Indicatif régional",
          countryCode: "Pays",
          email: "E-mail",
          enableSupportInfo: "Fournir un contact de support aux locataires",
          name: "Nom",
          phoneNumber: "N° de téléphone",
          subTitle:
            "Entrez les coordonnées du support pour les locataires ici. Tous les champs sont obligatoires lors de la saisie.",
          title: "Informations d'assistance",
        },
        title: "Détails du compte",
      },
      addTenant: "Ajouter un locataire",
      addTenantInfo: {
        adminDetails: {
          email: "E-mail",
          firstName: "Prénom",
          lastName: "Nom",
          title: "Administrateur principal",
        },
        bussinessDetails: {
          address: "Adresse",
          areaCode: "Indicatif régional",
          city: "Ville",
          companyName: "Nom de l'entreprise",
          country: "Pays",
          phoneNumber: "N° de téléphone",
          postalCode: "Code postal",
          postalCodeErrorMsg: "Code non valide",
          state: "Département",
          title: "Détails de l'entreprise",
        },
        subTitle: "Entrez les détails de l'administrateur et du locataire",
        termsOfService: "J'ai lu, compris et j'accepte les",
        termsOfServiceLinkText: "conditions d'utilisation du service",
        title: "Ajouter informations sur le locataire",
      },
      addTenantSubTitle: "Pour ajouter un locataire, procédez comme suit.",
      addTenantSuccesMsg: "Locataire créé avec succès",
      backBtn: "Précédent",
      country: {
        AC: "Île de l'Ascension",
        AD: "Andorre",
        AE: "Émirats arabes unis",
        AF: "Afghanistan",
        AG: "Antigua-et-Barbuda",
        AI: "Anguilla",
        AL: "Albanie",
        AM: "Arménie",
        AO: "Angola",
        AQ: "Antarctique",
        AR: "Argentine",
        AS: "Samoa américaines",
        AT: "Autriche",
        AU: "Australie",
        AW: "Aruba",
        AX: "Îles Aland",
        AZ: "Azerbaïdjan",
        BA: "Bosnie-Herzégovine",
        BB: "Barbade",
        BD: "Bangladesh",
        BE: "Belgique",
        BF: "Burkina Faso",
        BG: "Bulgarie",
        BH: "Bahreïn",
        BI: "Burundi",
        BJ: "Bénin",
        BL: "Saint-Barthélemy",
        BM: "Bermudes",
        BN: "Brunei Darussalam",
        BO: "Bolivie",
        BQ: "Bonaire, Saint-Eustache et Saba",
        BR: "Brésil",
        BS: "Bahamas",
        BT: "Bhoutan",
        BV: "Île Bouvet",
        BW: "Botswana",
        BY: "Biélorussie",
        BZ: "Bélize",
        CA: "Canada",
        CC: "Îles Cocos (Keeling)",
        CD: "Congo, République démocratique du",
        CF: "République centrafricaine",
        CG: "Congo",
        CH: "Suisse",
        CI: "Côte d'ivoire",
        CK: "Îles Cook",
        CL: "Chili",
        CM: "Cameroun",
        CN: "Chine",
        CO: "Colombie",
        CR: "Costa Rica",
        CU: "Cuba",
        CV: "Cap-Vert",
        CW: "Curaçao",
        CX: "Île Christmas",
        CY: "Chypre",
        CZ: "République tchèque",
        DE: "Allemagne",
        DJ: "Djibouti",
        DK: "Danemark",
        DM: "Dominique",
        DO: "République dominicaine",
        DZ: "Algérie",
        EC: "Équateur",
        EE: "Estonie",
        EG: "Égypte",
        EH: "Sahara occidental",
        ER: "Érythrée",
        ES: "Espagne",
        ET: "Éthiopie",
        FI: "Finlande",
        FJ: "Fidji",
        FK: "Îles Malouines",
        FM: "États fédérés de Micronésie",
        FO: "Îles Féroé",
        FR: "France",
        GA: "Gabon",
        GB: "Royaume-Uni",
        GD: "Grenade",
        GE: "Géorgie",
        GF: "Guyane française",
        GG: "Guernesey",
        GH: "Ghana",
        GI: "Gibraltar",
        GL: "Groenland",
        GM: "Gambie",
        GN: "Guinée",
        GP: "Guadeloupe",
        GQ: "Guinée équatoriale",
        GR: "Grèce",
        GS: "Géorgie du Sud et îles Sandwich du Sud",
        GT: "Guatemala",
        GU: "Guam",
        GW: "Guinée-Bissau",
        GY: "Guyane",
        HK: "Hong Kong",
        HM: "Îles Heard et McDonald",
        HN: "Honduras",
        HR: "Croatie",
        HT: "Haïti",
        HU: "Hongrie",
        ID: "Indonésie",
        IE: "Irlande",
        IL: "Israël",
        IM: "île de Man",
        IN: "Inde",
        IO: "Territoire britannique de l'océan Indien",
        IQ: "Irak",
        IR: "Iran",
        IS: "Islande",
        IT: "Italie",
        JE: "Jersey",
        JM: "Jamaïque",
        JO: "Jordanie",
        JP: "Japon",
        KE: "Kenya",
        KG: "Kirghizistan",
        KH: "Cambodge",
        KI: "Kiribati",
        KM: "Comores",
        KN: "Saint-Kitts-et-Nevis",
        KP: "Corée du Nord",
        KR: "Corée du Sud",
        KW: "Koweït",
        KY: "Îles Caïmans",
        KZ: "Kazakhstan",
        LA: "Laos",
        LB: "Liban",
        LC: "Sainte-Lucie",
        LI: "Liechtenstein",
        LK: "Sri Lanka",
        LR: "Liberia",
        LS: "Lesotho",
        LT: "Lituanie",
        LU: "Luxembourg",
        LV: "Lettonie",
        LY: "Libye",
        MA: "Maroc",
        MC: "Monaco",
        MD: "Moldavie",
        ME: "Monténégro",
        MF: "Saint-Martin (partie française)",
        MG: "Madagascar",
        MH: "Îles Marshall",
        MK: "Macédoine du Nord",
        ML: "Mali",
        MM: "Myanmar",
        MN: "Mongolie",
        MO: "Macao",
        MP: "Îles Mariannes du Nord",
        MQ: "Martinique",
        MR: "Mauritanie",
        MS: "Montserrat",
        MT: "Malte",
        MU: "Maurice",
        MV: "Maldives",
        MW: "Malawi",
        MX: "Mexique",
        MY: "Malaisie",
        MZ: "Mozambique",
        NA: "Namibie",
        NC: "Nouvelle Calédonie",
        NE: "Niger",
        NF: "Île Norfolk",
        NG: "Nigeria",
        NI: "Nicaragua",
        NL: "Pays-Bas",
        NO: "Norvège",
        NP: "Népal",
        NR: "Nauru",
        NU: "Niue",
        NZ: "Nouvelle-Zélande",
        OM: "Oman",
        OS: "Ossétie du Sud",
        PA: "Panama",
        PE: "Pérou",
        PF: "Polynésie française",
        PG: "Papouasie-Nouvelle-Guinée",
        PH: "Philippines",
        PK: "Pakistan",
        PL: "Pologne",
        PM: "Saint-Pierre et Miquelon",
        PN: "Pitcairn",
        PR: "Porto Rico",
        PS: "Palestine",
        PT: "Portugal",
        PW: "Palau",
        PY: "Paraguay",
        QA: "Qatar",
        RE: "Réunion",
        RO: "Roumanie",
        RS: "Serbie",
        RU: "Russie",
        RW: "Rwanda",
        SA: "Arabie saoudite",
        SB: "Îles Salomon",
        SC: "Seychelles",
        SD: "Soudan",
        SE: "Suède",
        SG: "Singapour",
        SH: "Sainte-Hélène",
        SI: "Slovénie",
        SJ: "Svalbard et Jan Mayen",
        SK: "Slovaquie",
        SL: "Sierra Leone",
        SM: "Saint-Marin",
        SN: "Sénégal",
        SO: "Somalie",
        SR: "Surinam",
        SS: "Soudan du sud",
        ST: "São Tomé-et-Príncipe",
        SV: "Salvador",
        SX: "Saint-Martin",
        SY: "Syrie",
        SZ: "Swaziland",
        TA: "Tristan da Cunha",
        TC: "Îles Turks et Caicos",
        TD: "Tchad",
        TF: "Terres australes françaises",
        TG: "Togo",
        TH: "Thaïlande",
        TJ: "Tadjikistan",
        TK: "Tokelau",
        TL: "Timor-Leste",
        TM: "Turkménistan",
        TN: "Tunisie",
        TO: "Tonga",
        TR: "Turquie",
        TT: "Trinité-et-Tobago",
        TV: "Tuvalu",
        TW: "Taïwan",
        TZ: "Tanzanie",
        UA: "Ukraine",
        UG: "Ouganda",
        UM: "Îles mineures éloignées des États-Unis",
        US: "États-Unis",
        UY: "Uruguay",
        UZ: "Ouzbékistan",
        VA: "Saint-Siège (État de la Cité du Vatican)",
        VC: "Saint-Vincent-et-les-Grenadines",
        VE: "Venezuela",
        VG: "Îles Vierges, Royaume-Uni",
        VI: "Îles Vierges, États-Unis",
        VN: "Vietnam",
        VU: "Vanuatu",
        WF: "Wallis-et-Futuna",
        WS: "Samoa",
        XK: "Kosovo",
        YE: "Yémen",
        YT: "Mayotte",
        ZA: "Afrique du Sud",
        ZM: "Zambie",
        ZW: "Zimbabwe",
      },
      finishBtn: "Terminer",
      finishBtnInfo: "Le compte ShareFile sera créé.",
      modal: {
        cancel: "Annuler",
        cancelAccount: "Annuler le compte",
        cancelAccountBody: {
          cancelAccount:
            "Êtes-vous sûr de vouloir annuler le compte {accountName} ?",
          cancelTrialAccount:
            "Êtes-vous sûr de vouloir annuler le compte d'évaluation {accountName} ?",
          para_2:
            "Veuillez noter que l'annulation du compte bloquera l'accès du locataire à tous les services ShareFile à la fin de la période de facturation en cours.",
          para_3:
            "Cette action est irréversible et peut affecter les projets en cours du locataire et l'accès aux données stockées.",
        },
        cancelTrial: "Annuler l'évaluation",
        changePlan: "Changer de plan",
        continueTrial: "Continuer l'évaluation",
        convertAccount: "Convertir le compte",
        convertPlanBody: {
          changeAccount: "Modifiez le plan du compte {accountName}.",
          convertAccount:
            "Convertissez le compte {accountName} d'un compte évaluation à un plan payant.",
          helpText: "En savoir plus sur les types de plans",
          needHelp: "Besoin d'aide pour choisir ? ",
        },
        convertToPaid: "Convertir en compte payant",
        increaseLicense: "Augmenter licences",
        keepAccount: "Conserver le compte",
        plans: {
          advanced: "2 - Advanced",
          advancedSubText:
            "Plug-ins de messagerie, collaboration et sécurité avancées",
          premium: "3 - Premium",
          premiumSubText:
            "Signatures électroniques illimitées, workflows automatisés, demandes de documents, espaces de projet, intégrations",
          standard: "1 - Standard",
          standardSubText:
            "Stockage, synchronisation, partage ; applications mobiles, clients illimités",
        },
      },
      phoneNumber: {
        minCharacters: "Un minimum de quatre chiffres est requis.",
      },
      reactSelect: {
        noResults: "Aucun résultat trouvé",
        select: "Rechercher un pays",
      },
      storageZone: {
        addToMultiTenant:
          "Ajouter au multi-locataire (hébergé par le partenaire)",
        addToMultiTenantDescription:
          "Zones de stockage multi-locataire créées par le partenaire.",
        addToMultiTenantInfo:
          "Les zones de stockage multi-locataire vous permettent de créer et de gérer une seule zone de stockage pour tous vos clients.",
        learnMore: "En savoir plus",
        sharefileCloudHosted: "Hébergé dans le cloud ShareFile",
        sharefileCloudHostedDescription:
          "Tout sera stocké et géré dans le cloud ShareFile.",
        standrdPartnerHosted: "Standard (hébergé par le partenaire)",
        standrdPartnerHostedDescription:
          "Configurez une zone de stockage dédiée plus tard.",
        subTitle: "Sélectionnez la zone de stockage du client.",
        title: "Zone de stockage",
      },
      tenant: {
        account_name: "Compte du locataire",
        add_tenant: " Ajouter un locataire",
        cancelled: "Annulé",
        contact_support:
          "Pour modifier vos locataires, veuillez remplir le formulaire",
        contact_support_link:
          "formulaire de demande de compte client CSP ShareFile",
        download_csv: "Télécharger au format CSV",
        fileName: "Locataire",
        license_used: "Licences",
        matching: "correspondant à {{keyword}}",
        none: "Aucun",
        paid: "Payé",
        planName: "Plan",
        search_tenants: "Rechercher des locataires",
        status: "État",
        storage_used: "Stockage utilisé",
        storage_zone: "Zone de stockage",
        tenant_management: "Gestion des locataires",
        tenants: "Locataires : ",
        total_licenses: "Total de licences utilisées :",
        trial: "Évaluation",
      },
    },
  },
  {
    key: "it",
    content: {
      accountDetails: {
        accountType: {
          paidService: "Servizio a pagamento",
          subTitle: "Verranno addebitate le licenze assegnate agli utenti.",
          title: "Tipo di account",
          trial: "Prova",
          trialInfo: "30 giorni di prova con spazio di archiviazione limitato",
        },
        plan: {
          advanced: "Advanced",
          learnAboutPalnTypes: "Informazioni sui tipi di piano",
          premium: "Premium",
          standard: "Standard",
          subTitle: "Si necessita di aiuto per scegliere?",
          title: "Piano",
        },
        subDomain: {
          checkAvailability: "Verifica disponibilità",
          checkAvailabilityApiErrorMsg:
            "Il sottodominio specificato è utilizzato da un altro account",
          checkAvailabilityApiSuccessMsg: "Disponibile",
          controlPlane: "Piano di controllo",
          options: {
            "0": "Selezionare il proprio Paese",
            "1": "Stati Uniti",
            "2": "Europa (Germania)",
          },
          subTitle:
            "Il sottodominio è il proprio URL univoco dell'account ShareFile. È possibile modificare questa impostazione in un secondo momento.",
          title: "Sottodominio",
        },
        supportInfo: {
          areaCode: "Prefisso",
          countryCode: "Paese",
          email: "E-mail",
          enableSupportInfo: "Fornire un contatto di supporto per i tenant",
          name: "Nome",
          phoneNumber: "Numero di telefono",
          subTitle:
            "Immettere qui i dettagli di contatto dell'assistenza per i tenant. Tutti i campi sono obbligatori in caso di inserimento.",
          title: "Informazioni di supporto",
        },
        title: "Dettagli dell'account",
      },
      addTenant: "Aggiungere un tenant",
      addTenantInfo: {
        adminDetails: {
          email: "E-mail",
          firstName: "Nome",
          lastName: "Cognome",
          title: "Amministratore principale",
        },
        bussinessDetails: {
          address: "Indirizzo",
          areaCode: "Prefisso",
          city: "Città",
          companyName: "Nome società",
          country: "Paese",
          phoneNumber: "Numero di telefono",
          postalCode: "CAP",
          postalCodeErrorMsg: "Codice non valido",
          state: "Stato/provincia",
          title: "Dettagli aziendali",
        },
        subTitle: "Immettere i dettagli dell'amministratore e del tenant",
        termsOfService: "Ho letto, compreso e accetto",
        termsOfServiceLinkText: "Condizioni per l'utilizzo",
        title: "Aggiungere informazioni sul tenant",
      },
      addTenantSubTitle:
        "Per aggiungere un tenant, completare i passaggi riportati di seguito.",
      addTenantSuccesMsg: "Tenant creato correttamente",
      backBtn: "Indietro",
      country: {
        AC: "Isola di Ascensione",
        AD: "Andorra",
        AE: "Emirati Arabi Uniti",
        AF: "Afghanistan",
        AG: "Antigua e Barbuda",
        AI: "Anguilla",
        AL: "Albania",
        AM: "Armenia",
        AO: "Angola",
        AQ: "Antartide",
        AR: "Argentina",
        AS: "Samoa americane",
        AT: "Austria",
        AU: "Australia",
        AW: "Aruba",
        AX: "Isole Aland",
        AZ: "Azerbaigian",
        BA: "Bosnia ed Erzegovina",
        BB: "Barbados",
        BD: "Bangladesh",
        BE: "Belgio",
        BF: "Burkina Faso",
        BG: "Bulgaria",
        BH: "Bahrein",
        BI: "Burundi",
        BJ: "Benin",
        BL: "Saint Barthélemy",
        BM: "Bermuda",
        BN: "Brunei Darussalam",
        BO: "Bolivia",
        BQ: "Bonaire, Sint Eustatius e Saba",
        BR: "Brasile",
        BS: "Bahamas",
        BT: "Bhutan",
        BV: "Isola Bouvet",
        BW: "Botswana",
        BY: "Bielorussia",
        BZ: "Belize",
        CA: "Canada",
        CC: "Isole Cocos (Keeling)",
        CD: "Congo, Repubblica Democratica del",
        CF: "Repubblica Centrafricana",
        CG: "Congo",
        CH: "Svizzera",
        CI: "Costa d'Avorio",
        CK: "Isole Cook",
        CL: "Cile",
        CM: "Camerun",
        CN: "Cina",
        CO: "Colombia",
        CR: "Costa Rica",
        CU: "Cuba",
        CV: "Capo Verde",
        CW: "Curaçao",
        CX: "Isola Christmas",
        CY: "Cipro",
        CZ: "Repubblica Ceca",
        DE: "Germania",
        DJ: "Gibuti",
        DK: "Danimarca",
        DM: "Dominica",
        DO: "Repubblica dominicana",
        DZ: "Algeria",
        EC: "Ecuador",
        EE: "Estonia",
        EG: "Egitto",
        EH: "Sahara occidentale",
        ER: "Eritrea",
        ES: "Spagna",
        ET: "Etiopia",
        FI: "Finlandia",
        FJ: "Figi",
        FK: "Isole Falkland",
        FM: "Stati Federati di Micronesia",
        FO: "Fær Øer",
        FR: "Francia",
        GA: "Gabon",
        GB: "Regno Unito",
        GD: "Grenada",
        GE: "Georgia",
        GF: "Guiana francese",
        GG: "Guernsey",
        GH: "Ghana",
        GI: "Gibilterra",
        GL: "Groenlandia",
        GM: "Gambia",
        GN: "Guinea",
        GP: "Guadalupa",
        GQ: "Guinea Equatoriale",
        GR: "Grecia",
        GS: "Georgia del Sud e Isole Sandwich Australi",
        GT: "Guatemala",
        GU: "Guam",
        GW: "Guinea-Bissau",
        GY: "Guyana",
        HK: "Hong Kong",
        HM: "Isola Heard e Isole McDonald",
        HN: "Honduras",
        HR: "Croazia",
        HT: "Haiti",
        HU: "Ungheria",
        ID: "Indonesia",
        IE: "Irlanda",
        IL: "Israele",
        IM: "Isola di Man",
        IN: "India",
        IO: "Territorio britannico dell'Oceano Indiano",
        IQ: "Iraq",
        IR: "Iran",
        IS: "Islanda",
        IT: "Italia",
        JE: "Jersey",
        JM: "Giamaica",
        JO: "Giordania",
        JP: "Giappone",
        KE: "Kenya",
        KG: "Kirghizistan",
        KH: "Cambogia",
        KI: "Kiribati",
        KM: "Comore",
        KN: "Saint Kitts e Nevis",
        KP: "Corea del Nord",
        KR: "Corea del Sud",
        KW: "Kuwait",
        KY: "Isole Cayman",
        KZ: "Kazakhstan",
        LA: "Laos",
        LB: "Libano",
        LC: "Saint Lucia",
        LI: "Liechtenstein",
        LK: "Sri Lanka",
        LR: "Liberia",
        LS: "Lesotho",
        LT: "Lituania",
        LU: "Lussemburgo",
        LV: "Lettonia",
        LY: "Libia",
        MA: "Marocco",
        MC: "Monaco",
        MD: "Moldavia",
        ME: "Montenegro",
        MF: "Saint Martin (parte francese)",
        MG: "Madagascar",
        MH: "Isole Marshall",
        MK: "Macedonia del Nord",
        ML: "Mali",
        MM: "Birmania",
        MN: "Mongolia",
        MO: "Macao",
        MP: "Isole Marianne settentrionali",
        MQ: "Martinica",
        MR: "Mauritania",
        MS: "Montserrat",
        MT: "Malta",
        MU: "Mauritius",
        MV: "Maldive",
        MW: "Malawi",
        MX: "Messico",
        MY: "Malaysia",
        MZ: "Mozambico",
        NA: "Namibia",
        NC: "Nuova Caledonia",
        NE: "Niger",
        NF: "Isola Norfolk",
        NG: "Nigeria",
        NI: "Nicaragua",
        NL: "Paesi Bassi",
        NO: "Norvegia",
        NP: "Nepal",
        NR: "Nauru",
        NU: "Niue",
        NZ: "Nuova Zelanda",
        OM: "Oman",
        OS: "Ossezia meridionale",
        PA: "Panama",
        PE: "Perù",
        PF: "Polinesia francese",
        PG: "Papua Nuova Guinea",
        PH: "Filippine",
        PK: "Pakistan",
        PL: "Polonia",
        PM: "Saint-Pierre e Miquelon",
        PN: "Pitcairn",
        PR: "Portorico",
        PS: "Palestina",
        PT: "Portogallo",
        PW: "Palau",
        PY: "Paraguay",
        QA: "Qatar",
        RE: "Riunione",
        RO: "Romania",
        RS: "Serbia",
        RU: "Russia",
        RW: "Ruanda",
        SA: "Arabia Saudita",
        SB: "Isole Salomone",
        SC: "Seychelles",
        SD: "Sudan",
        SE: "Svezia",
        SG: "Singapore",
        SH: "Sant'Elena",
        SI: "Slovenia",
        SJ: "Svalbard e Jan Mayen",
        SK: "Slovacchia",
        SL: "Sierra Leone",
        SM: "San Marino",
        SN: "Senegal",
        SO: "Somalia",
        SR: "Suriname",
        SS: "Sudan del Sud",
        ST: "São Tomé e Príncipe",
        SV: "El Salvador",
        SX: "Sint Maarten",
        SY: "Siria",
        SZ: "Swaziland",
        TA: "Tristan da Cunha",
        TC: "Isole Turks e Caicos",
        TD: "Ciad",
        TF: "Territori meridionali francesi",
        TG: "Togo",
        TH: "Thailandia",
        TJ: "Tagikistan",
        TK: "Tokelau",
        TL: "Timor-Leste",
        TM: "Turkmenistan",
        TN: "Tunisia",
        TO: "Tonga",
        TR: "Turchia",
        TT: "Trinidad e Tobago",
        TV: "Tuvalu",
        TW: "Taiwan",
        TZ: "Tanzania",
        UA: "Ucraina",
        UG: "Uganda",
        UM: "Isole minori esterne degli Stati Uniti",
        US: "Stati Uniti",
        UY: "Uruguay",
        UZ: "Uzbekistan",
        VA: "Santa Sede (Stato della Città del Vaticano)",
        VC: "Saint Vincent e Grenadine",
        VE: "Venezuela",
        VG: "Isole Vergini Britanniche",
        VI: "Isole Vergini, Stati Uniti",
        VN: "Vietnam",
        VU: "Vanuatu",
        WF: "Wallis e Futuna",
        WS: "Samoa",
        XK: "Kosovo",
        YE: "Yemen",
        YT: "Mayotte",
        ZA: "Sudafrica",
        ZM: "Zambia",
        ZW: "Zimbabwe",
      },
      finishBtn: "Completare",
      finishBtnInfo: "Verrà creato l'account ShareFile.",
      modal: {
        cancel: "Annulla",
        cancelAccount: "Annulla account",
        cancelAccountBody: {
          cancelAccount: "Cancellare l'account {accountName}?",
          cancelTrialAccount: "Cancellare l'account di prova {accountName}?",
          para_2:
            "Tenere presente che l'annullamento dell'account bloccherà l'accesso del tenant a tutti i servizi ShareFile alla fine del periodo di fatturazione corrente.",
          para_3:
            "Questa azione è irreversibile e può influire sui progetti in corso del tenant e sull'accesso ai dati archiviati.",
        },
        cancelTrial: "Annulla prova",
        changePlan: "Cambia piano",
        continueTrial: "Continua prova",
        convertAccount: "Converti account",
        convertPlanBody: {
          changeAccount: "Cambiare il piano dell'account {accountName}.",
          convertAccount:
            "Convertire l'account {accountName} dalla versione di prova in un piano a pagamento.",
          helpText: "Informazioni sui tipi di piano",
          needHelp: "Si necessita di aiuto per scegliere? ",
        },
        convertToPaid: "Converti in account a pagamento",
        increaseLicense: "Aumenta la licenza",
        keepAccount: "Tieni l'account",
        plans: {
          advanced: "2 - Advanced",
          advancedSubText: "Plugin e-mail, collaborazione avanzata e sicurezza",
          premium: "3 - Premium",
          premiumSubText:
            "Firme elettroniche illimitate, flussi di lavoro automatizzati, richieste di documenti, aree di progetto, integrazioni",
          standard: "1 - Standard",
          standardSubText:
            "Archiviazione, sincronizzazione, condivisione; app mobili, clienti illimitati",
        },
      },
      phoneNumber: {
        minCharacters: "Sono richieste almeno quattro cifre.",
      },
      reactSelect: {
        noResults: "Nessun risultato trovato",
        select: "Cerca paese",
      },
      storageZone: {
        addToMultiTenant: "Aggiungi a multi-tenant (ospitato da partner)",
        addToMultiTenantDescription:
          "Zone di archiviazione multi-tenant create dal partner.",
        addToMultiTenantInfo:
          "Le zone di archiviazione multi-tenant consentono di creare e gestire una singola zona di archiviazione per tutti i clienti.",
        learnMore: "Ulteriori informazioni",
        sharefileCloudHosted: "ShareFile ospitato nel cloud",
        sharefileCloudHostedDescription:
          "Tutto verrà archiviato e gestito nel cloud ShareFile.",
        standrdPartnerHosted: "Standard (ospitata da partner)",
        standrdPartnerHostedDescription:
          "Configurare una zona di archiviazione dedicata in un secondo momento.",
        subTitle: "Selezionare la zona di archiviazione del cliente.",
        title: "Zona di archiviazione",
      },
      tenant: {
        account_name: "Account del tenant",
        add_tenant: " Aggiungi tenant",
        cancelled: "Annullato",
        contact_support: "Per modificare i tenant, compilare il modulo",
        contact_support_link:
          "il modulo di richiesta di account cliente ShareFile CSP",
        download_csv: "Scarica come CSV",
        fileName: "Tenant",
        license_used: "Licenze",
        matching: "corrispondente a {{keyword}}",
        none: "Nessuno",
        paid: "Pagato",
        planName: "Piano",
        search_tenants: "Cerca tenant",
        status: "Stato",
        storage_used: "Spazio di archiviazione utilizzato",
        storage_zone: "Zona di archiviazione",
        tenant_management: "Gestione tenant",
        tenants: "Tenant: ",
        total_licenses: "Licenze totali utilizzate:",
        trial: "Prova",
      },
    },
  },
  {
    key: "ja",
    content: {
      accountDetails: {
        accountType: {
          paidService: "有料サービス",
          subTitle:
            "ユーザーに割り当てられたライセンス分に対して請求が発生します。",
          title: "アカウントの種類",
          trial: "トライアル",
          trialInfo: "30 日間のトライアル (ストレージ制限あり)",
        },
        plan: {
          advanced: "Advanced",
          learnAboutPalnTypes: "プランの種類に関する情報",
          premium: "Premium",
          standard: "Standard",
          subTitle: "選択に関するヘルプ",
          title: "プラン",
        },
        subDomain: {
          checkAvailability: "使用可能かを確認",
          checkAvailabilityApiErrorMsg:
            "指定したサブドメインは別のアカウントで使用されています",
          checkAvailabilityApiSuccessMsg: "使用可能",
          controlPlane: "コントロールプレーン",
          options: {
            "0": "国を選択",
            "1": "アメリカ合衆国",
            "2": "ヨーロッパ (ドイツ)",
          },
          subTitle:
            "サブドメインは、各 ShareFile アカウント固有の URL です。これは後で変更できます。",
          title: "サブドメイン",
        },
        supportInfo: {
          areaCode: "市外局番",
          countryCode: "国",
          email: "メール アドレス",
          enableSupportInfo: "テナントのサポート連絡先を提供する",
          name: "名前",
          phoneNumber: "電話番号",
          subTitle:
            "ここにテナントのサポート連絡先の詳細を入力します。入力する場合はすべてのフィールドが必須です。",
          title: "サポート情報",
        },
        title: "アカウントの詳細",
      },
      addTenant: "テナントを追加する",
      addTenantInfo: {
        adminDetails: {
          email: "メール アドレス",
          firstName: "名",
          lastName: "姓",
          title: "マスター管理者",
        },
        bussinessDetails: {
          address: "住所",
          areaCode: "市外局番",
          city: "市町村",
          companyName: "会社名",
          country: "国",
          phoneNumber: "電話番号",
          postalCode: "郵便番号",
          postalCodeErrorMsg: "無効なコード",
          state: "都道府県/州",
          title: "会社の詳細",
        },
        subTitle: "管理者とテナントの詳細を入力",
        termsOfService: "次の文章を読み、理解し、同意しました。",
        termsOfServiceLinkText: "サービス利用契約",
        title: "テナント情報を追加する",
      },
      addTenantSubTitle:
        "テナントを追加するには、以下の手順を完了してください。",
      addTenantSuccesMsg: "テナントの作成に成功しました",
      backBtn: "戻る",
      country: {
        AC: "アセンション島",
        AD: "アンドラ",
        AE: "アラブ首長国連邦",
        AF: "アフガニスタン",
        AG: "アンティグア・バーブーダ",
        AI: "アンギラ",
        AL: "アルバニア",
        AM: "アルメニア",
        AO: "アンゴラ",
        AQ: "南極大陸",
        AR: "アルゼンチン",
        AS: "米領サモア",
        AT: "オーストリア",
        AU: "オーストラリア",
        AW: "アルバ",
        AX: "オーランド諸島",
        AZ: "アゼルバイジャン",
        BA: "ボスニア・ヘルツェゴビナ",
        BB: "バルバドス",
        BD: "バングラデシュ",
        BE: "ベルギー",
        BF: "ブルキナファソ",
        BG: "ブルガリア",
        BH: "バーレーン",
        BI: "ブルンジ",
        BJ: "ベナン",
        BL: "サン バルテルミー島",
        BM: "バミューダ諸島",
        BN: "ブルネイ",
        BO: "ボリビア",
        BQ: "ボネール、シント ユースタティウスおよびサバ",
        BR: "ブラジル",
        BS: "バハマ",
        BT: "ブータン",
        BV: "ブーベ島",
        BW: "ボツワナ",
        BY: "ベラルーシ",
        BZ: "ベリーズ",
        CA: "カナダ",
        CC: "ココス (キーリング) 諸島",
        CD: "コンゴ民主共和国",
        CF: "中央アフリカ共和国",
        CG: "コンゴ",
        CH: "スイス",
        CI: "コートジボワール",
        CK: "クック諸島",
        CL: "チリ",
        CM: "カメルーン",
        CN: "中国",
        CO: "コロンビア",
        CR: "コスタリカ",
        CU: "キューバ",
        CV: "カーボベルデ",
        CW: "キュラソー",
        CX: "クリスマス島",
        CY: "キプロス",
        CZ: "チェコ共和国",
        DE: "ドイツ",
        DJ: "ジブチ",
        DK: "デンマーク",
        DM: "ドミニカ",
        DO: "ドミニカ共和国",
        DZ: "アルジェリア",
        EC: "エクアドル",
        EE: "エストニア",
        EG: "エジプト",
        EH: "西サハラ",
        ER: "エリトリア",
        ES: "スペイン",
        ET: "エチオピア",
        FI: "フィンランド",
        FJ: "フィジー",
        FK: "フォークランド諸島",
        FM: "ミクロネシア連邦",
        FO: "フェロー諸島",
        FR: "フランス",
        GA: "ガボン",
        GB: "英国",
        GD: "グレナダ",
        GE: "ジョージア",
        GF: "フランス領ギアナ",
        GG: "ガーンジー",
        GH: "ガーナ",
        GI: "ジブラルタル",
        GL: "グリーンランド",
        GM: "ガンビア",
        GN: "ギニア",
        GP: "グアドループ",
        GQ: "赤道ギニア",
        GR: "ギリシャ",
        GS: "サウス ジョージア島とサウス サンドイッチ諸島",
        GT: "グアテマラ",
        GU: "グアム",
        GW: "ギニアビサウ",
        GY: "ガイアナ",
        HK: "香港",
        HM: "ハード島とマクドナルド諸島",
        HN: "ホンジュラス",
        HR: "クロアチア",
        HT: "ハイチ",
        HU: "ハンガリー",
        ID: "インドネシア",
        IE: "アイルランド",
        IL: "イスラエル",
        IM: "マン島",
        IN: "インド",
        IO: "イギリス領インド洋地域",
        IQ: "イラク",
        IR: "イラン",
        IS: "アイスランド",
        IT: "イタリア",
        JE: "ジャージー",
        JM: "ジャマイカ",
        JO: "ヨルダン",
        JP: "日本",
        KE: "ケニア",
        KG: "キルギス",
        KH: "カンボジア",
        KI: "キリバス",
        KM: "コモロ",
        KN: "セントクリスタファーネイビス",
        KP: "北朝鮮",
        KR: "韓国",
        KW: "クウェート",
        KY: "ケイマン諸島",
        KZ: "カザフスタン",
        LA: "ラオス",
        LB: "レバノン",
        LC: "セントルシア",
        LI: "リヒテンシュタイン",
        LK: "スリランカ",
        LR: "リベリア",
        LS: "レソト",
        LT: "リトアニア",
        LU: "ルクセンブルグ",
        LV: "ラトビア",
        LY: "リビア",
        MA: "モロッコ",
        MC: "モナコ",
        MD: "モルドバ",
        ME: "モンテネグロ",
        MF: "サン マルタン (フランス領)",
        MG: "マダガスカル",
        MH: "マーシャル諸島",
        MK: "北マケドニア",
        ML: "マリ",
        MM: "ミャンマー",
        MN: "モンゴル",
        MO: "マカオ",
        MP: "北マリアナ諸島",
        MQ: "マルチニーク島",
        MR: "モーリタニア",
        MS: "モンセラット",
        MT: "マルタ",
        MU: "モーリシャス",
        MV: "モルディブ",
        MW: "マラウイ",
        MX: "メキシコ",
        MY: "マレーシア",
        MZ: "モザンビーク",
        NA: "ナミビア",
        NC: "ニューカレドニア",
        NE: "ニジェール",
        NF: "ノーフォーク島",
        NG: "ナイジェリア",
        NI: "ニカラグア",
        NL: "オランダ",
        NO: "ノルウェー",
        NP: "ネパール",
        NR: "ナウル",
        NU: "ニウエ",
        NZ: "ニュージーランド",
        OM: "オマーン",
        OS: "南オセチア",
        PA: "パナマ",
        PE: "ペルー",
        PF: "フランス領ポリネシア",
        PG: "パプアニューギニア",
        PH: "フィリピン",
        PK: "パキスタン",
        PL: "ポーランド",
        PM: "サンピエール/ミクロン",
        PN: "ピトケアン",
        PR: "プエルトリコ",
        PS: "パレスチナ",
        PT: "ポルトガル",
        PW: "パラオ",
        PY: "パラグアイ",
        QA: "カタール",
        RE: "レユニオン",
        RO: "ルーマニア",
        RS: "セルビア",
        RU: "ロシア",
        RW: "ルワンダ",
        SA: "サウジアラビア",
        SB: "ソロモン諸島",
        SC: "セーシェル",
        SD: "スーダン",
        SE: "スウェーデン",
        SG: "シンガポール",
        SH: "セントヘレナ",
        SI: "スロベニア",
        SJ: "スバールバル諸島とヤン マイエン島",
        SK: "スロバキア",
        SL: "シエラレオネ",
        SM: "サンマリノ",
        SN: "セネガル",
        SO: "ソマリア",
        SR: "スリナム",
        SS: "南スーダン",
        ST: "サントメ・プリンシペ",
        SV: "エルサルバドル",
        SX: "シント マールテン",
        SY: "シリア",
        SZ: "スワジランド",
        TA: "トリスタン ダ クーニャ",
        TC: "タークス・カイコス諸島",
        TD: "チャド",
        TF: "フランス領南方地域",
        TG: "トーゴ",
        TH: "タイ",
        TJ: "タジキスタン",
        TK: "トケラウ諸島",
        TL: "東ティモール",
        TM: "トルクメニスタン",
        TN: "チュニジア",
        TO: "トンガ",
        TR: "トルコ",
        TT: "トリニダード・トバゴ",
        TV: "ツバル",
        TW: "台湾",
        TZ: "タンザニア",
        UA: "ウクライナ",
        UG: "ウガンダ",
        UM: "合衆国領有小離島",
        US: "アメリカ合衆国",
        UY: "ウルグアイ",
        UZ: "ウズベキスタン",
        VA: "ローマ法王庁 (バチカン市国)",
        VC: "セントビンセント/グレナディーン諸島",
        VE: "ベネズエラ",
        VG: "イギリス領バージン諸島",
        VI: "米領バージン諸島",
        VN: "ベトナム",
        VU: "バヌアツ",
        WF: "ワリス・フテュナ諸島",
        WS: "サモア",
        XK: "コソボ",
        YE: "イエメン",
        YT: "マヨット",
        ZA: "南アフリカ",
        ZM: "ザンビア",
        ZW: "ジンバブエ",
      },
      finishBtn: "完了",
      finishBtnInfo: "ShareFile アカウントが作成されます。",
      modal: {
        cancel: "キャンセル",
        cancelAccount: "アカウントをキャンセルする",
        cancelAccountBody: {
          cancelAccount:
            "{accountName} のアカウントをキャンセルしてもよろしいですか?",
          cancelTrialAccount:
            "{accountName} のトライアル アカウントをキャンセルしてもよろしいですか?",
          para_2:
            "アカウントをキャンセルすると、現在の請求期間の終了時に ShareFile サービスに対するテナントのすべてのアクセスが禁止されることに注意してください。",
          para_3:
            "このアクションは元に戻すことができず、テナントの進行中のプロジェクトや保存されたデータへのアクセスに影響を与える可能性があります。",
        },
        cancelTrial: "トライアルをキャンセルする",
        changePlan: "プランの変更",
        continueTrial: "トライアルを続行する",
        convertAccount: "アカウントの変換",
        convertPlanBody: {
          changeAccount: "{accountName} のアカウントのプランを変更します。",
          convertAccount:
            "{accountName} のアカウントをトライアルから有料プランに変換します。",
          helpText: "プランの種類に関する情報",
          needHelp: "選択に関するヘルプが必要ですか? ",
        },
        convertToPaid: "有料サービスに変更する",
        increaseLicense: "ライセンスを増やす",
        keepAccount: "アカウントを保持する",
        plans: {
          advanced: "2 - Advanced",
          advancedSubText:
            "メール プラグイン、高度なコラボレーションおよびセキュリティ",
          premium: "3 - Premium",
          premiumSubText:
            "無制限の電子署名、自動化されたワークフロー、ドキュメントの要求、プロジェクトの領域、統合",
          standard: "1 - Standard",
          standardSubText:
            "保存、同期、共有。モバイル アプリ、無制限のクライアント",
        },
      },
      phoneNumber: {
        minCharacters: "最低 4 桁の数字が必要です。",
      },
      reactSelect: {
        noResults: "結果が見つかりませんでした",
        select: "国を検索する",
      },
      storageZone: {
        addToMultiTenant: "マルチテナントに追加する (パートナーがホスト)",
        addToMultiTenantDescription:
          "パートナー作成のマルチテナント ストレージ ゾーン。",
        addToMultiTenantInfo:
          "マルチテナント ストレージ ゾーンを使用すると、すべての顧客に対して単一のストレージ ゾーンを作成および管理できます。",
        learnMore: "詳細情報",
        sharefileCloudHosted: "ShareFile クラウドがホスト",
        sharefileCloudHostedDescription:
          "すべて ShareFile クラウドで保存、管理します。",
        standrdPartnerHosted: "Standard (パートナーがホスト)",
        standrdPartnerHostedDescription:
          "後で専用ストレージ ゾーンをセットアップします。",
        subTitle: "顧客のストレージ ゾーンを選択します。",
        title: "ストレージ ゾーン",
      },
      tenant: {
        account_name: "テナント アカウント",
        add_tenant: " テナントを追加する",
        cancelled: "キャンセルされました",
        contact_support: "テナントを変更するには、フォームに記入してください",
        contact_support_link: "CSP ShareFile 顧客アカウント依頼",
        download_csv: "CSV としてダウンロード",
        fileName: "テナント",
        license_used: "ライセンス",
        matching: "一致する{{keyword}}",
        none: "なし",
        paid: "有料",
        planName: "プラン",
        search_tenants: "テナントの検索",
        status: "状態",
        storage_used: "使用ストレージ",
        storage_zone: "ストレージ ゾーン",
        tenant_management: "テナント管理",
        tenants: "テナント: ",
        total_licenses: "総ライセンス使用数：",
        trial: "トライアル",
      },
    },
  },
  {
    key: "ko",
    content: {
      accountDetails: {
        accountType: {
          paidService: "유료 서비스",
          subTitle: "사용자에게 할당된 라이선스에 대한 요금이 청구됩니다.",
          title: "계정 유형",
          trial: "평가판",
          trialInfo: "스토리지가 제한된 30일 평가판",
        },
        plan: {
          advanced: "Advanced",
          learnAboutPalnTypes: "계획 유형에 대해 알아보기",
          premium: "Premium",
          standard: "Standard",
          subTitle: "선택에 도움이 필요하십니까?",
          title: "계획",
        },
        subDomain: {
          checkAvailability: "가용성 확인",
          checkAvailabilityApiErrorMsg:
            "지정한 하위 도메인을 다른 계정에서 사용하고 있습니다",
          checkAvailabilityApiSuccessMsg: "사용 가능",
          controlPlane: "제어부",
          options: {
            "0": "국가 선택",
            "1": "미국",
            "2": "유럽(독일)",
          },
          subTitle:
            "하위 도메인은 ShareFile 계정의 고유한 URL입니다. 나중에 변경할 수 있습니다.",
          title: "하위 도메인",
        },
        supportInfo: {
          areaCode: "지역 코드",
          countryCode: "국가",
          email: "전자 메일",
          enableSupportInfo: "테넌트를 위한 지원 연락처 제공",
          name: "이름",
          phoneNumber: "전화 번호",
          subTitle:
            "여기에 테넌트를 위한 지원 연락처 정보를 입력하십시오. 입력하는 경우 모든 필드는 필수입니다.",
          title: "지원 정보",
        },
        title: "계정 세부 정보",
      },
      addTenant: "테넌트 추가",
      addTenantInfo: {
        adminDetails: {
          email: "전자 메일",
          firstName: "이름",
          lastName: "성",
          title: "마스터 관리자",
        },
        bussinessDetails: {
          address: "주소",
          areaCode: "지역 코드",
          city: "구/군/시",
          companyName: "회사 이름",
          country: "국가",
          phoneNumber: "전화 번호",
          postalCode: "우편 번호",
          postalCodeErrorMsg: "잘못된 코드",
          state: "시/도",
          title: "비즈니스 세부 정보",
        },
        subTitle: "관리자 및 테넌트 세부 정보 입력",
        termsOfService: "본인은 서비스 약관을 읽고 이해했으며",
        termsOfServiceLinkText: "이에 동의합니다",
        title: "테넌트 정보 추가",
      },
      addTenantSubTitle: "테넌트를 추가하려면 아래 단계를 완료하십시오.",
      addTenantSuccesMsg: "테넌트가 성공적으로 만들어짐",
      backBtn: "뒤로",
      country: {
        AC: "아센시온섬",
        AD: "안도라",
        AE: "아랍 에미리트 연합",
        AF: "아프가니스탄",
        AG: "앤티가 바부다",
        AI: "앵귈라",
        AL: "알바니아",
        AM: "아르메니아",
        AO: "앙골라",
        AQ: "남극",
        AR: "아르헨티나",
        AS: "미국령 사모아",
        AT: "오스트리아",
        AU: "오스트레일리아",
        AW: "아루바",
        AX: "올랜드 제도",
        AZ: "아제르바이잔",
        BA: "보스니아 헤르체고비나",
        BB: "바베이도스",
        BD: "방글라데시",
        BE: "벨기에",
        BF: "부르키나 파소",
        BG: "불가리아",
        BH: "바레인",
        BI: "부룬디",
        BJ: "베냉",
        BL: "생바르텔레미",
        BM: "버뮤다",
        BN: "브루나이",
        BO: "볼리비아",
        BQ: "보네르, 신트유스타티우스, 사바",
        BR: "브라질",
        BS: "바하마",
        BT: "부탄",
        BV: "부베섬",
        BW: "보츠와나",
        BY: "벨라루스",
        BZ: "벨리즈",
        CA: "캐나다",
        CC: "코코스 제도",
        CD: "콩고민주공화국",
        CF: "중앙 아프리카 공화국",
        CG: "콩고",
        CH: "스위스",
        CI: "코트디부아르",
        CK: "쿡 제도",
        CL: "칠레",
        CM: "카메룬",
        CN: "중국",
        CO: "콜롬비아",
        CR: "코스타리카",
        CU: "쿠바",
        CV: "카보베르데",
        CW: "퀴라소",
        CX: "크리스마스섬",
        CY: "키프로스",
        CZ: "체코공화국",
        DE: "독일",
        DJ: "지부티",
        DK: "덴마크",
        DM: "도미니카",
        DO: "도미니카공화국",
        DZ: "알제리아",
        EC: "에콰도르",
        EE: "에스토니아",
        EG: "이집트",
        EH: "서부 사하라",
        ER: "에리트레아",
        ES: "스페인",
        ET: "에티오피아",
        FI: "핀란드",
        FJ: "피지",
        FK: "포클랜드 제도",
        FM: "미크로네시아 연방",
        FO: "페로 제도",
        FR: "프랑스",
        GA: "가봉",
        GB: "영국",
        GD: "그레나다",
        GE: "조지아",
        GF: "프랑스령 기아나",
        GG: "건지",
        GH: "가나",
        GI: "지브롤터",
        GL: "그린란드",
        GM: "감비아",
        GN: "기니",
        GP: "과들루프",
        GQ: "적도 기니",
        GR: "그리스",
        GS: "사우스조지아 사우스샌드위치 제도",
        GT: "과테말라",
        GU: "괌",
        GW: "기니비사우",
        GY: "가이아나",
        HK: "홍콩",
        HM: "허드 맥도널드 제도",
        HN: "온두라스",
        HR: "크로아티아",
        HT: "아이티",
        HU: "헝가리",
        ID: "인도네시아",
        IE: "아일랜드",
        IL: "이스라엘",
        IM: "맨섬",
        IN: "인도",
        IO: "영국령 인도양 지역",
        IQ: "이라크",
        IR: "이란",
        IS: "아이슬랜드",
        IT: "이탈리아",
        JE: "저지",
        JM: "자메이카",
        JO: "요르단",
        JP: "일본",
        KE: "케냐",
        KG: "키르기스스탄",
        KH: "캄보디아",
        KI: "키리바시",
        KM: "코모로",
        KN: "세인트 크리스토퍼 네비스",
        KP: "북한",
        KR: "대한민국",
        KW: "쿠웨이트",
        KY: "카이만 제도",
        KZ: "카자흐스탄",
        LA: "라오스",
        LB: "레바논",
        LC: "세인트 루시아",
        LI: "리히텐슈타인",
        LK: "스리랑카",
        LR: "라이베리아",
        LS: "레소토",
        LT: "리투아니아",
        LU: "룩셈부르크",
        LV: "라트비아",
        LY: "리비아",
        MA: "모로코",
        MC: "모나코",
        MD: "몰도바",
        ME: "몬테네그로",
        MF: "생마르탱(프랑스령)",
        MG: "마다가스카",
        MH: "마셜 제도",
        MK: "북 마케도니아",
        ML: "말리",
        MM: "미얀마",
        MN: "몽고",
        MO: "마카오",
        MP: "북마리아나 제도",
        MQ: "마르티니크",
        MR: "모리타니",
        MS: "몬트세라트",
        MT: "몰타",
        MU: "모리셔스",
        MV: "몰디브",
        MW: "말라위",
        MX: "멕시코",
        MY: "말레이시아",
        MZ: "모잠비크",
        NA: "나미비아",
        NC: "뉴칼레도니아",
        NE: "니제르",
        NF: "노퍽섬",
        NG: "나이지리아",
        NI: "니카라과",
        NL: "네덜란드",
        NO: "노르웨이",
        NP: "네팔",
        NR: "나우루",
        NU: "니우에",
        NZ: "뉴질랜드",
        OM: "오만",
        OS: "남오세티야",
        PA: "파나마",
        PE: "페루",
        PF: "프랑스령 폴리네시아",
        PG: "파푸아뉴기니",
        PH: "필리핀",
        PK: "파키스탄",
        PL: "폴란드",
        PM: "세인트 피에르 미퀠론",
        PN: "핏케언",
        PR: "푸에르토리코",
        PS: "팔레스타인",
        PT: "포르투갈",
        PW: "팔라우",
        PY: "파라과이",
        QA: "카타르",
        RE: "리유니언",
        RO: "루마니아",
        RS: "세르비아",
        RU: "러시아",
        RW: "르완다",
        SA: "사우디아라비아",
        SB: "솔로몬 제도",
        SC: "세이셸",
        SD: "수단",
        SE: "스웨덴",
        SG: "싱가포르",
        SH: "세인트 헬레나",
        SI: "슬로베니아",
        SJ: "스발바르 얀마엔",
        SK: "슬로바키아",
        SL: "시에라리온",
        SM: "산마리노",
        SN: "세네갈",
        SO: "소말리아",
        SR: "수리남",
        SS: "남 수단",
        ST: "상투메 프린시페",
        SV: "엘살바도르",
        SX: "신트마르턴",
        SY: "시리아",
        SZ: "스와질랜드",
        TA: "트리스탄 다 쿠냐",
        TC: "터크스 카이코스 제도",
        TD: "차드",
        TF: "프랑스령 남방",
        TG: "토고",
        TH: "태국",
        TJ: "타지키스탄",
        TK: "토켈라우",
        TL: "동티모르",
        TM: "투르크메니스탄",
        TN: "튀니지",
        TO: "통가",
        TR: "터키",
        TT: "트리니다드 토바고",
        TV: "투발루",
        TW: "대만",
        TZ: "탄자니아",
        UA: "우크라이나",
        UG: "우간다",
        UM: "미국령 군소 제도",
        US: "미국",
        UY: "우루과이",
        UZ: "우즈베키스탄",
        VA: "교황청(바티칸 시국)",
        VC: "세인트 빈센트 그레나딘",
        VE: "베네수엘라",
        VG: "영국령 버진아일랜드",
        VI: "미국령 버진아일랜드",
        VN: "베트남",
        VU: "바누아투",
        WF: "월리스 푸투나",
        WS: "사모아",
        XK: "코소보",
        YE: "예멘",
        YT: "마요트",
        ZA: "남아프리카",
        ZM: "잠비아",
        ZW: "짐바브웨",
      },
      finishBtn: "마침",
      finishBtnInfo: "ShareFile 계정이 만들어집니다.",
      modal: {
        cancel: "취소",
        cancelAccount: "계정 취소",
        cancelAccountBody: {
          cancelAccount: "{accountName} 계정을 취소하시겠습니까?",
          cancelTrialAccount: "{accountName} 체험판 계정을 취소하시겠습니까?",
          para_2:
            "계정을 취소할 경우 현재 결제 기간이 끝나면 모든 ShareFile 서비스에 대한 테넌트의 액세스가 차단됩니다.",
          para_3:
            "이 작업은 되돌릴 수 없으며 진행 중인 테넌트의 프로젝트와 저장된 데이터에 대한 액세스에 영향을 미칠 수 있습니다.",
        },
        cancelTrial: "평가판 취소",
        changePlan: "계획 변경",
        continueTrial: "평가판 계속하기",
        convertAccount: "계정 전환",
        convertPlanBody: {
          changeAccount: "{accountName} 계정 요금제를 변경하십시오.",
          convertAccount:
            "{accountName} 계정을 체험판에서 유료 요금제로 전환하십시오.",
          helpText: "계획 유형에 대해 알아보기",
          needHelp: "선택에 도움이 필요하십니까?",
        },
        convertToPaid: "유료로 전환",
        increaseLicense: "라이선스 늘리기",
        keepAccount: "계정 유지",
        plans: {
          advanced: "2 -고급",
          advancedSubText: "이메일 플러그인, 고급 협업 및 보안",
          premium: "3 - 프리미엄",
          premiumSubText:
            "무제한 전자 서명, 자동화된 워크플로, 문서 요청, 프로젝트 공간, 통합",
          standard: "1 - 스탠다드",
          standardSubText: "저장, 동기화, 공유, 모바일 앱, 무제한 클라이언트",
        },
      },
      phoneNumber: {
        minCharacters: "최소 네 자리 숫자가 필요합니다.",
      },
      reactSelect: {
        noResults: "결과 없음",
        select: "국가 검색",
      },
      storageZone: {
        addToMultiTenant: "다중 테넌트에 추가(파트너 호스팅)",
        addToMultiTenantDescription:
          "파트너가 만든 다중 테넌트 스토리지 영역입니다.",
        addToMultiTenantInfo:
          "다중 테넌트 스토리지 영역에서는 모든 고객을 위해 하나의 스토리지 영역을 만들고 관리할 수 있습니다.",
        learnMore: "자세히 보기",
        sharefileCloudHosted: "ShareFile 클라우드 호스팅",
        sharefileCloudHostedDescription:
          "모든 것이 ShareFile 클라우드에 저장되고 여기에서 관리됩니다.",
        standrdPartnerHosted: "표준(파트너 호스팅)",
        standrdPartnerHostedDescription:
          "나중에 전용 스토리지 영역을 설정합니다.",
        subTitle: "고객의 스토리지 영역을 선택합니다.",
        title: "스토리지 영역",
      },
      tenant: {
        account_name: "테넌트 계정",
        add_tenant: " 테넌트 추가",
        cancelled: "취소됨",
        contact_support: "테넌트를 수정하려면 양식을 작성하십시오",
        contact_support_link: "CSP ShareFile 고객 계정 요청",
        download_csv: "CSV로 다운로드",
        fileName: "테넌트",
        license_used: " 라이선스",
        matching: "{{keyword}} 일치",
        none: "없음",
        paid: "유료",
        planName: "계획",
        search_tenants: "검색 테넌트",
        status: "상태",
        storage_used: "사용된 스토리지",
        storage_zone: "스토리지 영역",
        tenant_management: "테넌트 관리",
        tenants: "테넌트: ",
        total_licenses: "사용된 총 라이선스:",
        trial: "평가판",
      },
    },
  },
  {
    key: "nl",
    content: {
      accountDetails: {
        accountType: {
          paidService: "Betaalde service",
          subTitle:
            "U wordt gefactureerd voor licenties die aan gebruikers zijn toegewezen.",
          title: "Type account",
          trial: "Proefabonnement",
          trialInfo: "30 dagen op proef met beperkte opslagruimte",
        },
        plan: {
          advanced: "Advanced",
          learnAboutPalnTypes: "Meer informatie over abonnementstypen",
          premium: "Premium",
          standard: "Standaard",
          subTitle: "Hulp nodig bij het kiezen?",
          title: "Abonnement",
        },
        subDomain: {
          checkAvailability: "Beschikbaarheid controleren",
          checkAvailabilityApiErrorMsg:
            "Het door u opgegeven subdomein wordt door een ander account gebruikt",
          checkAvailabilityApiSuccessMsg: "Beschikbaar",
          controlPlane: "Besturingslaag",
          options: {
            "0": "Selecteer uw land",
            "1": "Verenigde Staten",
            "2": "Europa (Duitsland)",
          },
          subTitle:
            "Uw subdomein is uw unieke URL voor uw ShareFile-account. U kunt dit later wijzigen.",
          title: "Subdomein",
        },
        supportInfo: {
          areaCode: "Netnummer",
          countryCode: "Land",
          email: "E-mail",
          enableSupportInfo:
            "Zorg voor een contactpersoon voor ondersteuning van tenants",
          name: "Naam",
          phoneNumber: "Telefoonnummer",
          subTitle:
            "Voer hier de contactgegevens voor ondersteuning van tenants in. Alle velden moeten verplicht worden ingevoerd.",
          title: "Ondersteuningsinformatie",
        },
        title: "Accountgegevens",
      },
      addTenant: "Tenant toevoegen",
      addTenantInfo: {
        adminDetails: {
          email: "E-mail",
          firstName: "Voornaam",
          lastName: "Achternaam",
          title: "Hoofdbeheerder",
        },
        bussinessDetails: {
          address: "Adres",
          areaCode: "Netnummer",
          city: "Plaats",
          companyName: "Bedrijfsnaam",
          country: "Land",
          phoneNumber: "Telefoonnummer",
          postalCode: "Postcode",
          postalCodeErrorMsg: "Ongeldige code",
          state: "Provincie",
          title: "Bedrijfsgegevens",
        },
        subTitle: "Voer gegevens van beheerder en tenants in",
        termsOfService: "Ik heb gelezen, begrepen en ga akkoord met:",
        termsOfServiceLinkText: "Servicevoorwaarden",
        title: "Tenantgegevens toevoegen",
      },
      addTenantSubTitle:
        "Voer de onderstaande stappen uit om een tenant toe te voegen.",
      addTenantSuccesMsg: "Tenant is gemaakt",
      backBtn: "Terug",
      country: {
        AC: "Ascension (eiland)",
        AD: "Andorra",
        AE: "Verenigde Arabische Emiraten",
        AF: "Afghanistan",
        AG: "Antigua en Barbuda",
        AI: "Anguilla",
        AL: "Albanië",
        AM: "Armenië",
        AO: "Angola",
        AQ: "Antarctica",
        AR: "Argentinië",
        AS: "Amerikaans-Samoa",
        AT: "Oostenrijk",
        AU: "Australië",
        AW: "Aruba",
        AX: "Åland-eilanden",
        AZ: "Azerbeidzjan",
        BA: "Bosnië en Herzegovina",
        BB: "Barbados",
        BD: "Bangladesh",
        BE: "België",
        BF: "Burkina Faso",
        BG: "Bulgarije",
        BH: "Bahrein",
        BI: "Burundi",
        BJ: "Benin",
        BL: "Saint-Barthélemy (eiland)",
        BM: "Bermuda",
        BN: "Brunei Darussalam",
        BO: "Bolivia",
        BQ: "Bonaire, Sint Eustatius en Saba",
        BR: "Brazilië",
        BS: "Bahamas",
        BT: "Bhutan",
        BV: "Bouvet (eiland)",
        BW: "Botswana",
        BY: "Wit-Rusland",
        BZ: "Belize",
        CA: "Canada",
        CC: "Cocoseilanden (Keeling)",
        CD: "Congo, Democratische Republiek van de",
        CF: "Centraal-Afrikaanse Republiek",
        CG: "Congo",
        CH: "Zwitserland",
        CI: "Ivoorkust",
        CK: "Cookeilanden",
        CL: "Chili",
        CM: "Kameroen",
        CN: "China",
        CO: "Colombia",
        CR: "Costa Rica",
        CU: "Cuba",
        CV: "Kaapverdië",
        CW: "Curaçao",
        CX: "Christmaseiland",
        CY: "Cyprus",
        CZ: "Tsjechische Republiek",
        DE: "Duitsland",
        DJ: "Djibouti",
        DK: "Denemarken",
        DM: "Dominica",
        DO: "Dominicaanse Republiek",
        DZ: "Algerije",
        EC: "Ecuador",
        EE: "Estland",
        EG: "Egypte",
        EH: "Westelijke Sahara",
        ER: "Eritrea",
        ES: "Spanje",
        ET: "Ethiopië",
        FI: "Finland",
        FJ: "Fiji",
        FK: "Falklandeilanden",
        FM: "Federale Staten van Micronesië",
        FO: "Faeröer",
        FR: "Frankrijk",
        GA: "Gabon",
        GB: "Verenigd Koninkrijk",
        GD: "Grenada",
        GE: "Georgië",
        GF: "Frans-Guyana",
        GG: "Guernsey",
        GH: "Ghana",
        GI: "Gibraltar",
        GL: "Groenland",
        GM: "Gambia",
        GN: "Guinee",
        GP: "Guadeloupe",
        GQ: "Equatoriaal-Guinea",
        GR: "Griekenland",
        GS: "Zuid-Georgia en de Zuidelijke Sandwicheilanden",
        GT: "Guatemala",
        GU: "Guam",
        GW: "Guinee-Bissau",
        GY: "Guyana",
        HK: "Hong Kong",
        HM: "Heard en McDonald-eilanden",
        HN: "Honduras",
        HR: "Kroatië",
        HT: "Haïti",
        HU: "Hongarije",
        ID: "Indonesië",
        IE: "Ierland",
        IL: "Israël",
        IM: "Eiland Man",
        IN: "India",
        IO: "Brits Territorium in de Indische Oceaan",
        IQ: "Irak",
        IR: "Iran",
        IS: "IJsland",
        IT: "Italië",
        JE: "Jersey",
        JM: "Jamaica",
        JO: "Jordanië",
        JP: "Japan",
        KE: "Kenia",
        KG: "Kirgizische Republiek",
        KH: "Cambodja",
        KI: "Kiribati",
        KM: "Comoren",
        KN: "Saint Kitts en Nevis",
        KP: "Noord-Korea",
        KR: "Zuid-Korea",
        KW: "Koeweit",
        KY: "Caymaneilanden",
        KZ: "Kazachstan",
        LA: "Laos",
        LB: "Libanon",
        LC: "Saint Lucia",
        LI: "Liechtenstein",
        LK: "Sri Lanka",
        LR: "Liberia",
        LS: "Lesotho",
        LT: "Litouwen",
        LU: "Luxemburg",
        LV: "Letland",
        LY: "Libië",
        MA: "Marokko",
        MC: "Monaco",
        MD: "Moldavië",
        ME: "Montenegro",
        MF: "Saint Martin (Franse deel)",
        MG: "Madagaskar",
        MH: "Marshalleilanden",
        MK: "Noord-Macedonië",
        ML: "Mali",
        MM: "Myanmar",
        MN: "Mongolië",
        MO: "Macau",
        MP: "Noordelijke Marianen",
        MQ: "Martinique",
        MR: "Mauritanië",
        MS: "Montserrat",
        MT: "Malta",
        MU: "Mauritius",
        MV: "Maldiven",
        MW: "Malawi",
        MX: "Mexico",
        MY: "Maleisië",
        MZ: "Mozambique",
        NA: "Namibië",
        NC: "Nieuw-Caledonië",
        NE: "Niger",
        NF: "Norfolkeiland",
        NG: "Nigeria",
        NI: "Nicaragua",
        NL: "Nederland",
        NO: "Noorwegen",
        NP: "Nepal",
        NR: "Nauru",
        NU: "Niue",
        NZ: "Nieuw-Zeeland",
        OM: "Oman",
        OS: "Zuid-Ossetië",
        PA: "Panama",
        PE: "Peru",
        PF: "Frans-Polynesië",
        PG: "Papoea-Nieuw-Guinea",
        PH: "Filipijnen",
        PK: "Pakistan",
        PL: "Polen",
        PM: "Saint Pierre en Miquelon",
        PN: "Pitcairn",
        PR: "Puerto Rico",
        PS: "Palestina",
        PT: "Portugal",
        PW: "Palau",
        PY: "Paraguay",
        QA: "Qatar",
        RE: "Réunion",
        RO: "Roemenië",
        RS: "Servië",
        RU: "Rusland",
        RW: "Rwanda",
        SA: "Saoedi-Arabië",
        SB: "Solomoneilanden",
        SC: "Seychellen",
        SD: "Soedan",
        SE: "Zweden",
        SG: "Singapore",
        SH: "Sint-Helena",
        SI: "Slovenië",
        SJ: "Spitsbergen en Jan Mayen",
        SK: "Slowakije",
        SL: "Sierra Leone",
        SM: "San Marino",
        SN: "Senegal",
        SO: "Somalië",
        SR: "Suriname",
        SS: "Zuid-Soedan",
        ST: "Sao Tomé en Principe",
        SV: "El Salvador",
        SX: "Sint Maarten",
        SY: "Syrië",
        SZ: "Swaziland",
        TA: "Tristan da Cunha",
        TC: "Turks- en Caicoseilanden",
        TD: "Tsjaad",
        TF: "Franse Zuidelijke Gebieden",
        TG: "Togo",
        TH: "Thailand",
        TJ: "Tadzjikistan",
        TK: "Tokelau",
        TL: "Oost-Timor",
        TM: "Turkmenistan",
        TN: "Tunesië",
        TO: "Tonga",
        TR: "Turkije",
        TT: "Trinidad en Tobago",
        TV: "Tuvalu",
        TW: "Taiwan",
        TZ: "Tanzania",
        UA: "Oekraïne",
        UG: "Uganda",
        UM: "Kleine afgelegen eilanden van de Verenigde Staten",
        US: "Verenigde Staten",
        UY: "Uruguay",
        UZ: "Oezbekistan",
        VA: "Heilige Stoel (Vaticaanstad)",
        VC: "Saint Vincent en de Grenadines",
        VE: "Venezuela",
        VG: "Britse Maagdeneilanden",
        VI: "Amerikaanse Maagdeneilanden",
        VN: "Vietnam",
        VU: "Vanuatu",
        WF: "Wallis en Futuna",
        WS: "Samoa",
        XK: "Kosovo",
        YE: "Jemen",
        YT: "Mayotte",
        ZA: "Zuid-Afrika",
        ZM: "Zambia",
        ZW: "Zimbabwe",
      },
      finishBtn: "Voltooien",
      finishBtnInfo: "Het ShareFile-account wordt gemaakt.",
      modal: {
        cancel: "Annuleren",
        cancelAccount: "Account annuleren",
        cancelAccountBody: {
          cancelAccount:
            "Weet u zeker dat u het account van {accountName} wilt annuleren?",
          cancelTrialAccount:
            "Weet u zeker dat u het proefaccount van {accountName} wilt annuleren?",
          para_2:
            "Houd er rekening mee dat het annuleren van het account de toegang van de tenant tot alle ShareFile-services aan het einde van de huidige factureringsperiode blokkeert.",
          para_3:
            "Deze actie is onomkeerbaar en kan van invloed zijn op de lopende projecten van de tenant en de toegang tot opgeslagen gegevens.",
        },
        cancelTrial: "Proefabonnement annuleren",
        changePlan: "Abonnement wijzigen",
        continueTrial: "Doorgaan met proef",
        convertAccount: "Account omzetten",
        convertPlanBody: {
          changeAccount: "Wijzig het abonnement van het account {accountName}.",
          convertAccount:
            "Zet het account van {accountName} om van een proefabonnement naar een betaald abonnement.",
          helpText: "Meer informatie over abonnementstypen",
          needHelp: "Hulp nodig bij het kiezen? ",
        },
        convertToPaid: "Omzetten naar betaald",
        increaseLicense: "Licentie verhogen",
        keepAccount: "Account behouden",
        plans: {
          advanced: "2 - Geavanceerd",
          advancedSubText:
            "E-mailplug-ins, geavanceerde samenwerking en beveiliging",
          premium: "3 - Premium",
          premiumSubText:
            "Onbeperkte digitale handtekeningen, geautomatiseerde werkstromen, documentaanvragen, projectruimtes, integraties",
          standard: "1 - Standaard",
          standardSubText:
            "Opslaan, synchroniseren, delen; mobiele apps, onbeperkt aantal klanten",
        },
      },
      phoneNumber: {
        minCharacters: "Minimaal vier cijfers vereist.",
      },
      reactSelect: {
        noResults: "Geen resultaten gevonden",
        select: "Land zoeken",
      },
      storageZone: {
        addToMultiTenant: "Toevoegen aan multi-tenant (door partner gehost)",
        addToMultiTenantDescription:
          "Door de partner gemaakte multi-tenant opslagzones.",
        addToMultiTenantInfo:
          "Met opslagzones voor meerdere tenants kunt u één opslagzone voor al uw klanten maken en beheren.",
        learnMore: "Meer informatie",
        sharefileCloudHosted: "In de ShareFile-cloud gehost",
        sharefileCloudHostedDescription:
          "Alles wordt opgeslagen en beheerd in de ShareFile-cloud.",
        standrdPartnerHosted: "Standaard (door partner gehost)",
        standrdPartnerHostedDescription:
          "Stel later een speciale opslagzone in.",
        subTitle: "Selecteer de opslagzone van de klant.",
        title: "Opslagzone",
      },
      tenant: {
        account_name: "Tenantaccount",
        add_tenant: " Tenant toevoegen",
        cancelled: "Geannuleerd",
        contact_support:
          "Als u uw tenants wilt wijzigen, vult u het formulier in",
        contact_support_link: "CSP ShareFile Klantaccountaanvraagformulier",
        download_csv: "Downloaden als CSV",
        fileName: "Tenant",
        license_used: "Licenties",
        matching: "komt overeen met {{keyword}}",
        none: "Geen",
        paid: "Betaald",
        planName: "Abonnement",
        search_tenants: "Tenants zoeken",
        status: "Status",
        storage_used: "Gebruikte opslag",
        storage_zone: "Opslagzone",
        tenant_management: "Tenantbeheer",
        tenants: "Tenants: ",
        total_licenses: "Totaal gebruikte licenties:",
        trial: "Proefabonnement",
      },
    },
  },
  {
    key: "pt-BR",
    content: {
      accountDetails: {
        accountType: {
          paidService: "Serviço pago",
          subTitle: "Você será cobrado pelas licenças atribuídas aos usuários.",
          title: "Tipo de conta",
          trial: "Avaliação",
          trialInfo: "30 dias de avaliação com armazenamento limitado",
        },
        plan: {
          advanced: "Advanced",
          learnAboutPalnTypes: "Conheça os tipos de planos",
          premium: "Premium",
          standard: "Standard",
          subTitle: "Precisa de ajuda para escolher?",
          title: "Plano",
        },
        subDomain: {
          checkAvailability: "Verificar disponibilidade",
          checkAvailabilityApiErrorMsg:
            "O subdomínio que você especificou é usado por outra conta",
          checkAvailabilityApiSuccessMsg: "Disponível",
          controlPlane: "Plano de controle",
          options: {
            "0": "Selecione seu país",
            "1": "Estados Unidos",
            "2": "Europa (Alemanha)",
          },
          subTitle:
            "Seu subdomínio é seu URL exclusivo para sua conta do ShareFile. Você pode alterar isso mais tarde.",
          title: "Subdomínio",
        },
        supportInfo: {
          areaCode: "Código de área",
          countryCode: "País",
          email: "Email",
          enableSupportInfo: "Fornecer um contato de suporte para locatários",
          name: "Nome",
          phoneNumber: "Número de telefone",
          subTitle:
            "Insira os detalhes de contato de suporte para locatários aqui. Todos os campos são obrigatórios no caso de inserimento.",
          title: "Informações de suporte",
        },
        title: "Detalhes da conta",
      },
      addTenant: "Adicionar um locatário",
      addTenantInfo: {
        adminDetails: {
          email: "Email",
          firstName: "Primeiro nome",
          lastName: "Sobrenome",
          title: "Administrador principal",
        },
        bussinessDetails: {
          address: "Endereço",
          areaCode: "Código de área",
          city: "Cidade",
          companyName: "Nome da empresa",
          country: "País",
          phoneNumber: "Número de telefone",
          postalCode: "Código Postal",
          postalCodeErrorMsg: "Código Inválido",
          state: "Estado/Província",
          title: "Detalhes da empresa",
        },
        subTitle: "Insira os detalhes do administrador e do locatário",
        termsOfService: "Eu li, entendi e concordo com os",
        termsOfServiceLinkText: "Termos de Serviço",
        title: "Adicionar informações do locatário",
      },
      addTenantSubTitle:
        "Para adicionar um locatário, siga estas etapas abaixo.",
      addTenantSuccesMsg: "Locatário criado com sucesso",
      backBtn: "Voltar",
      country: {
        AC: "Ilha de Ascensão",
        AD: "Andorra",
        AE: "Emirados Árabes Unidos",
        AF: "Afeganistão",
        AG: "Antígua e Barbuda",
        AI: "Anguilla",
        AL: "Albânia",
        AM: "Armênia",
        AO: "Angola",
        AQ: "Antártica",
        AR: "Argentina",
        AS: "Samoa Americana",
        AT: "Áustria",
        AU: "Austrália",
        AW: "Aruba",
        AX: "Ilhas Aland",
        AZ: "Azerbaijão",
        BA: "Bósnia e Herzegovina",
        BB: "Barbados",
        BD: "Bangladesh",
        BE: "Bélgica",
        BF: "Burkina Faso",
        BG: "Bulgária",
        BH: "Barein",
        BI: "Burundi",
        BJ: "Benin",
        BL: "São Bartolomeu",
        BM: "Bermudas",
        BN: "Brunei Darussalam",
        BO: "Bolívia",
        BQ: "Bonaire, Santo Eustáquio e Saba",
        BR: "Brasil",
        BS: "Bahamas",
        BT: "Butão",
        BV: "Ilha Bouvet",
        BW: "Botswana",
        BY: "Belarus",
        BZ: "Belize",
        CA: "Canadá",
        CC: "Ilhas Cocos (Keeling)",
        CD: "Congo, República Democrática do",
        CF: "República Centro-Africana",
        CG: "Congo",
        CH: "Suíça",
        CI: "Costa do Marfim",
        CK: "Ilhas Cook",
        CL: "Chile",
        CM: "Camarões",
        CN: "China",
        CO: "Colômbia",
        CR: "Costa Rica",
        CU: "Cuba",
        CV: "Cabo Verde",
        CW: "Curaçao",
        CX: "Ilha Christmas",
        CY: "Chipre",
        CZ: "República Tcheca",
        DE: "Alemanha",
        DJ: "Djibuti",
        DK: "Dinamarca",
        DM: "Dominica",
        DO: "República Dominicana",
        DZ: "Argélia",
        EC: "Equador",
        EE: "Estônia",
        EG: "Egito",
        EH: "Saara Ocidental",
        ER: "Eritreia",
        ES: "Espanha",
        ET: "Etiópia",
        FI: "Finlândia",
        FJ: "Fiji",
        FK: "Ilhas Malvinas",
        FM: "Estados Federados da Micronésia",
        FO: "Ilhas Faroé",
        FR: "França",
        GA: "Gabão",
        GB: "Reino Unido",
        GD: "Granada",
        GE: "Geórgia",
        GF: "Guiana Francesa",
        GG: "Guernsey",
        GH: "Gana",
        GI: "Gibraltar",
        GL: "Groenlândia",
        GM: "Gâmbia",
        GN: "Guiné",
        GP: "Guadalupe",
        GQ: "Guiné Equatorial",
        GR: "Grécia",
        GS: "Ilhas Geórgia do Sul e Sandwich do Sul",
        GT: "Guatemala",
        GU: "Guam",
        GW: "Guiné-Bissau",
        GY: "Guiana",
        HK: "Hong Kong",
        HM: "Ilha Heard e Ilhas McDonald",
        HN: "Honduras",
        HR: "Croácia",
        HT: "Haiti",
        HU: "Hungria",
        ID: "Indonésia",
        IE: "Irlanda",
        IL: "Israel",
        IM: "Ilha de Man",
        IN: "Índia",
        IO: "Território Britânico do Oceano Índico",
        IQ: "Iraque",
        IR: "Irã",
        IS: "Islândia",
        IT: "Itália",
        JE: "Jersey",
        JM: "Jamaica",
        JO: "Jordânia",
        JP: "Japão",
        KE: "Quênia",
        KG: "Quirguistão",
        KH: "Camboja",
        KI: "Kiribati",
        KM: "Comores",
        KN: "São Cristóvão e Névis",
        KP: "Coreia do Norte",
        KR: "Coreia do Sul",
        KW: "Kuwait",
        KY: "Ilhas Cayman",
        KZ: "Cazaquistão",
        LA: "Laos",
        LB: "Líbano",
        LC: "Santa Lúcia",
        LI: "Liechtenstein",
        LK: "Sri Lanka",
        LR: "Libéria",
        LS: "Lesoto",
        LT: "Lituânia",
        LU: "Luxemburgo",
        LV: "Letônia",
        LY: "Líbia",
        MA: "Marrocos",
        MC: "Mônaco",
        MD: "Moldova",
        ME: "Montenegro",
        MF: "São Martinho (parte francesa)",
        MG: "Madagáscar",
        MH: "Ilhas Marshall",
        MK: "Macedônia do Norte",
        ML: "Mali",
        MM: "Myanmar",
        MN: "Mongólia",
        MO: "Macau",
        MP: "Ilhas Marianas do Norte",
        MQ: "Martinica",
        MR: "Mauritânia",
        MS: "Montserrat",
        MT: "Malta",
        MU: "Maurício",
        MV: "Maldivas",
        MW: "Malaui",
        MX: "México",
        MY: "Malásia",
        MZ: "Moçambique",
        NA: "Namíbia",
        NC: "Nova Caledônia",
        NE: "Níger",
        NF: "Ilha Norfolk",
        NG: "Nigéria",
        NI: "Nicarágua",
        NL: "Países Baixos",
        NO: "Noruega",
        NP: "Nepal",
        NR: "Nauru",
        NU: "Niue",
        NZ: "Nova Zelândia",
        OM: "Omã",
        OS: "Ossétia do Sul",
        PA: "Panamá",
        PE: "Peru",
        PF: "Polinésia Francesa",
        PG: "Papua Nova Guiné",
        PH: "Filipinas",
        PK: "Paquistão",
        PL: "Polônia",
        PM: "Saint-Pierre e Miquelon",
        PN: "Pitcairn",
        PR: "Porto Rico",
        PS: "Palestina",
        PT: "Portugal",
        PW: "Palau",
        PY: "Paraguai",
        QA: "Catar",
        RE: "Reunião",
        RO: "Romênia",
        RS: "Sérvia",
        RU: "Rússia",
        RW: "Ruanda",
        SA: "Arábia Saudita",
        SB: "Ilhas Salomão",
        SC: "Seychelles",
        SD: "Sudão",
        SE: "Suécia",
        SG: "Cingapura",
        SH: "Santa Helena",
        SI: "Eslovênia",
        SJ: "Svalbard e Jan Mayen",
        SK: "Eslováquia",
        SL: "Serra Leoa",
        SM: "San Marino",
        SN: "Senegal",
        SO: "Somália",
        SR: "Suriname",
        SS: "Sudão do Sul",
        ST: "São Tomé e Príncipe",
        SV: "El Salvador",
        SX: "São Martinho (Países Baixos)",
        SY: "Síria",
        SZ: "Suazilândia",
        TA: "Tristão da Cunha",
        TC: "Ilhas Turks e Caicos",
        TD: "Chade",
        TF: "Territórios Franceses do Sul",
        TG: "Togo",
        TH: "Tailândia",
        TJ: "Tajiquistão",
        TK: "Tokelau",
        TL: "Timor-Leste",
        TM: "Turcomenistão",
        TN: "Tunísia",
        TO: "Tonga",
        TR: "Peru",
        TT: "Trinidad e Tobago",
        TV: "Tuvalu",
        TW: "Taiwan",
        TZ: "Tanzânia",
        UA: "Ucrânia",
        UG: "Uganda",
        UM: "Ilhas Menores Distantes dos Estados Unidos",
        US: "Estados Unidos",
        UY: "Uruguai",
        UZ: "Uzbequistão",
        VA: "Santa Sé (Estado da Cidade do Vaticano)",
        VC: "São Vicente e Granadinas",
        VE: "Venezuela",
        VG: "Ilhas Virgens Britânicas",
        VI: "Ilhas Virgens Americanas",
        VN: "Vietnã",
        VU: "Vanuatu",
        WF: "Wallis e Futuna",
        WS: "Samoa",
        XK: "Kosovo",
        YE: "Iêmen",
        YT: "Maiote",
        ZA: "África do Sul",
        ZM: "Zâmbia",
        ZW: "Zimbábue",
      },
      finishBtn: "Terminar",
      finishBtnInfo: "A conta do ShareFile será criada.",
      modal: {
        cancel: "Cancelar",
        cancelAccount: "Cancelar conta",
        cancelAccountBody: {
          cancelAccount:
            "Tem certeza de que deseja cancelar a conta {accountName}?",
          cancelTrialAccount:
            "Tem certeza de que deseja cancelar a conta de avaliação {accountName}?",
          para_2:
            "Esteja ciente de que o cancelamento da conta bloqueará o acesso do locatário a todos os serviços do ShareFile no final do período de cobrança atual.",
          para_3:
            "Essa ação é irreversível e pode afetar os projetos em andamento do inquilino e o acesso aos dados armazenados.",
        },
        cancelTrial: "Cancelar avaliação",
        changePlan: "Mudar de plano",
        continueTrial: "Continuar avaliação",
        convertAccount: "Converter conta",
        convertPlanBody: {
          changeAccount: "Mudar o plano da conta {accountName}.",
          convertAccount:
            "Converter a {accountName} conta de um plano de avaliação em um plano pago.",
          helpText: "Conheça os tipos de planos",
          needHelp: "Precisa de ajuda para escolher? ",
        },
        convertToPaid: "Converter em paga",
        increaseLicense: "Aumentar a licença",
        keepAccount: "Manter a conta",
        plans: {
          advanced: "2 - Avançado",
          advancedSubText:
            "Plug-ins de email, colaboração avançada e segurança",
          premium: "3 - Premium",
          premiumSubText:
            "Assinaturas eletrônicas ilimitadas, fluxos de trabalho automatizados, solicitações de documentos, espaços de projetos, integrações",
          standard: "1 - Padrão",
          standardSubText:
            "Armazenar, sincronizar, compartilhar; aplicativos móveis, clientes ilimitados",
        },
      },
      phoneNumber: {
        minCharacters: "É necessário um mínimo de quatro dígitos.",
      },
      reactSelect: {
        noResults: "Nenhum resultado encontrado",
        select: "Pesquisar país",
      },
      storageZone: {
        addToMultiTenant:
          "Adicionar ao multilocatário (hospedado pelo parceiro)",
        addToMultiTenantDescription:
          "Zonas de armazenamento multilocatário criadas pelo parceiro.",
        addToMultiTenantInfo:
          "As zonas de armazenamento de multilocatários permitem que você crie e gerencie uma única zona de armazenagem para todos os seus clientes.",
        learnMore: "Saber mais",
        sharefileCloudHosted: "ShareFile hospedado na nuvem",
        sharefileCloudHostedDescription:
          "Tudo será armazenado e gerenciado na nuvem do ShareFile.",
        standrdPartnerHosted: "Padrão (hospedado por um parceiro)",
        standrdPartnerHostedDescription:
          "Configure uma zona de armazenamento dedicada posteriormente.",
        subTitle: "Selecione a zona de armazenamento do cliente.",
        title: "Zona de armazenamento",
      },
      tenant: {
        account_name: "Conta de locatário",
        add_tenant: " Adicionar locatário",
        cancelled: "Cancelado",
        contact_support:
          "Para modificar seus locatários, preencha o formulário",
        contact_support_link:
          "Formulário de solicitação de conta de cliente do ShareFile CSP",
        download_csv: "Baixar como CSV",
        fileName: "Locatário",
        license_used: "Licenças",
        matching: "correspondente a {{keyword}}",
        none: "Nenhum",
        paid: "Pago",
        planName: "Plano",
        search_tenants: "Pesquisar locatários",
        status: "Status",
        storage_used: "Armazenamento utilizado",
        storage_zone: "Zona de armazenamento",
        tenant_management: "Gerenciamento de locatários",
        tenants: "Locatários: ",
        total_licenses: "Total de licenças usadas:",
        trial: "Avaliação",
      },
    },
  },
  {
    key: "ru",
    content: {
      accountDetails: {
        accountType: {
          paidService: "Платная услуга",
          subTitle:
            "Вам будет выставлен счет за лицензии, назначенные пользователям.",
          title: "Тип учетной записи",
          trial: "Пробная версия",
          trialInfo:
            "30-дневная пробная версия с ограниченным объемом хранилища",
        },
        plan: {
          advanced: "Advanced",
          learnAboutPalnTypes: "Подробнее о типах планов",
          premium: "Premium",
          standard: "Standard",
          subTitle: "Нужна помощь в выборе?",
          title: "План",
        },
        subDomain: {
          checkAvailability: "Проверить доступность",
          checkAvailabilityApiErrorMsg:
            "Указанный вами поддомен используется другой учетной записью",
          checkAvailabilityApiSuccessMsg: "Доступно",
          controlPlane: "Плоскость управления",
          options: {
            "0": "Выберите свою страну",
            "1": "Соединенные Штаты Америки",
            "2": "Европа (Германия)",
          },
          subTitle:
            "Поддомен — это уникальный URL-адрес вашей учетной записи ShareFile. Вы можете изменить это позже.",
          title: "Поддомен",
        },
        supportInfo: {
          areaCode: "Код города",
          countryCode: "Страна",
          email: "Электронная почта",
          enableSupportInfo:
            "Укажите контактное лицо службы поддержки для арендаторов",
          name: "Имя",
          phoneNumber: "Номер телефона",
          subTitle:
            "Введите здесь контактные данные службы поддержки для арендаторов. Все поля обязательны для заполнения при вводе.",
          title: "Информация о поддержке",
        },
        title: "Сведения об учетной записи",
      },
      addTenant: "Добавить арендатора",
      addTenantInfo: {
        adminDetails: {
          email: "Электронная почта",
          firstName: "Имя",
          lastName: "Фамилия",
          title: "Главный администратор",
        },
        bussinessDetails: {
          address: "Адрес",
          areaCode: "Код города",
          city: "Город",
          companyName: "Название компании",
          country: "Страна",
          phoneNumber: "Номер телефона",
          postalCode: "Почтовый индекс",
          postalCodeErrorMsg: "Неверный код",
          state: "Область, край",
          title: "Сведения о бизнесе",
        },
        subTitle: "Ввод сведений об администраторе и арендаторе",
        termsOfService: "Я прочитал, понял и принимаю",
        termsOfServiceLinkText: "условия использования службы",
        title: "Добавление сведений об арендаторе",
      },
      addTenantSubTitle:
        "Чтобы добавить арендатора, выполните следующие действия.",
      addTenantSuccesMsg: "Арендатор создан",
      backBtn: "Назад",
      country: {
        AC: "Остров Вознесения",
        AD: "Андорра",
        AE: "Объединенные Арабские Эмираты",
        AF: "Афганистан",
        AG: "Антигуа и Барбуда",
        AI: "Ангилья",
        AL: "Албания",
        AM: "Армения",
        AO: "Ангола",
        AQ: "Антарктида",
        AR: "Аргентина",
        AS: "Американское Самоа",
        AT: "Австрия",
        AU: "Австралия",
        AW: "Аруба",
        AX: "Аландские острова",
        AZ: "Азербайджан",
        BA: "Босния и Герцеговина",
        BB: "Барбадос",
        BD: "Бангладеш",
        BE: "Бельгия",
        BF: "Буркина-Фасо",
        BG: "Болгария",
        BH: "Бахрейн",
        BI: "Бурунди",
        BJ: "Бенин",
        BL: "Сен-Бартелеми",
        BM: "Бермудские острова",
        BN: "Бруней-Даруссалам",
        BO: "Боливия",
        BQ: "Бонайре, Синт-Эстатиус и Саба",
        BR: "Бразилия",
        BS: "Багамские острова",
        BT: "Бутан",
        BV: "Остров Буве",
        BW: "Ботсвана",
        BY: "Беларусь",
        BZ: "Белиз",
        CA: "Канада",
        CC: "Кокосовые острова (Килинг)",
        CD: "Демократическая Республика Конго",
        CF: "Центральноафриканская Республика",
        CG: "Конго",
        CH: "Швейцария",
        CI: "Кот д'Ивуар",
        CK: "Острова Кука",
        CL: "Чили",
        CM: "Камерун",
        CN: "Китай",
        CO: "Колумбия",
        CR: "Коста-Рика",
        CU: "Куба",
        CV: "Кабо-Верде",
        CW: "Кюрасао",
        CX: "Остров Рождества",
        CY: "Кипр",
        CZ: "Чехия",
        DE: "Германия",
        DJ: "Джибути",
        DK: "Дания",
        DM: "Доминика",
        DO: "Доминиканская республика",
        DZ: "Алжир",
        EC: "Эквадор",
        EE: "Эстония",
        EG: "Египет",
        EH: "Западная Сахара",
        ER: "Эритрея",
        ES: "Испания",
        ET: "Эфиопия",
        FI: "Финляндия",
        FJ: "Фиджи",
        FK: "Фолклендские острова",
        FM: "Федеративные Штаты Микронезии",
        FO: "Фарерские острова",
        FR: "Франция",
        GA: "Габон",
        GB: "Великобритания",
        GD: "Гренада",
        GE: "Грузия",
        GF: "Французская Гвиана",
        GG: "Гернси",
        GH: "Гана",
        GI: "Гибралтар",
        GL: "Гренландия",
        GM: "Гамбия",
        GN: "Гвинея",
        GP: "Гваделупа",
        GQ: "Экваториальная Гвинея",
        GR: "Греция",
        GS: "Южная Георгия и Южные Сандвичевы острова",
        GT: "Гватемала",
        GU: "Гуам",
        GW: "Гвинея-Бисау",
        GY: "Гайана",
        HK: "Гонконг",
        HM: "Остров Херд и острова Макдональд",
        HN: "Гондурас",
        HR: "Хорватия",
        HT: "Гаити",
        HU: "Венгрия",
        ID: "Индонезия",
        IE: "Ирландия",
        IL: "Израиль",
        IM: "Остров Мэн",
        IN: "Индия",
        IO: "Британская территория Индийского океана",
        IQ: "Ирак",
        IR: "Иран",
        IS: "Исландия",
        IT: "Италия",
        JE: "Джерси",
        JM: "Ямайка",
        JO: "Иордания",
        JP: "Япония",
        KE: "Кения",
        KG: "Киргизия",
        KH: "Камбоджа",
        KI: "Кирибати",
        KM: "Коморские острова",
        KN: "Сент-Китс и Невис",
        KP: "Корейская народно-демократическая республика",
        KR: "Южная Корея",
        KW: "Кувейт",
        KY: "Каймановы острова",
        KZ: "Казахстан",
        LA: "Лаос",
        LB: "Ливан",
        LC: "Сент-Люсия",
        LI: "Лихтенштейн",
        LK: "Шри-Ланка",
        LR: "Либерия",
        LS: "Лесото",
        LT: "Литва",
        LU: "Люксембург",
        LV: "Латвия",
        LY: "Ливия",
        MA: "Марокко",
        MC: "Монако",
        MD: "Молдова",
        ME: "Черногория",
        MF: "Сен-Мартен (французская часть)",
        MG: "Мадагаскар",
        MH: "Маршалловы Острова",
        MK: "Северная Македония",
        ML: "Мали",
        MM: "Мьянма",
        MN: "Монголия",
        MO: "Макао",
        MP: "Северные Марианские о-ва",
        MQ: "Мартиника",
        MR: "Мавритания",
        MS: "Монтсеррат",
        MT: "Мальта",
        MU: "Маврикий",
        MV: "Мальдивы",
        MW: "Малави",
        MX: "Мексика",
        MY: "Малайзия",
        MZ: "Мозамбик",
        NA: "Намибия",
        NC: "Новая Каледония",
        NE: "Нигер",
        NF: "Остров Норфолк",
        NG: "Нигерия",
        NI: "Никарагуа",
        NL: "Нидерланды",
        NO: "Норвегия",
        NP: "Непал",
        NR: "Науру",
        NU: "Ниуэ",
        NZ: "Новая Зеландия",
        OM: "Оман",
        OS: "Южная Осетия",
        PA: "Панама",
        PE: "Перу",
        PF: "Французская Полинезия",
        PG: "Папуа - Новая Гвинея",
        PH: "Филиппины",
        PK: "Пакистан",
        PL: "Польша",
        PM: "Сен-Пьер и Микелон",
        PN: "Питкэрн",
        PR: "Пуэрто-Рико",
        PS: "Палестина",
        PT: "Португалия",
        PW: "Палау",
        PY: "Парагвай",
        QA: "Катар",
        RE: "Реюньон",
        RO: "Румыния",
        RS: "Сербия",
        RU: "Россия",
        RW: "Руанда",
        SA: "Саудовская Аравия",
        SB: "Соломоновы острова",
        SC: "Сейшелы",
        SD: "Судан",
        SE: "Швеция",
        SG: "Сингапур",
        SH: "Остров Святой Елены",
        SI: "Словения",
        SJ: "Шпицберген и Ян-Майен",
        SK: "Словакия",
        SL: "Сьерра-Леоне",
        SM: "Сан-Марино",
        SN: "Сенегал",
        SO: "Сомали",
        SR: "Суринам",
        SS: "Южный Судан",
        ST: "Сан-Томе и Принсипи",
        SV: "Сальвадор",
        SX: "Синт-Мартен",
        SY: "Сирия",
        SZ: "Свазиленд",
        TA: "Тристан-да-Кунья",
        TC: "Острова Теркс и Кайкос",
        TD: "Чад",
        TF: "Французские Южные территории",
        TG: "Того",
        TH: "Таиланд",
        TJ: "Таджикистан",
        TK: "Токелау",
        TL: "Восточный Тимор",
        TM: "Туркменистан",
        TN: "Тунис",
        TO: "Тонга",
        TR: "Турция",
        TT: "Тринидад и Тобаго",
        TV: "Тувалу",
        TW: "Тайвань",
        TZ: "Танзания",
        UA: "Украина",
        UG: "Уганда",
        UM: "Внешние малые острова США",
        US: "Соединенные Штаты Америки",
        UY: "Уругвай",
        UZ: "Узбекистан",
        VA: "Святой Престол (город-государство Ватикан)",
        VC: "Сент-Винсент и Гренадины",
        VE: "Венесуэла",
        VG: "Британские Виргинские Острова",
        VI: "Американские Виргинские Острова",
        VN: "Вьетнам",
        VU: "Вануату",
        WF: "Уоллис и Футуна",
        WS: "Самоа",
        XK: "Косово",
        YE: "Йемен",
        YT: "Майотта",
        ZA: "Южная Африка",
        ZM: "Замбия",
        ZW: "Зимбабве",
      },
      finishBtn: "Готово",
      finishBtnInfo: "Учетная запись ShareFile будет создана.",
      modal: {
        cancel: "Отмена",
        cancelAccount: "Деактивировать учетную запись",
        cancelAccountBody: {
          cancelAccount:
            "Действительно отключить учетную запись {accountName}?",
          cancelTrialAccount:
            "Действительно отключить пробную учетную запись {accountName}?",
          para_2:
            "Обратите внимание, что при деактивации учетной записи в конце текущего расчетного периода будет заблокирован доступ арендатора ко всем службам ShareFile.",
          para_3:
            "Это действие является необратимым и может повлиять на текущие проекты арендатора, а также доступ к сохраненным данным.",
        },
        cancelTrial: "Деактивировать пробную версию",
        changePlan: "Изменить план",
        continueTrial: "Продолжить пробную версию",
        convertAccount: "Преобразовать учетную запись",
        convertPlanBody: {
          changeAccount: "Изменить план учетной записи {accountName}.",
          convertAccount:
            "Перевести учетную запись {accountName} с пробного плана на платный.",
          helpText: "Подробнее о типах планов",
          needHelp: "Нужна помощь в выборе? ",
        },
        convertToPaid: "Перейти на платную версию",
        increaseLicense: "Увеличить лицензию",
        keepAccount: "Сохранить учетную запись",
        plans: {
          advanced: "2 — Расширенный",
          advancedSubText:
            "Модули электронной почты, расширенные возможности совместной работы и безопасности",
          premium: "3 – Премиум",
          premiumSubText:
            "Неограниченное число электронных подписей, автоматизированные рабочие процессы, запросы документов, проектные пространства, интеграции",
          standard: "1 – Стандартный",
          standardSubText:
            "Хранение, синхронизация, обмен файлами; мобильные приложения, неограниченное число клиентов",
        },
      },
      phoneNumber: {
        minCharacters: "Требуется минимум четыре цифры.",
      },
      reactSelect: {
        noResults: "Результаты не найдены",
        select: "Искать страну",
      },
      storageZone: {
        addToMultiTenant:
          "Добавить в зону, содержащую несколько арендаторов (размещается у партнера)",
        addToMultiTenantDescription:
          "Зоны хранения с несколькими арендаторами, созданные партнером.",
        addToMultiTenantInfo:
          "Зоны хранения для нескольких арендаторов позволяют создавать единую зону хранения и управлять ею для всех клиентов.",
        learnMore: "Подробнее",
        sharefileCloudHosted: "Хостинг ShareFile Cloud",
        sharefileCloudHostedDescription:
          "Все будет храниться и управляться в облаке ShareFile.",
        standrdPartnerHosted: "Стандартная (размещается у партнера)",
        standrdPartnerHostedDescription:
          "Настройте выделенную зону хранения позже.",
        subTitle: "Выберите зону хранения клиента.",
        title: "Зона хранения",
      },
      tenant: {
        account_name: "Учетная запись арендатора",
        add_tenant: " Добавить арендатора",
        cancelled: "Деактивировано",
        contact_support: "Чтобы изменить арендаторов, заполните форму",
        contact_support_link:
          "запрос пользовательской учетной записи ShareFile CS",
        download_csv: "Загрузить в формате CSV",
        fileName: "Арендатор",
        license_used: "Лицензии",
        matching: "соответствие ключевому слову {{keyword}}",
        none: "Нет",
        paid: "Оплачено",
        planName: "План",
        search_tenants: "Поиск арендаторов",
        status: "Состояние",
        storage_used: "Используемое хранилище",
        storage_zone: "Зона хранения",
        tenant_management: "Управление арендаторами",
        tenants: "Арендаторы: ",
        total_licenses: "Всего использовано лицензий:",
        trial: "Пробная версия",
      },
    },
  },
  {
    key: "zh-CN",
    content: {
      accountDetails: {
        accountType: {
          paidService: "付费服务",
          subTitle: "您需要为分配给用户的许可证付费。",
          title: "帐户类型",
          trial: "试用版",
          trialInfo: "30 天试用期，存储空间有限",
        },
        plan: {
          advanced: "Advanced",
          learnAboutPalnTypes: "了解计划类型",
          premium: "Premium",
          standard: "Standard",
          subTitle: "需要帮助选择吗?",
          title: "计划",
        },
        subDomain: {
          checkAvailability: "检查可用性",
          checkAvailabilityApiErrorMsg: "您指定的子域已被另一个帐户使用",
          checkAvailabilityApiSuccessMsg: "可用",
          controlPlane: "控制平面",
          options: {
            "0": "选择您的国家/地区",
            "1": "美国",
            "2": "欧洲(德国)",
          },
          subTitle:
            "您的子域是您的 ShareFile 帐户的唯一 URL。您以后可以更改此设置。",
          title: "子域",
        },
        supportInfo: {
          areaCode: "区号",
          countryCode: "国家/地区",
          email: "电子邮件",
          enableSupportInfo: "提供租户的支持联系人",
          name: "姓名",
          phoneNumber: "电话号码",
          subTitle:
            "请在此处输入租户的支持联系人详细信息。如果输入，则所有字段均为必填项。",
          title: "支持信息",
        },
        title: "帐户详细信息",
      },
      addTenant: "添加租户",
      addTenantInfo: {
        adminDetails: {
          email: "电子邮件",
          firstName: "名字",
          lastName: "姓氏",
          title: "主管理员",
        },
        bussinessDetails: {
          address: "地址",
          areaCode: "区号",
          city: "城市",
          companyName: "公司名称",
          country: "国家/地区",
          phoneNumber: "电话号码",
          postalCode: "邮政编码",
          postalCodeErrorMsg: "代码无效",
          state: "省/自治区/直辖市",
          title: "业务详细信息",
        },
        subTitle: "输入管理员和租户详细信息",
        termsOfService: "我已阅读、理解并同意",
        termsOfServiceLinkText: "服务条款",
        title: "添加租户信息",
      },
      addTenantSubTitle: "要添加租户，请完成以下步骤。",
      addTenantSuccesMsg: "租户已成功创建",
      backBtn: "上一步",
      country: {
        AC: "阿森松岛",
        AD: "安道尔",
        AE: "阿拉伯联合酋长国",
        AF: "阿富汗",
        AG: "安提瓜和巴布达",
        AI: "安圭拉",
        AL: "阿尔巴尼亚",
        AM: "亚美尼亚",
        AO: "安哥拉",
        AQ: "南极洲",
        AR: "阿根廷",
        AS: "美属萨摩亚",
        AT: "奥地利",
        AU: "澳大利亚",
        AW: "鲁巴岛",
        AX: "奥兰群岛",
        AZ: "阿塞拜疆",
        BA: "波斯尼亚和黑塞哥维那",
        BB: "巴巴多斯",
        BD: "孟加拉国",
        BE: "比利时",
        BF: "布基纳法索",
        BG: "保加利亚",
        BH: "巴林",
        BI: "布隆迪",
        BJ: "贝宁",
        BL: "圣巴泰勒米",
        BM: "百慕大",
        BN: "文莱达鲁萨兰",
        BO: "玻利维亚",
        BQ: "博奈尔、圣尤斯特歇斯和萨巴",
        BR: "巴西",
        BS: "巴哈马",
        BT: "不丹",
        BV: "布维岛",
        BW: "博茨瓦纳",
        BY: "白俄罗斯",
        BZ: "伯利兹",
        CA: "加拿大",
        CC: "科科斯(基林)群岛",
        CD: "刚果民主共和国",
        CF: "中非共和国",
        CG: "刚果",
        CH: "瑞士",
        CI: "科特迪瓦",
        CK: "库克群岛",
        CL: "智利",
        CM: "喀麦隆",
        CN: "中华人民共和国",
        CO: "哥伦比亚",
        CR: "哥斯达黎加",
        CU: "古巴",
        CV: "佛得角",
        CW: "库拉索",
        CX: "圣诞岛",
        CY: "塞浦路斯",
        CZ: "捷克共和国",
        DE: "德国",
        DJ: "吉布提",
        DK: "丹麦",
        DM: "多米尼加",
        DO: "多米尼加共和国",
        DZ: "阿尔及利亚",
        EC: "厄瓜多尔",
        EE: "爱沙尼亚",
        EG: "埃及",
        EH: "西撒哈拉",
        ER: "厄立特里亚",
        ES: "西班牙",
        ET: "埃塞俄比亚",
        FI: "芬兰",
        FJ: "斐济",
        FK: "福克兰群岛",
        FM: "密克罗尼西亚联邦",
        FO: "法罗群岛",
        FR: "法国",
        GA: "加蓬",
        GB: "英国",
        GD: "格林纳达",
        GE: "乔治亚",
        GF: "法属圭亚那",
        GG: "根西岛",
        GH: "加纳",
        GI: "直布罗陀",
        GL: "格陵兰",
        GM: "冈比亚",
        GN: "几尼",
        GP: "瓜德罗普",
        GQ: "赤道几内亚",
        GR: "希腊",
        GS: "南乔治亚和南桑德韦奇群岛",
        GT: "危地马拉",
        GU: "关岛",
        GW: "几内亚比绍",
        GY: "圭亚那",
        HK: "香港特别行政区",
        HM: "赫德岛和麦克唐纳群岛",
        HN: "洪都拉斯",
        HR: "克罗地亚",
        HT: "海地",
        HU: "匈牙利",
        ID: "印度尼西亚",
        IE: "爱尔兰",
        IL: "以色列",
        IM: "马恩岛",
        IN: "印度",
        IO: "英属印度洋领地",
        IQ: "伊拉克",
        IR: "伊朗",
        IS: "冰岛",
        IT: "意大利",
        JE: "泽西",
        JM: "牙买加",
        JO: "约旦",
        JP: "日本",
        KE: "肯尼亚",
        KG: "吉尔吉斯斯坦",
        KH: "柬埔寨",
        KI: "基里巴斯",
        KM: "科摩罗",
        KN: "圣基茨和尼维斯",
        KP: "朝鲜",
        KR: "韩国",
        KW: "科威特",
        KY: "开曼群岛",
        KZ: "哈萨克斯坦",
        LA: "老挝",
        LB: "黎巴嫩",
        LC: "圣卢西亚",
        LI: "列支敦士登",
        LK: "斯里兰卡",
        LR: "利比里亚",
        LS: "莱索托",
        LT: "立陶宛",
        LU: "卢森堡",
        LV: "拉脱维亚",
        LY: "利比亚",
        MA: "摩洛哥",
        MC: "摩纳哥",
        MD: "摩尔多瓦",
        ME: "黑山",
        MF: "圣马丁(法属)",
        MG: "马达加斯加",
        MH: "马绍尔群岛",
        MK: "北马其顿",
        ML: "马里",
        MM: "缅甸",
        MN: "蒙古",
        MO: "澳门特别行政区",
        MP: "北马里亚纳群岛",
        MQ: "马提尼克",
        MR: "毛里塔尼亚",
        MS: "蒙特塞拉特",
        MT: "马耳他",
        MU: "毛里求斯",
        MV: "马尔代夫",
        MW: "马拉维",
        MX: "墨西哥",
        MY: "马来西亚",
        MZ: "莫桑比克",
        NA: "纳米比亚",
        NC: "新喀里多尼亚",
        NE: "尼日尔",
        NF: "诺福克岛",
        NG: "尼日利亚",
        NI: "尼加拉瓜",
        NL: "荷兰",
        NO: "挪威",
        NP: "尼泊尔",
        NR: "瑙鲁",
        NU: "纽埃",
        NZ: "新西兰",
        OM: "阿曼",
        OS: "南奥塞梯",
        PA: "巴拿马",
        PE: "秘鲁",
        PF: "法属波利尼西亚",
        PG: "巴布亚新几内亚",
        PH: "菲律宾",
        PK: "巴基斯坦",
        PL: "波兰",
        PM: "圣皮埃尔和密克隆",
        PN: "皮特凯恩",
        PR: "波多黎各",
        PS: "巴勒斯坦",
        PT: "葡萄牙",
        PW: "帕劳",
        PY: "巴拉圭",
        QA: "卡塔尔",
        RE: "留尼旺",
        RO: "罗马尼亚",
        RS: "塞尔维亚",
        RU: "俄罗斯",
        RW: "卢旺达",
        SA: "沙特阿拉伯",
        SB: "所罗门群岛",
        SC: "塞舌尔",
        SD: "苏丹",
        SE: "瑞典",
        SG: "新加坡",
        SH: "圣赫勒拿",
        SI: "斯洛文尼亚",
        SJ: "斯瓦尔巴和扬马延",
        SK: "斯洛伐克",
        SL: "塞拉利昂",
        SM: "圣马力诺",
        SN: "塞内加尔",
        SO: "索马里",
        SR: "苏里南",
        SS: "南苏丹",
        ST: "圣多美和普林西比",
        SV: "萨尔瓦多",
        SX: "荷属圣马丁",
        SY: "叙利亚",
        SZ: "斯威士兰",
        TA: "特里斯坦达库尼亚",
        TC: "特克斯和凯科斯群岛",
        TD: "乍得",
        TF: "法属南部领地",
        TG: "多哥",
        TH: "泰国",
        TJ: "塔吉克",
        TK: "托克劳",
        TL: "东帝汶",
        TM: "土库曼斯坦",
        TN: "突尼斯",
        TO: "汤加",
        TR: "土耳其",
        TT: "特立尼达和多巴哥",
        TV: "图瓦卢",
        TW: "中国台湾",
        TZ: "坦桑尼亚",
        UA: "乌克兰",
        UG: "乌干达",
        UM: "美国本土外小岛屿",
        US: "美国",
        UY: "乌拉圭",
        UZ: "乌兹别克斯坦",
        VA: "罗马教廷(梵蒂冈城国)",
        VC: "圣文森特和格林纳丁斯",
        VE: "委内瑞拉",
        VG: "英属维尔京群岛",
        VI: "美属维尔京群岛",
        VN: "越南",
        VU: "瓦努阿图",
        WF: "瓦利斯和富图纳",
        WS: "萨摩亚",
        XK: "科索沃",
        YE: "也门",
        YT: "马约特",
        ZA: "南非",
        ZM: "赞比亚",
        ZW: "津巴布韦",
      },
      finishBtn: "完成",
      finishBtnInfo: "将创建 ShareFile 帐户。",
      modal: {
        cancel: "取消",
        cancelAccount: "取消帐户",
        cancelAccountBody: {
          cancelAccount: "是否确实要取消 {accountName} 帐户?",
          cancelTrialAccount: "是否确实要取消 {accountName} 试用帐户?",
          para_2:
            "请注意，取消帐户将阻止租户在当前计费周期结束时访问所有 ShareFile 服务。",
          para_3:
            "此操作不可逆，可能会影响租户正在处理的项目以及对存储数据的访问。",
        },
        cancelTrial: "取消试用",
        changePlan: "更改计划",
        continueTrial: "继续试用",
        convertAccount: "转换帐户",
        convertPlanBody: {
          changeAccount: "更改 {accountName} 帐户的套餐。",
          convertAccount: "将 {accountName} 帐户从试用版转换为付费套餐。",
          helpText: "了解计划类型",
          needHelp: "需要帮助选择吗? ",
        },
        convertToPaid: "转换为付费",
        increaseLicense: "增加许可证",
        keepAccount: "保留帐户",
        plans: {
          advanced: "2 - Advanced",
          advancedSubText: "电子邮件插件、高级协作与安全性",
          premium: "3 - Premium",
          premiumSubText:
            "电子签名、自动化工作流、文档请求、项目空间、集成不受限制",
          standard: "1 - Standard",
          standardSubText: "存储、同步、分享；移动应用程序，客户端不受限制",
        },
      },
      phoneNumber: {
        minCharacters: "至少需要四位数字。",
      },
      reactSelect: {
        noResults: "未找到任何结果",
        select: "搜索国家/地区",
      },
      storageZone: {
        addToMultiTenant: "添加到多租户(合作伙伴托管)",
        addToMultiTenantDescription: "合作伙伴创建的多租户存储区域。",
        addToMultiTenantInfo:
          "多租户存储区域允许您创建和管理用于所有客户的单个存储区域。",
        learnMore: "了解更多",
        sharefileCloudHosted: "ShareFile 云托管",
        sharefileCloudHostedDescription:
          "所有内容都将在 ShareFile 云中存储和管理。",
        standrdPartnerHosted: "标准(合作伙伴托管)",
        standrdPartnerHostedDescription: "稍后设置一个专用存储区域。",
        subTitle: "选择客户的存储区域。",
        title: "存储区域",
      },
      tenant: {
        account_name: "租户帐户",
        add_tenant: " 添加租户",
        cancelled: "已取消",
        contact_support: "要修改您的租户，请填写表单",
        contact_support_link: "CSP ShareFile 客户帐户申请",
        download_csv: "下载为 CSV 格式",
        fileName: "租户",
        license_used: "许可证",
        matching: "匹配 {{keyword}}",
        none: "无",
        paid: "已付款",
        planName: "计划",
        search_tenants: "搜索租户",
        status: "状态",
        storage_used: "已用存储",
        storage_zone: "存储区域",
        tenant_management: "租户管理",
        tenants: "租户: ",
        total_licenses: "使用的总许可证数:",
        trial: "试用版",
      },
    },
  },
];
