import React from "react";

export type TenantInfo = {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  phoneNumber: string;
  address: string;
  city: string;
  country: string;
  zipcode: string;
  state: string;
  isChecked: boolean;
  subDomain: string;
  controlPlaneRegion: string;
  accountType: string;
  plan: string;
  supportInformation: {
    name: string;
    countryCode: string;
    phoneNumber: string;
    email: string;
  };
  selectedStorage: string;
  MultiTenantZoneId?: string;
};

export interface IFormContext {
  tenantInfo: TenantInfo;
  onTenantInfo: (tenant: TenantInfo) => void;
  isFinishBtnClicked: boolean;
  onFinishBtnClick: (bool: boolean) => void;
  userContext: {
    get: () => {
      user: any;
      account: any;
    };
  };
}

export const FormContext = React.createContext<IFormContext>({
  tenantInfo: {
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    phoneNumber: "",
    address: "",
    city: "",
    country: "",
    zipcode: "",
    state: "",
    isChecked: false,
    subDomain: "",
    controlPlaneRegion: "",
    accountType: "",
    plan: "",
    supportInformation: {
      name: "",
      countryCode: "",
      phoneNumber: "",
      email: "",
    },
    selectedStorage: "",
    MultiTenantZoneId: "",
  },
  onTenantInfo: () => null,
  isFinishBtnClicked: false,
  onFinishBtnClick: () => null,
  userContext: {
    get: () => ({
      user: {},
      account: {},
    }),
  },
});
