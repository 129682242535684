import {
  StorageZone,
  StorageZones,
  WebMvcManageZoneEntity,
} from "@citrite/sf-api";
import { LoadingPrimary, Select } from "@sharefiledev/flow-web";
import React, { useContext, useEffect, useState } from "react";

import { RequiredField } from "../../../styled";
import { STORAGEDETAILS, t } from "../../../util";
import { logger } from "../../../util/logger";
import { Collapsable } from "../../common/collapsable/Collapsable";
import { FormContext } from "../../common/form-context/FormContext";
import RadioGroup, {
  RadioGroupItem,
} from "../../common/radio-group/RadioGroup";
import {
  DetailsSection,
  MultiTenantStorageSelect,
  StorageLoader,
  StorageZoneContainer,
  TitleConatiner,
} from "./StorageDetails.styled";

const StorageDetails = () => {
  const addTenantFormContext = useContext(FormContext);
  const { tenantInfo, onTenantInfo, isFinishBtnClicked } = addTenantFormContext;

  const [panel, setPanel] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [multiTenantStorageZones, setMultiTenantStorageZones] = useState<any[]>(
    []
  );

  const { selectedStorage, MultiTenantZoneId } = tenantInfo;

  const onStorageItemClick = (val: string) => {
    onTenantInfo({
      ...tenantInfo,
      selectedStorage: val,
      MultiTenantZoneId: "",
    });
  };

  const onSelectMultiTenantId = (val: string) => {
    onTenantInfo({ ...tenantInfo, MultiTenantZoneId: val });
  };

  useEffect(() => {
    isFinishBtnClicked && !panel && setPanel(true);
  }, [isFinishBtnClicked]);

  useEffect(() => {
    const { selectedStorage } = tenantInfo;
    if (
      (selectedStorage && selectedStorage === "Private_1") ||
      !selectedStorage
    ) {
      setLoading(true);
      WebMvcManageZoneEntity.getZones()
        .execute()
        .then((res: StorageZones) => {
          if (
            res &&
            res.MultiTenantZones &&
            res.MultiTenantZones.Zones &&
            res.MultiTenantZones.Zones.length > 0
          ) {
            const _multiTenantStorageZones: any[] = [
              {
                value: "",
                label: "Select Storage Zone",
                disabled: true,
              },
            ];
            const zones = res.MultiTenantZones.Zones;
            zones.forEach(({ ZoneId, Title }: StorageZone) => {
              _multiTenantStorageZones.push({ value: ZoneId, label: Title });
            });
            setMultiTenantStorageZones([...[], ..._multiTenantStorageZones]);
          }
        })
        .catch((err) =>
          logger.logError(`Error while fetching multi-tenant zones : ${err}`)
        )
        .finally(() => setLoading(false));
    }
  }, [tenantInfo.selectedStorage]);

  const render = (storageDetail: RadioGroupItem, zoneLength = 1) => {
    let _storageDetail = storageDetail;
    if (storageDetail.value === "Private_1" && zoneLength === 0) {
      _storageDetail = {
        ..._storageDetail,
        subText: t("tenant-mgt-ui:storageZone.addToMultiTenantInfo"),
      };
    }
    return (
      <RadioGroup
        item={_storageDetail}
        selectedItem={selectedStorage}
        onClick={onStorageItemClick}
        isError={isFinishBtnClicked && selectedStorage.trim().length === 0}
        isDisabled={zoneLength === 0}
      />
    );
  };

  return (
    <Collapsable
      title={t("tenant-mgt-ui:storageZone.title")}
      open={panel}
      onOpen={() => setPanel(!panel)}
      isDefaultOpen={false}
    >
      <DetailsSection>
        <TitleConatiner>
          <div>
            {t("tenant-mgt-ui:storageZone.subTitle")}
            <RequiredField>*</RequiredField>
          </div>
        </TitleConatiner>
        <StorageZoneContainer>
          {STORAGEDETAILS &&
            STORAGEDETAILS.map((item: RadioGroupItem) => {
              if (
                item.value === "Private_1" &&
                selectedStorage &&
                selectedStorage === "Private_1"
              ) {
                return (
                  <div key={item.value}>
                    <div key={item.value}>
                      {render(item, multiTenantStorageZones.length)}
                    </div>
                    {loading ? (
                      <StorageLoader>
                        <LoadingPrimary />
                      </StorageLoader>
                    ) : (
                      <MultiTenantStorageSelect>
                        <Select
                          required
                          hint=""
                          onChange={onSelectMultiTenantId}
                          options={multiTenantStorageZones}
                          value={MultiTenantZoneId ?? ""}
                          width="300px"
                        />
                        <RequiredField>*</RequiredField>
                      </MultiTenantStorageSelect>
                    )}
                  </div>
                );
              }
              return (
                <div key={item.value}>
                  {render(
                    item,
                    item.value === "Private_1"
                      ? multiTenantStorageZones.length
                      : 1
                  )}
                </div>
              );
            })}
        </StorageZoneContainer>
      </DetailsSection>
    </Collapsable>
  );
};

export default StorageDetails;
