import styled from "@emotion/styled";
import { fromThemeColors, ThemeColor } from "@sharefiledev/flow-web";

export const TenantLayout = styled.div`
  margin: 15px 20px 0 0;
`;

export const Row = styled.div`
  border-radius: 4px;
  background-color: ${fromThemeColors(ThemeColor.secondaryContainerBackground)};
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 20px 20px 0 20px;
  border: 1px solid ${fromThemeColors(ThemeColor.border)};
`;

export const Field = styled.div`
  margin-right: 20px;
  width: 250px;
  .email-field {
    margin-top: 8px;
    position: relative;
    bottom: 15%;
    display: flex;
    justify-content: flex-end;
    color: ${fromThemeColors(ThemeColor.secondaryText)};
    font-size: 12px;
  }
`;
