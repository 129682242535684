const prefix = "tenantMgtPilet_";

// track operations
export const trackCreateTenant = `${prefix}createTenant`;
export const trackCreateTenantInitiated = `${prefix}createTenantInitiated`;
export const trackCreateTenantError = `${prefix}createTenantFailure`;
export const trackCreateTenantSuccess = `${prefix}createTenantSuccess`;
export const trackSubDomainCheck = `${prefix}subDomainCheck`;
export const trackChangePlanInitiated = `${prefix}ChangePlanInitiated`;
export const trackChangePlanSuccess = `${prefix}ChangePlanSuccess`;
export const trackTrialToPaidInitiated = `${prefix}TrialToPaidInitiated`;
export const trackTrialToPaidSuccess = `${prefix}TrialToPaidSuccess`;
export const trackCancelTenantInitiated = `${prefix}CancelTenantInitiated`;
export const trackCancelTenantSuccess = `${prefix}CancelTenantSuccess`;
