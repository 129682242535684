import styled from "@emotion/styled";
import { fromThemeColors, ThemeColor } from "@sharefiledev/flow-web";

export const Search = styled.div`
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
}`;

export const Buttons = styled.div`
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
}`;

export const TotalLicenses = styled.span`
    margin: 0 20px;
}`;

export const RowStyle = styled.div<{ isCancelled?: boolean }>`
  ${(props) =>
    props.isCancelled &&
    `
    color: ${fromThemeColors(ThemeColor.metadataText)(props)};
    a {
        color: inherit;
        text-decoration: none;
        pointer-events: none;
        cursor: pointer;
    }
`}
`;

export const TRHover = styled.tr`
  &:hover {
    background: ${fromThemeColors(ThemeColor.listItemHoverBackground)};
  }
`;
