import { Typeahead } from "@sharefiledev/flow-web";
import React from "react";

import { COUNTRIES, COUNTRY, t } from "../../../util";

type Props = {
  label: string;
  placeholder: string;
  noResultsText: string;
  required: boolean;
  onSelect: (country: COUNTRY) => void;
  selectedItem: string;
};

const CountryField = (props: Props) => {
  const {
    onSelect,
    label,
    placeholder,
    noResultsText,
    required,
    selectedItem,
  } = props;
  return (
    <Typeahead
      required={required}
      searchSync={(input) =>
        COUNTRIES.filter((o) =>
          o.label.toLowerCase().includes(input.toLowerCase())
        )
      }
      onSelect={(selectedCountry) => onSelect(selectedCountry)}
      itemToString={(country) =>
        country?.value &&
        t(`tenant-mgt-ui:country.${country?.value.toUpperCase()}`)
      }
      noResultsText={noResultsText}
      placeholder={placeholder}
      label={label}
      selectedItem={
        selectedItem
          ? COUNTRIES.filter((item) => item.value === selectedItem)[0]
          : null
      }
    />
  );
};

export default CountryField;
