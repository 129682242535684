import { RadioGroupItem } from "../components/common/radio-group/RadioGroup";
import { t } from "../util/translate";
import COUNTRYLIST from "./countries.json";
export const ACCOUNTTYPES: RadioGroupItem[] = [
  {
    title: t("tenant-mgt-ui:accountDetails.accountType.trial"),
    value: "trial",
    subText: t("tenant-mgt-ui:accountDetails.accountType.trialInfo"),
  },
  {
    title: t("tenant-mgt-ui:accountDetails.accountType.paidService"),
    value: "paid_service",
  },
];

export const PLAN: RadioGroupItem[] = [
  {
    title: t("tenant-mgt-ui:accountDetails.plan.standard"),
    value: "ShareFile SO Standard",
  },
  {
    title: t("tenant-mgt-ui:accountDetails.plan.advanced"),
    value: "ShareFile SO Advanced",
  },
  {
    title: t("tenant-mgt-ui:accountDetails.plan.premium"),
    value: "ShareFile SO Premium",
  },
];

export const STORAGEDETAILS: RadioGroupItem[] = [
  {
    title: t("tenant-mgt-ui:storageZone.sharefileCloudHosted"),
    value: "CitrixManaged",
    subText: t("tenant-mgt-ui:storageZone.sharefileCloudHostedDescription"),
  },
  {
    title: t("tenant-mgt-ui:storageZone.addToMultiTenant"),
    value: "Private_1",
    subText: t("tenant-mgt-ui:storageZone.addToMultiTenantDescription"),
    titleInfo: t("tenant-mgt-ui:storageZone.addToMultiTenantInfo"),
    link: "https://docs.sharefile.com/en-us/storage-zones-controller/5-0/install/configure-multi-tenant-storagezones.html#:~:text=Using%20a%20multitenant%20zone%20enables,retention%20preferences%2C%20and%20security%20settings",
    linkText: t("tenant-mgt-ui:storageZone.learnMore"),
  },
  {
    title: t("tenant-mgt-ui:storageZone.standrdPartnerHosted"),
    value: "Private",
    subText: t("tenant-mgt-ui:storageZone.standrdPartnerHostedDescription"),
  },
];

export const TENANTBASEUSERS = 1000;

export const DEFAULT_ERROR_NOTIFICATION_TIMEOUT = 5000;

export const TERMS_OF_SERVICE =
  "https://www.cloud.com/content/dam/cloud/documents/legal/end-user-agreement.pdf";

export type COUNTRY = {
  value: string;
  label: string;
  code: string;
  zipRegx: string;
};

export const COUNTRIES: COUNTRY[] = COUNTRYLIST;

export const MINIMUM_PHONE_NUMBER_LENGTH = 4;

export const ADDTENANT_CHARACTER_LENGTH = {
  firstName: 40,
  lastName: 40,
  email: 80,
  address: 40,
  companyName: 90,
  phoneNumber: 20,
  city: 35,
  zipcode: 10,
  zipCodeWithHypen: 9,
  subDomain: 50,
};

export const TABLE_HEADERS = [
  t("tenant-mgt-ui:tenant.account_name"),
  t("tenant-mgt-ui:tenant.status"),
  t("tenant-mgt-ui:tenant.license_used"),
  t("tenant-mgt-ui:tenant.planName"),
  t("tenant-mgt-ui:tenant.storage_zone"),
  t("tenant-mgt-ui:tenant.storage_used"),
];

export const STANDARD_PLAN = "ShareFile SO Standard";
export const ADVANCED_PLAN = "ShareFile SO Advanced";
export const PREMIUM_PLAN = "ShareFile SO Premium";

export const PLANS: RadioGroupItem[] = [
  {
    title: t("tenant-mgt-ui:modal.plans.standard"),
    value: STANDARD_PLAN,
    subText: t("tenant-mgt-ui:modal.plans.standardSubText"),
  },
  {
    title: t("tenant-mgt-ui:modal.plans.advanced"),
    value: ADVANCED_PLAN,
    subText: t("tenant-mgt-ui:modal.plans.advancedSubText"),
  },
  {
    title: t("tenant-mgt-ui:modal.plans.premium"),
    value: PREMIUM_PLAN,
    subText: t("tenant-mgt-ui:modal.plans.premiumSubText"),
  },
];

export const STANDARD_PLAN_ENT = "SO Standard ENT";
export const ADVANCED_PLAN_ENT = "SO Advanced ENT";
export const PREMIUM_PLAN_ENT = "SO Premium ENT";
export const LICENSE_PERCENTAGE = 75;
export const ENABLE_AFTER_TIME = 5;
