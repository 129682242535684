import styled from "@emotion/styled";
import {
  fromThemeColors,
  ThemeColor,
  typography,
} from "@sharefiledev/flow-web";

export const DetailsSection = styled.div`
  maargin-bottom: 10px;
`;

export const TitleConatiner = styled.div`
  display: flex;
  div {
    font-size: ${typography.weights.light};
    color: ${fromThemeColors(ThemeColor.metadataText)};
  }
`;

export const StorageZoneContainer = styled.div`
  margin-top: 16px;
`;

export const StorageLoader = styled.div`
  margin: 40px 0px;
  position: relative;
  right: 30%;
`;

export const MultiTenantStorageSelect = styled.div`
  display: flex;
  margin: 18px 0 0 28px;
`;
