import styled from "@emotion/styled";
import { SubTitle } from "@sharefiledev/flow-web";

export const MainLayout = styled.div`
    padding: 20px;
}`;

export const SubLayout = styled.div`
    max-width: 950px;
}`;

export const Layout = styled.div`
  margin: 20px 0;
`;

export const SubTitleSection = styled(SubTitle)`
  margin-top: 20px;
`;
