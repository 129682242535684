import {
  Button,
  Checkbox,
  EmailField,
  Link,
  LoadingPrimary,
  Select,
  SubSectionTitle,
  SuccessIcon,
  Text,
  TextField,
  TextInput,
  WarningIcon,
} from "@sharefiledev/flow-web";
import React, { useContext, useEffect, useMemo, useState } from "react";

import { RequiredField } from "../../../styled";
import {
  ACCOUNTTYPES,
  ADDTENANT_CHARACTER_LENGTH,
  COUNTRIES,
  COUNTRY,
  PLAN,
  t,
} from "../../../util";
import { Collapsable } from "../../common/collapsable/Collapsable";
import CountryField from "../../common/country-field/CountryField";
import { FormContext } from "../../common/form-context/FormContext";
import PhoneNumber from "../../common/phone-number/PhoneNumber";
import RadioGroup, {
  RadioGroupItem,
} from "../../common/radio-group/RadioGroup";
import { Field, Row } from "../customer-information/CustomerInformation.styled";
import {
  Block,
  HorizontalLine,
  LoaderStyle,
  RadioGroupElement,
  SectionText,
  StatusMessage,
  SubDomain,
  SubDomainApiStatus,
  SubDomainField,
  SupportInforation,
} from "./AccountDetails.styled";

export type Props = {
  onSubDomainCheck: () => void;
  isLoading: boolean;
  isSubDomainAvailable: boolean | null;
};

const AccountDetails = ({
  onSubDomainCheck,
  isLoading,
  isSubDomainAvailable,
}: Props) => {
  const addTenantFormContext = useContext(FormContext);
  const { tenantInfo, onTenantInfo, isFinishBtnClicked } = addTenantFormContext;

  const [panel, setPanel] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const onAccountSelection = (val: string) => {
    onTenantInfo({ ...tenantInfo, accountType: val });
  };

  const onPlanSelection = (val: string) => {
    onTenantInfo({ ...tenantInfo, plan: val });
  };

  const renderAccounts = (item: RadioGroupItem) => (
    <RadioGroup
      item={item}
      selectedItem={tenantInfo.accountType}
      onClick={onAccountSelection}
      isError={
        isFinishBtnClicked &&
        tenantInfo &&
        tenantInfo.accountType.trim().length === 0
      }
    />
  );

  const renderPlan = (item: RadioGroupItem) => (
    <RadioGroup
      item={item}
      selectedItem={tenantInfo.plan}
      onClick={onPlanSelection}
      isError={
        isFinishBtnClicked && tenantInfo && tenantInfo.plan.trim().length === 0
      }
    />
  );

  const [domainSuffix, setDomainSuffix] = useState(".sharefile.com");

  const onSelectControlPlane = (val: string) => {
    onTenantInfo({
      ...tenantInfo,
      controlPlaneRegion: val,
    });

    let domainSuffixExtention = ".sharefile.com";
    if (val === "EU") {
      domainSuffixExtention = ".sharefile.eu";
    }
    setDomainSuffix(domainSuffixExtention);
    if (subDomain.trim().length != 0) {
      onSubDomainCheck();
    }
  };

  useEffect(() => {
    isFinishBtnClicked && !panel && setPanel(true);
  }, [isFinishBtnClicked]);

  const onSupprotInfoValidation = useMemo(() => {
    const { name, email, countryCode, phoneNumber } =
      tenantInfo.supportInformation;
    if (
      name.trim().length > 0 ||
      email.trim().length > 0 ||
      countryCode.trim().length > 0 ||
      phoneNumber.trim().length > 0
    ) {
      return true;
    }
    return false;
  }, [
    tenantInfo.supportInformation.name,
    tenantInfo.supportInformation.email,
    tenantInfo.supportInformation.countryCode,
    tenantInfo.supportInformation.phoneNumber,
  ]);

  const {
    subDomain,
    supportInformation: { name, countryCode, phoneNumber, email },
  } = tenantInfo;

  const getCountryCode = useMemo(() => {
    if (countryCode) {
      return COUNTRIES.filter((_country) => _country.value === countryCode)[0]
        .code;
    }
    return "";
  }, [countryCode]);

  return (
    <Collapsable
      title={t("tenant-mgt-ui:accountDetails.title")}
      open={panel}
      onOpen={() => setPanel(!panel)}
      isDefaultOpen={false}
    >
      <SubDomain>
        <div>
          <SubSectionTitle>
            {t("tenant-mgt-ui:accountDetails.subDomain.controlPlane")}
            <RequiredField>*</RequiredField>
          </SubSectionTitle>
          <div data-testid="select-elem">
            <Select
              required
              hint=""
              onChange={onSelectControlPlane}
              options={[
                {
                  label: t("tenant-mgt-ui:accountDetails.subDomain.options.0"),
                  value: "",
                },
                {
                  label: t("tenant-mgt-ui:accountDetails.subDomain.options.1"),
                  value: "US",
                },
                {
                  label: t("tenant-mgt-ui:accountDetails.subDomain.options.2"),
                  value: "EU",
                },
              ]}
              value={tenantInfo.controlPlaneRegion}
              width="300px"
            />
          </div>
        </div>
      </SubDomain>

      <SubDomain>
        <div>
          <SubSectionTitle>
            {t("tenant-mgt-ui:accountDetails.subDomain.title")}
            <RequiredField>*</RequiredField>
          </SubSectionTitle>
          <SectionText>
            {t("tenant-mgt-ui:accountDetails.subDomain.subTitle")}
          </SectionText>
          <div className="availability">
            <span className="https-label">
              <Text>https://</Text>
            </span>
            <SubDomainField data-testid="subdomain">
              <div
                className={
                  isSubDomainAvailable !== null && !isSubDomainAvailable
                    ? "subdomainError"
                    : ""
                }
              >
                <TextInput
                  required
                  value={subDomain}
                  onChange={(e) =>
                    onTenantInfo({
                      ...tenantInfo,
                      subDomain: e.replace(/[^a-z0-9]/gi, ""),
                    })
                  }
                  maxLength={ADDTENANT_CHARACTER_LENGTH.subDomain}
                  showCharacterLimit
                />
              </div>
            </SubDomainField>
            <span className="domainSuffix">
              <Text>{domainSuffix}</Text>
            </span>
            {isLoading &&
              subDomain.trim().length > 0 &&
              isSubDomainAvailable === null && (
                <LoaderStyle>
                  <LoadingPrimary />
                </LoaderStyle>
              )}
            <Button.Secondary
              disabled={
                !subDomain ||
                subDomain.trim().length === 0 ||
                tenantInfo.controlPlaneRegion.trim().length === 0
              }
              onClick={onSubDomainCheck}
              data-testid="checkAvailability-btn"
            >
              {t("tenant-mgt-ui:accountDetails.subDomain.checkAvailability")}
            </Button.Secondary>
          </div>
        </div>
        {!isLoading &&
          isSubDomainAvailable !== null &&
          !isSubDomainAvailable && (
            <SubDomainApiStatus>
              <WarningIcon size={24} style={{ marginRight: "16px" }} />
              <StatusMessage>
                {t(
                  "tenant-mgt-ui:accountDetails.subDomain.checkAvailabilityApiErrorMsg"
                )}
              </StatusMessage>
            </SubDomainApiStatus>
          )}
        {!isLoading &&
          isSubDomainAvailable !== null &&
          isSubDomainAvailable && (
            <SubDomainApiStatus>
              <SuccessIcon size={24} style={{ marginRight: "16px" }} />
              <StatusMessage>
                {t(
                  "tenant-mgt-ui:accountDetails.subDomain.checkAvailabilityApiSuccessMsg"
                )}
              </StatusMessage>
            </SubDomainApiStatus>
          )}
      </SubDomain>

      <HorizontalLine />

      <Block>
        <SubSectionTitle>
          {t("tenant-mgt-ui:accountDetails.accountType.title")}
          <RequiredField>*</RequiredField>
        </SubSectionTitle>
        <SectionText>
          {t("tenant-mgt-ui:accountDetails.accountType.subTitle")}
        </SectionText>
        <RadioGroupElement>
          {ACCOUNTTYPES &&
            ACCOUNTTYPES.map((item: RadioGroupItem) => (
              <div key={item.value}>{renderAccounts(item)}</div>
            ))}
        </RadioGroupElement>
      </Block>

      <Block>
        <SubSectionTitle>
          {t("tenant-mgt-ui:accountDetails.plan.title")}
          <RequiredField>*</RequiredField>
        </SubSectionTitle>
        <SectionText>
          {t("tenant-mgt-ui:accountDetails.plan.subTitle")}{" "}
          <Link.Primary href="https://www.sharefile.com/" target="_blank">
            {t("tenant-mgt-ui:accountDetails.plan.learnAboutPalnTypes")}
          </Link.Primary>
        </SectionText>
        <RadioGroupElement>
          {PLAN &&
            PLAN.map((item: RadioGroupItem) => (
              <div key={item.value}>{renderPlan(item)}</div>
            ))}
        </RadioGroupElement>
      </Block>

      <HorizontalLine />

      <Block>
        <SubSectionTitle>
          {t("tenant-mgt-ui:accountDetails.supportInfo.title")}{" "}
        </SubSectionTitle>

        <SectionText data-testid="enable_support_info">
          <Checkbox
            checked={isChecked}
            label={t(
              "tenant-mgt-ui:accountDetails.supportInfo.enableSupportInfo"
            )}
            onChange={() => setIsChecked(!isChecked)}
          />
        </SectionText>

        {isChecked && (
          <div>
            <SectionText>
              {isChecked &&
                t("tenant-mgt-ui:accountDetails.supportInfo.subTitle")}
            </SectionText>
          </div>
        )}

        {isChecked && (
          <SupportInforation>
            <Row>
              <Field data-testid="supportinfo_name">
                <TextField
                  label={t("tenant-mgt-ui:accountDetails.supportInfo.name")}
                  value={name}
                  required={isChecked && onSupprotInfoValidation}
                  onChange={(e) =>
                    onTenantInfo({
                      ...tenantInfo,
                      supportInformation: {
                        ...tenantInfo.supportInformation,
                        name: e.replace(/[^a-z ]/gi, ""),
                      },
                    })
                  }
                />
              </Field>

              <Field data-testid="supportinfo_countrycode">
                <CountryField
                  required={isChecked && onSupprotInfoValidation}
                  noResultsText={t("tenant-mgt-ui:reactSelect.noResults")}
                  placeholder={t("tenant-mgt-ui:reactSelect.select")}
                  label={t(
                    "tenant-mgt-ui:accountDetails.supportInfo.countryCode"
                  )}
                  selectedItem={countryCode}
                  onSelect={(selectedCountry: COUNTRY) =>
                    onTenantInfo({
                      ...tenantInfo,
                      supportInformation: {
                        ...tenantInfo.supportInformation,
                        countryCode: selectedCountry
                          ? selectedCountry.value
                          : "",
                      },
                    })
                  }
                />
              </Field>
              <Field data-testid="supportinfo_phonenumber">
                <PhoneNumber
                  label={t(
                    "tenant-mgt-ui:accountDetails.supportInfo.phoneNumber"
                  )}
                  required={isChecked && onSupprotInfoValidation}
                  countryCode={getCountryCode}
                  phoneNumber={phoneNumber}
                  showCharacterLimit={false}
                  maxLength={undefined}
                  onChange={(e) =>
                    onTenantInfo({
                      ...tenantInfo,
                      supportInformation: {
                        ...tenantInfo.supportInformation,
                        phoneNumber: e.replace(/[^0-9]/gi, ""),
                      },
                    })
                  }
                />
              </Field>
              <Field data-testid="supportinfo_email">
                <EmailField
                  label={t("tenant-mgt-ui:accountDetails.supportInfo.email")}
                  value={email}
                  required={isChecked && onSupprotInfoValidation}
                  onChange={(e) =>
                    onTenantInfo({
                      ...tenantInfo,
                      supportInformation: {
                        ...tenantInfo.supportInformation,
                        email: e,
                      },
                    })
                  }
                />
              </Field>
            </Row>
          </SupportInforation>
        )}
      </Block>
    </Collapsable>
  );
};

export default AccountDetails;
