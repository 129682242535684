export const tenantFilter = function <T>(
  searchString: string,
  properties: string[],
  array: T[]
) {
  const result: T[] = [];
  const keywords = parseKeywords(searchString);

  array.forEach((element) => {
    const matchedKeywords: string[] = [];
    keywords.forEach((key: string) => {
      key = key.toLowerCase();
      properties.forEach((propertyName: string) => {
        const property = resolvePropertyByName(propertyName, element);

        if (
          property &&
          typeof property === "string" &&
          containsIgnoreCase(property, key)
        ) {
          if (!matchedKeywords.includes(key)) {
            matchedKeywords.push(key);
          }
        }
      });
    });
    if (matchedKeywords.length === keywords.length) {
      result.push(element);
    }
  });

  return result;
};

export const resolvePropertyByName = function <T>(path: string, element: T) {
  return path.split(".").reduce((prev: any, curr) => {
    return prev ? prev[curr] : null;
  }, element);
};

/**
 * parse the search string user entered into a list of keywords. By default
 * the keywords are separated by one or more white spaces, unless we use group char
 * to group them
 *
 * @searchString the raw string user entered for searching
 */
export const parseKeywords = function (searchString: string) {
  const groupBy = "'";

  searchString = searchString.trim();
  const result: string[] = [];

  const groupedString: string[] = [];
  let groupStart = 0;
  let isInGroup = false;

  for (let i = 0; i < searchString.length; i++) {
    if (searchString[i] === groupBy) {
      if (!isInGroup) {
        groupStart = i;
        isInGroup = true;
      } else {
        groupedString.push(searchString.substring(groupStart, i + 1));
        isInGroup = false;
      }
    }
  }

  groupedString.forEach((str: string) => {
    searchString = searchString.replace(str, " ");
    result.push(str.replace(new RegExp(groupBy, "g"), " ").trim());
  });

  const split = searchString.split(/\s+/);
  split.forEach((keyword) => {
    if (keyword) {
      result.push(keyword);
    }
  });

  return result;
};

export const containsIgnoreCase = function (str: string, keyword: string) {
  return str.toLowerCase().includes(keyword.toLowerCase());
};
