import styled from "@emotion/styled";
import { fromThemeColors, ThemeColor } from "@sharefiledev/flow-web";

export const Text = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
`;

export const SectionText = styled.div`
  color: ${fromThemeColors(ThemeColor.primaryText)};
  text-align: left;
  display: inline-block;
  font-size: 14px;
  margin-top: 8px;
`;
