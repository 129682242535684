import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
} from "@sharefiledev/flow-web";
import React from "react";

import {
  ButtonGroupLeft,
  ButtonWrapper,
  Header,
  ModalBodyWrapper,
} from "./ModalTemplate.styled";

type CustomModalProps = {
  isOpen: boolean;
  header: string;
  children: React.ReactNode;
  buttonProps: {
    saveButtonProps?: {
      buttonText: string;
      onClick?: () => void;
      active?: boolean;
    };
    cancelButtonProps?: {
      buttonText: string;
      onClick?: () => void;
      disabled?: boolean;
    };
  };
};

export const ModalTemplate = ({
  isOpen,
  header,
  children,
  buttonProps,
}: CustomModalProps) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onDismiss={buttonProps.cancelButtonProps?.onClick}
        autoFocus={false}
      >
        <ModalHeader>
          <Header>{header}</Header>
        </ModalHeader>
        <ModalBodyWrapper>{children}</ModalBodyWrapper>

        <ModalFooter>
          <ButtonWrapper>
            <ButtonGroupLeft>
              {buttonProps.saveButtonProps && (
                <Button.Primary
                  onClick={buttonProps.saveButtonProps?.onClick}
                  active={buttonProps.saveButtonProps?.active}
                >
                  {buttonProps.saveButtonProps?.buttonText}
                </Button.Primary>
              )}
              {buttonProps.cancelButtonProps && (
                <Button.Secondary
                  onClick={buttonProps.cancelButtonProps?.onClick}
                  disabled={buttonProps.cancelButtonProps?.disabled}
                >
                  {buttonProps.cancelButtonProps?.buttonText}
                </Button.Secondary>
              )}
            </ButtonGroupLeft>
          </ButtonWrapper>
        </ModalFooter>
      </Modal>
    </>
  );
};
