import { ZoneType } from "@citrite/sf-api";
import {
  Button,
  FormWithValidation,
  LightText,
  PageTitle,
} from "@sharefiledev/flow-web";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  createFreeTrailAccount,
  getSubdomainAvailability,
} from "../../api/services/tenantApi";
import {
  trackCreateTenantError,
  trackCreateTenantInitiated,
  trackCreateTenantSuccess,
  trackSubDomainCheck,
} from "../../tracking/events";
import { pendoSendTrackEvent } from "../../tracking/pendo";
import {
  COUNTRIES,
  MINIMUM_PHONE_NUMBER_LENGTH,
  t,
  TENANTBASEUSERS,
} from "../../util";
import { logger } from "../../util/logger";
import { FreeTrialAccountType } from "../../util/types";
import { FormContext } from "../common/form-context/FormContext";
import { toastNotification } from "../common/toast-notification/toastNotificationHandler";
import AccountDetails from "./account-details/AccountDetails";
import { Layout, SubLayout, SubTitleSection } from "./AddTenant.styled";
import CustomerInformation from "./customer-information/CustomerInformation";
import StorageDetails from "./storage-details/StorageDetails";

type SubDominaApiResponse = {
  subDomainText: string;
  isAvailable: boolean | null;
};

type Props = {
  tenants: Array<any>;
};

const AddTenant = (props: Props) => {
  const history = useHistory();

  const addTenantFormContext = useContext(FormContext);

  const { tenantInfo, onTenantInfo, onFinishBtnClick, userContext } =
    addTenantFormContext;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subDominaApiRes, setSubDominaApiRes] = useState<SubDominaApiResponse>({
    subDomainText: "",
    isAvailable: null,
  });

  const onCancelTenant = () => {
    onBack();
  };

  const onBack = () => {
    onFinishBtnClick(false);
    // clearing the context data
    onTenantInfo({
      ...tenantInfo,
      firstName: "",
      lastName: "",
      email: "",
      companyName: "",
      phoneNumber: "",
      address: "",
      city: "",
      country: "",
      zipcode: "",
      state: "",
      isChecked: false,
      subDomain: "",
      accountType: "",
      plan: "",
      selectedStorage: "",
      MultiTenantZoneId: "",
      supportInformation: {
        ...tenantInfo.supportInformation,
        countryCode: "",
        phoneNumber: "",
        email: "",
        name: "",
      },
    });
    history.push({
      pathname: "/settings/admin/advancedpreferences/managetenants",
      state: {
        params: props.tenants,
      },
    });
  };

  const onSubmitClicked = () => {
    onFinishBtnClick && onFinishBtnClick(true);
  };

  useEffect(() => {
    if (
      tenantInfo.subDomain.trim().length === 0 ||
      (subDominaApiRes.subDomainText &&
        tenantInfo.subDomain &&
        subDominaApiRes.subDomainText.trim() !== tenantInfo.subDomain.trim())
    ) {
      setSubDominaApiRes({ subDomainText: "", isAvailable: null });
    }
  }, [tenantInfo.subDomain]);

  const onFormSubmit = async () => {
    const {
      isChecked,
      selectedStorage,
      accountType,
      plan,
      subDomain,
      phoneNumber,
      supportInformation,
    } = tenantInfo;
    if (
      isChecked &&
      selectedStorage &&
      accountType &&
      plan &&
      (subDominaApiRes.isAvailable === null || subDominaApiRes.isAvailable) &&
      phoneNumber.trim().length >= MINIMUM_PHONE_NUMBER_LENGTH &&
      (supportInformation.phoneNumber.trim().length === 0 ||
        supportInformation.phoneNumber.trim().length >=
          MINIMUM_PHONE_NUMBER_LENGTH)
    ) {
      // if form is valid, subdomain textbox contains value if is not validated, click on submit initially it will call the
      // subdomain availability check api method.
      if (
        (subDominaApiRes.isAvailable === null || subDominaApiRes.isAvailable) &&
        subDomain.trim().length !== 0 &&
        subDominaApiRes.subDomainText !== subDomain
      ) {
        onSubDomainCheck(true);
      } else {
        setIsLoading(true);
        // subdomain textbox is vaidated (or) if it is empty, it directly calls create-free-trail-account api.
        onCreateFreeTrailAccount();
      }
    }
  };

  /**
   * This function calls the create free trail account api.
   */
  const onCreateFreeTrailAccount = async () => {
    pendoSendTrackEvent(trackCreateTenantInitiated);
    const {
      selectedStorage,
      accountType,
      plan,
      subDomain,
      firstName,
      lastName,
      email,
      companyName,
      city,
      zipcode,
      state,
      address,
      country,
      controlPlaneRegion,
      supportInformation,
      MultiTenantZoneId,
    } = tenantInfo;
    try {
      let _tenantInfo: FreeTrialAccountType = {
        BillingContact: {
          FirstName: firstName,
          LastName: lastName,
          Email: email,
        },
        Address1: address,
        City: city,
        Country: country.toUpperCase(),
        Zip: zipcode,
        State: state,
        Phone: `${
          COUNTRIES.filter((_country) => _country.value === country)[0].code
        }${tenantInfo.phoneNumber}`,
        TrialInfo: {
          IsFreeTrial: accountType === "trial" ? true : false,
        },
        IsFreeTrial: accountType === "trial" ? true : false,
        CompanyName: companyName,
        Subdomain: subDomain,
        ControlPlaneRegion: controlPlaneRegion,
        PlanName: plan,
        TenantOptions: {
          PartnerAccountId: userContext.get().account.Id,
          PartnerAdminEmail: userContext.get().user.Email,
        },
        BaseUsers: TENANTBASEUSERS,
        Preferences: {
          DefaultZone: {
            ZoneType:
              selectedStorage === "CitrixManaged"
                ? ZoneType.CitrixManaged
                : ZoneType.Private,
          },
        },
      };
      if (MultiTenantZoneId) {
        _tenantInfo = {
          ..._tenantInfo,
          TenantOptions: {
            ..._tenantInfo.TenantOptions,
            MultiTenantZoneId: MultiTenantZoneId,
          },
        };
      }
      const { countryCode, phoneNumber } = supportInformation;
      if (supportInformation.email && countryCode && phoneNumber) {
        _tenantInfo = {
          ..._tenantInfo,
          SupportInfo: {
            ..._tenantInfo.SupportInfo,
            EmailAddresses: [
              {
                Email: supportInformation.email,
                Name: supportInformation.name,
              },
            ],
            PhoneNumbers: [
              {
                Name: supportInformation.name,
                CountryCode: COUNTRIES.filter(
                  (_country) =>
                    _country.value === supportInformation.countryCode
                )[0].code,
                Number: supportInformation.phoneNumber.slice(
                  3,
                  supportInformation.phoneNumber.length
                ),
                AreaCode: supportInformation.phoneNumber.slice(0, 3),
              },
            ],
          },
        };
      }
      try {
        const res: any = await createFreeTrailAccount(_tenantInfo);
        if (res && res.Id) {
          setIsLoading(false);
          pendoSendTrackEvent(trackCreateTenantSuccess);
          history.push({
            pathname: "/settings/admin/advancedpreferences/managetenants",
          });
          toastNotification({
            message: `${t("tenant-mgt-ui:addTenantSuccesMsg")}`,
            type: "SUCCESS",
          });
        }
        logger.logInfo(`Tenant created successfully`);
      } catch (err) {
        setIsLoading(false);
        pendoSendTrackEvent(trackCreateTenantError);
        logger.logError(`Error while creating tenant: ${err}`);
        toastNotification({ message: `${err}`, type: "ERROR" });
      }
    } catch (err) {
      setIsLoading(false);
      logger.logError(`Error while creating tenant: ${err}`);
    }
  };

  /**
   * This function will call the subdomain availability check.
   * @param isFreeTrail its an boolean value if it is true after subdomain check, it will call create-free-trail-account api as well.
   */
  const onSubDomainCheck = async (isFreeTrail = false) => {
    pendoSendTrackEvent(trackSubDomainCheck);
    setIsLoading(true);
    try {
      const res: boolean = await getSubdomainAvailability(
        tenantInfo.subDomain,
        tenantInfo.controlPlaneRegion
      );
      setSubDominaApiRes({
        subDomainText: tenantInfo.subDomain,
        isAvailable: res,
      });
      if (isFreeTrail && res) {
        onCreateFreeTrailAccount();
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setSubDominaApiRes({ subDomainText: "", isAvailable: null });
      setIsLoading(false);
      logger.logError(
        `Error while fetching subdomain availability check: ${err}`
      );
      toastNotification({ message: `${err}`, type: "ERROR" });
    }
  };

  return (
    <>
      <SubLayout>
        <PageTitle>{t("tenant-mgt-ui:addTenant")}</PageTitle>
        <SubTitleSection>
          {t("tenant-mgt-ui:addTenantSubTitle")}
        </SubTitleSection>
        <FormWithValidation onSubmit={onFormSubmit}>
          <Layout>
            <CustomerInformation />
          </Layout>
          <Layout>
            <AccountDetails
              onSubDomainCheck={() => onSubDomainCheck(false)}
              isSubDomainAvailable={subDominaApiRes.isAvailable}
              isLoading={isLoading}
            />
          </Layout>
          <Layout>
            <StorageDetails />
          </Layout>
          <div>
            <Button.Secondary
              style={{ marginRight: "16px" }}
              onClick={onCancelTenant}
            >
              {t("tenant-mgt-ui:backBtn")}
            </Button.Secondary>
            <Button.Primary
              type="submit"
              style={{ marginRight: "16px" }}
              onClick={onSubmitClicked}
              active={isLoading}
            >
              {t("tenant-mgt-ui:finishBtn")}
            </Button.Primary>
            <LightText>{t("tenant-mgt-ui:finishBtnInfo")}</LightText>
          </div>
        </FormWithValidation>
      </SubLayout>
    </>
  );
};

export default AddTenant;
