import React, { useState } from "react";

import { cancelAccount } from "../../api/services/tenantApi";
import {
  pendoSendTrackEvent,
  trackCancelTenantInitiated,
  trackCancelTenantSuccess,
} from "../../tracking";
import { t } from "../../util";
import { logger } from "../../util/logger";
import { ModalTemplate } from "../common/modal-template/ModalTemplate";
import { toastNotification } from "../common/toast-notification/toastNotificationHandler";
import { Text } from "./CancelTenantOrTrial.styled";

type Props = {
  data?: any;
  onClose?(bool: boolean): void;
};

const CancelTenantTrial = ({ data, onClose }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const IsFreeTrial = data?.account?.IsFreeTrial;
  const header = IsFreeTrial
    ? t("tenant-mgt-ui:modal.cancelTrial")
    : t("tenant-mgt-ui:modal.cancelAccount");

  const buttonText = IsFreeTrial
    ? {
        saveButton: t("tenant-mgt-ui:modal.cancelTrial"),
        closeButton: t("tenant-mgt-ui:modal.continueTrial"),
      }
    : {
        saveButton: t("tenant-mgt-ui:modal.cancelAccount"),
        closeButton: t("tenant-mgt-ui:modal.keepAccount"),
      };

  const handleCancellation = async () => {
    pendoSendTrackEvent(trackCancelTenantInitiated);
    setIsLoading(true);
    try {
      const res: any = await cancelAccount(data.account.Id);
      if (res) {
        pendoSendTrackEvent(trackCancelTenantSuccess);
        setIsLoading(false);
        toastNotification({
          message: `Cancelled successfully`,
          type: "SUCCESS",
        });
        logger.logInfo(`Account cancelled successfully`);
        closeModal(true);
      } else {
        closeModal();
        logger.logError(`Error while cancelling account`);
      }
    } catch (err) {
      closeModal();
      setIsLoading(false);
      logger.logError(`Error while cancelling account: ${err}`);
      toastNotification({ message: `${err}`, type: "ERROR" });
    }
  };

  const closeModal = (bool = false) => {
    onClose && onClose(bool);
  };

  const getHtmlObject = (text: string) => ({
    __html: text.replace(
      "{accountName}",
      `<b>${data.account && data.account.CompanyName}</b>`
    ),
  });

  return (
    <>
      {
        <ModalTemplate
          isOpen
          header={header}
          buttonProps={{
            saveButtonProps: {
              buttonText: buttonText.saveButton,
              onClick: handleCancellation,
              active: isLoading,
            },
            cancelButtonProps: {
              buttonText: buttonText.closeButton,
              onClick: () => closeModal(),
              disabled: isLoading,
            },
          }}
        >
          <>
            <Text>
              {IsFreeTrial ? (
                <div
                  dangerouslySetInnerHTML={getHtmlObject(
                    t(
                      "tenant-mgt-ui:modal.cancelAccountBody.cancelTrialAccount"
                    )
                  )}
                />
              ) : (
                <div
                  dangerouslySetInnerHTML={getHtmlObject(
                    t("tenant-mgt-ui:modal.cancelAccountBody.cancelAccount")
                  )}
                />
              )}
            </Text>
            <Text>{t("tenant-mgt-ui:modal.cancelAccountBody.para_2")}</Text>
            <Text>{t("tenant-mgt-ui:modal.cancelAccountBody.para_3")}</Text>
          </>
        </ModalTemplate>
      }
    </>
  );
};

export default CancelTenantTrial;
