import {
  NoncriticalBadge,
  RoleLabelBadge,
  SuccessBadge,
} from "@sharefiledev/flow-web";
import React, { memo, useMemo } from "react";
// Returns respective badge based on the parameter passed.
export const BadgeComponent = memo(({ status }: { status: string }) => {
  const renderStatus = useMemo(
    () => (Badgestatus: string) => {
      let updatedStatus;
      switch (status) {
        case "Paid":
          updatedStatus = <SuccessBadge>{Badgestatus}</SuccessBadge>;
          break;
        case "Trial":
          updatedStatus = <RoleLabelBadge>{Badgestatus}</RoleLabelBadge>;
          break;
        default:
          updatedStatus = <NoncriticalBadge>{Badgestatus}</NoncriticalBadge>;
      }
      return updatedStatus;
    },
    [status]
  );

  return <>{renderStatus(status)}</>;
});

BadgeComponent.displayName = "BadgeComponent";
