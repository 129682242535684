import {
  Checkbox,
  EmailField,
  Link,
  SubSectionTitle,
  TextField,
} from "@sharefiledev/flow-web";
import React, { useContext, useMemo, useState } from "react";

import { RequiredField } from "../../../styled";
import {
  ADDTENANT_CHARACTER_LENGTH,
  COUNTRIES,
  COUNTRY,
  t,
  TERMS_OF_SERVICE,
} from "../../../util";
import { Collapsable } from "../../common/collapsable/Collapsable";
import CountryField from "../../common/country-field/CountryField";
import { FormContext } from "../../common/form-context/FormContext";
import PhoneNumber from "../../common/phone-number/PhoneNumber";
import { Field, Row, TenantLayout } from "./CustomerInformation.styled";

export type UserInfo = {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  phoneNumber: string;
  address: string;
  city: string;
  areaCode: string;
  country: string;
  zipcode: string;
  isChecked: boolean;
};

const CustomerInformation = () => {
  const addTenantFormContext = useContext(FormContext);
  const { tenantInfo, onTenantInfo, isFinishBtnClicked } = addTenantFormContext;

  const {
    firstName,
    lastName,
    email,
    companyName,
    address,
    city,
    phoneNumber,
    zipcode,
    state,
    country,
    isChecked,
  } = tenantInfo;

  const getCountryCode = useMemo(() => {
    if (country) {
      return COUNTRIES.filter((_country) => _country.value === country)[0].code;
    }
    return "";
  }, [country]);

  const getZipCodeMaxLength = useMemo(() => {
    if (zipcode.includes("-")) {
      return ADDTENANT_CHARACTER_LENGTH.zipCodeWithHypen;
    } else {
      return ADDTENANT_CHARACTER_LENGTH.zipcode;
    }
  }, [zipcode]);

  const [postalCodeErrorMsg, setPostalCodeErrorMsg] = useState("");

  const getZipCodeValue = useMemo(() => {
    if (country) {
      const regxVal = COUNTRIES.filter(
        (_country) => _country.value === country
      )[0].zipRegx;
      if (regxVal && new RegExp(regxVal).test(zipcode.trim())) {
        setPostalCodeErrorMsg("");
      } else {
        setPostalCodeErrorMsg(
          t("tenant-mgt-ui:addTenantInfo.bussinessDetails.postalCodeErrorMsg")
        );
      }
      return zipcode;
    }
    return "";
  }, [zipcode]);

  return (
    <Collapsable
      title={t("tenant-mgt-ui:addTenantInfo.title")}
      open
      isDefaultOpen
    >
      <TenantLayout>
        <SubSectionTitle>
          {t("tenant-mgt-ui:addTenantInfo.adminDetails.title")}
        </SubSectionTitle>
        <Row>
          <Field data-testid="first_name">
            <TextField
              required
              label={t("tenant-mgt-ui:addTenantInfo.adminDetails.firstName")}
              value={firstName}
              onChange={(e) =>
                onTenantInfo({
                  ...tenantInfo,
                  firstName: e.replace(/[^a-z ]/gi, ""),
                })
              }
              maxLength={ADDTENANT_CHARACTER_LENGTH.firstName}
              showCharacterLimit
            />
          </Field>
          <Field data-testid="last_name">
            <TextField
              required
              label={t("tenant-mgt-ui:addTenantInfo.adminDetails.lastName")}
              value={lastName}
              onChange={(e) =>
                onTenantInfo({
                  ...tenantInfo,
                  lastName: e.replace(/[^a-z ]/gi, ""),
                })
              }
              maxLength={ADDTENANT_CHARACTER_LENGTH.lastName}
              showCharacterLimit
            />
          </Field>
          <Field data-testid="email">
            <EmailField
              required
              label={t("tenant-mgt-ui:addTenantInfo.adminDetails.email")}
              value={
                email && email.trim().length > ADDTENANT_CHARACTER_LENGTH.email
                  ? email.slice(0, ADDTENANT_CHARACTER_LENGTH.email)
                  : email
              }
              onChange={(e) => onTenantInfo({ ...tenantInfo, email: e })}
              maxLength={ADDTENANT_CHARACTER_LENGTH.email}
            />
            <div className="email-field">
              <div>
                {email.length > ADDTENANT_CHARACTER_LENGTH.email - 1
                  ? ADDTENANT_CHARACTER_LENGTH.email
                  : email.length}
                /{ADDTENANT_CHARACTER_LENGTH.email}
              </div>
            </div>
          </Field>
        </Row>
        <SubSectionTitle>
          {t("tenant-mgt-ui:addTenantInfo.bussinessDetails.title")}
        </SubSectionTitle>
        <Row>
          <Field data-testid="company_name">
            <TextField
              label={t(
                "tenant-mgt-ui:addTenantInfo.bussinessDetails.companyName"
              )}
              required
              value={companyName}
              onChange={(e) => onTenantInfo({ ...tenantInfo, companyName: e })}
              maxLength={ADDTENANT_CHARACTER_LENGTH.companyName}
              showCharacterLimit
            />
          </Field>
          <Field data-testid="address">
            <TextField
              label={t("tenant-mgt-ui:addTenantInfo.bussinessDetails.address")}
              required
              value={address}
              onChange={(e) => onTenantInfo({ ...tenantInfo, address: e })}
              maxLength={ADDTENANT_CHARACTER_LENGTH.address}
              showCharacterLimit
            />
          </Field>
          <Field data-testid="city">
            <TextField
              label={t("tenant-mgt-ui:addTenantInfo.bussinessDetails.city")}
              value={city}
              required
              onChange={(e) =>
                onTenantInfo({
                  ...tenantInfo,
                  city: e.replace(/[^a-z ]/gi, ""),
                })
              }
              maxLength={ADDTENANT_CHARACTER_LENGTH.city}
              showCharacterLimit
            />
          </Field>
          <Field data-testid="state">
            <TextField
              required
              label={t("tenant-mgt-ui:addTenantInfo.bussinessDetails.state")}
              value={state}
              onChange={(e) =>
                onTenantInfo({
                  ...tenantInfo,
                  state: e.replace(/[^a-z ]/gi, ""),
                })
              }
            />
          </Field>
          <Field data-testid="country-field">
            <CountryField
              required
              noResultsText={t("tenant-mgt-ui:reactSelect.noResults")}
              placeholder={t("tenant-mgt-ui:reactSelect.select")}
              label={t("tenant-mgt-ui:addTenantInfo.bussinessDetails.country")}
              selectedItem={country}
              onSelect={(selectedCountry: COUNTRY) =>
                onTenantInfo({
                  ...tenantInfo,
                  zipcode: "",
                  country: selectedCountry ? selectedCountry.value : "",
                })
              }
            />
          </Field>
          <Field data-testid="postal_code">
            <TextField
              label={t(
                "tenant-mgt-ui:addTenantInfo.bussinessDetails.postalCode"
              )}
              value={getZipCodeValue}
              required
              onChange={(e) =>
                onTenantInfo({
                  ...tenantInfo,
                  zipcode: e.replace(/[^0-9a-zA-Z\s-]/gi, ""),
                })
              }
              warningMessage={postalCodeErrorMsg}
              maxLength={getZipCodeMaxLength}
              showCharacterLimit
            />
          </Field>
          <Field>
            <PhoneNumber
              label={t(
                "tenant-mgt-ui:addTenantInfo.bussinessDetails.phoneNumber"
              )}
              countryCode={getCountryCode}
              phoneNumber={phoneNumber}
              required
              showCharacterLimit
              maxLength={ADDTENANT_CHARACTER_LENGTH.phoneNumber}
              onChange={(e) =>
                onTenantInfo({
                  ...tenantInfo,
                  phoneNumber: e.replace(/[^0-9]/gi, ""),
                })
              }
            />
          </Field>
        </Row>
        <div data-testid="terms_and_conditions">
          <Checkbox
            checked={isChecked}
            hasError={!isChecked && isFinishBtnClicked}
            label={t("tenant-mgt-ui:addTenantInfo.termsOfService")}
            onChange={() =>
              onTenantInfo({ ...tenantInfo, isChecked: !isChecked })
            }
          />
          &nbsp;
          <Link.Primary target="_blank" href={TERMS_OF_SERVICE}>
            {t("tenant-mgt-ui:addTenantInfo.termsOfServiceLinkText")}
          </Link.Primary>
          <RequiredField>*</RequiredField>
        </div>
      </TenantLayout>
    </Collapsable>
  );
};

export default CustomerInformation;
