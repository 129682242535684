import styled from "@emotion/styled";
import { fromThemeColors, ThemeColor } from "@sharefiledev/flow-web";

export const Container = styled.div`
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  .radio-item {
    margin-left: 4px;
    div:nth-of-type(1) {
      display: flex;
      div:nth-of-type(1) {
        font-size: 14px;
      }
    }
  }
  .sub-text {
    margin-top: 5px;
    margin-left: 28px;
    margin-top: 8px;
    color: ${fromThemeColors(ThemeColor.metadataText)};
  }
`;

export const BadgeStatus = styled.span<{
  color: string | undefined;
  bgColor: string | undefined;
}>`
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  padding: 0px 8px;
  min-width: 64px;
  border-radius: 2px;
  box-sizing: border-box;
  margin-left: 20px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
`;
