import { Api } from "@citrite/sf-api";
import type { PiletApi } from "@sharefiledev/sharefile-appshell";
import React from "react";

import AppRoute from "./routes/AppRoute";
import { setLogger } from "./util/logger";

export const setup = (app: PiletApi) => {
  setLogger(app.sf.getLogger());

  configureApiClients(app);
  app.registerExtension(
    "urn:sfextslot:sharefile-appshell:settings:admin-settings:tenant-management",
    (props) => <AppRoute app={app} {...props.params} />
  );
};

const configureApiClients = (app: PiletApi) => {
  app.sf.registerSfApiConfigHandler(Api.configure);
};
