import {
  ArrowDownIcon,
  Button,
  ExpandableSection,
  ExpandableSectionHelpers,
  SectionTitle,
} from "@sharefiledev/flow-web";
import React, { ReactNode } from "react";

import { CollapsableSubTitle, StyledSection } from "./Collapsable.styled";

type ExpandableProps = {
  title: string;
  children: ReactNode;
  subTitle?: string;
  open: boolean;
  onOpen?: () => void;
  isDefaultOpen: boolean;
};

export const Collapsable = ({
  title,
  subTitle,
  children,
  open,
  onOpen,
  isDefaultOpen,
}: ExpandableProps) => {
  const renderChildren = (expandableProps: ExpandableSectionHelpers) => {
    return (
      <div {...expandableProps.getContentProps()} style={{ marginTop: "16px" }}>
        {subTitle && <CollapsableSubTitle>{subTitle}</CollapsableSubTitle>}
        {children}
      </div>
    );
  };

  return (
    <ExpandableSection expanded={open} data-testid="expand">
      {(expandableProps) => (
        <StyledSection
          data-testid="expandable-section"
          isDefaultOpen={isDefaultOpen}
        >
          <Button.Wrapper
            {...expandableProps.getControlProps()}
            onClick={onOpen}
            data-testid="toggle-collapsable"
          >
            <div>
              <SectionTitle>{title}</SectionTitle>
              {!isDefaultOpen && (
                <ArrowDownIcon
                  style={{ transform: `rotate(${open ? "0" : "180"}deg)` }}
                />
              )}
            </div>
          </Button.Wrapper>
          {open && renderChildren(expandableProps)}
        </StyledSection>
      )}
    </ExpandableSection>
  );
};
