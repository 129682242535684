import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

import AddTenant from "../add-tenant/AddTenant";
import { TenantInfo } from "../common/form-context/FormContext";
import { FormContextProvider } from "../common/form-context/FormContextProvider";

type Props = {
  userContext: {
    get: () => {
      user: any;
      account: any;
    };
  };
};

const AddTenantForm = (props: Props) => {
  const history: any = useHistory();

  const [isFinishBtnClick, setIsFinishBtnClick] = useState(false);
  const [tenatInfo, setTenantInfo] = useState<TenantInfo>({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    phoneNumber: "",
    address: "",
    city: "",
    country: "",
    zipcode: "",
    state: "",
    isChecked: false,
    subDomain: "",
    accountType: "",
    plan: "",
    supportInformation: {
      name: "",
      countryCode: "",
      phoneNumber: "",
      email: "",
    },
    selectedStorage: "",
    MultiTenantZoneId: "",
    controlPlaneRegion: "",
  });

  const onTenantInfo = (tenant: TenantInfo) => {
    setTenantInfo(tenant);
  };

  const onFinishBtnClick = useCallback(
    (bool: boolean) => {
      setIsFinishBtnClick(bool);
    },
    [isFinishBtnClick]
  );

  const tenants = history.location.state && history.location.state.params;

  return (
    <FormContextProvider
      tenantInfo={tenatInfo}
      onTenantInfo={onTenantInfo}
      isFinishBtnClicked={isFinishBtnClick}
      onFinishBtnClick={onFinishBtnClick}
      userContext={props.userContext}
    >
      <AddTenant tenants={tenants} />
    </FormContextProvider>
  );
};

export default AddTenantForm;
