import { Link, Radio, Tooltip } from "@sharefiledev/flow-web";
import React from "react";

import { BadgeStatus, Container } from "./RadioGroup.styled";

export type RadioGroupItem = {
  title: string;
  value: string;
  titleInfo?: string;
  subText?: string;
  linkText?: string;
  link?: string;
  badgeText?: string;
  badgeColor?: string;
  badgeBackgroundColor?: string;
};

type Props = {
  item: RadioGroupItem;
  onClick: (e: string) => void;
  selectedItem: string;
  isError: boolean;
  isDisabled?: boolean;
};

const RadioGroup = (props: Props) => {
  const { item, onClick, selectedItem, isError, isDisabled } = props;
  const {
    title,
    subText,
    titleInfo,
    value,
    linkText,
    link,
    badgeText,
    badgeColor,
    badgeBackgroundColor,
  } = item;

  return (
    <Container key={value} data-testid="radio-elem">
      <Radio
        disabled={isDisabled}
        checked={value === selectedItem}
        hasError={isError}
        onChange={() => onClick(value)}
      >
        <div className="radio-item">
          <div>
            <div>{title}</div>
            {badgeText && badgeColor && (
              <BadgeStatus color={badgeColor} bgColor={badgeBackgroundColor}>
                {badgeText}
              </BadgeStatus>
            )}
            {titleInfo && <Tooltip>{titleInfo}</Tooltip>}
          </div>
        </div>
      </Radio>
      {subText && (
        <div className="sub-text">
          {subText}&ensp;
          {value === "Private_1" && (
            <Link.Primary href={link} target="_blank">
              {linkText}
            </Link.Primary>
          )}
        </div>
      )}
    </Container>
  );
};

export default RadioGroup;
