import { FreeTrialAccount } from "@citrite/sf-api";

import {
  AccountObjChangePlan,
  AccountObjIncreaseLicense,
  AccountObjTrialUpgrade,
  FreeTrialAccountType,
} from "../../util/types";
import apiClient from "../apiClient";

export const getSubdomainAvailability = async (
  domain: string,
  controlPlaneRegion: string
) => {
  try {
    const response: boolean = await apiClient.get(
      `accounts/subdomainAvailability?name=${domain}&ControlPlaneRegion=${controlPlaneRegion}`
    );
    return response;
  } catch (error) {
    throw new Error(
      `Error while finding the subdomain availability check: ${error}`
    );
  }
};

export const createFreeTrailAccount = async (
  freeTrialAccount: FreeTrialAccountType
) => {
  try {
    const response = await apiClient.post<FreeTrialAccountType>(
      `accounts/CreateFreeTrialAccount`,
      freeTrialAccount
    );
    return response;
  } catch (error) {
    throw new Error(`Error while creating the free trail account: ${error}`);
  }
};

export const cancelAccount = async (id: string) => {
  try {
    const response = await apiClient.post<FreeTrialAccount>(
      `accounts/Cancellation`,
      { id: id }
    );
    return response;
  } catch (error) {
    throw new Error(`Error while cancelling account: ${error}`);
  }
};

export const convertTrialToPaid = async (
  accountBody: AccountObjTrialUpgrade
) => {
  try {
    const response = await apiClient.patch<AccountObjTrialUpgrade>(
      `accounts/TrialUpgrade`,
      accountBody
    );
    return response;
  } catch (error) {
    throw new Error(`Error while converting trial to paid account: ${error}`);
  }
};

export const changePlan = async (accountBody: AccountObjChangePlan) => {
  try {
    const response = await apiClient.put<AccountObjChangePlan>(
      `accounts/ChangePlan`,
      accountBody
    );
    return response;
  } catch (error) {
    throw new Error(`Error while changing plan: ${error}`);
  }
};

export const increaseLicense = async (
  accountBody: AccountObjIncreaseLicense
) => {
  try {
    const response = await apiClient.patch<AccountObjIncreaseLicense>(
      `accounts/Licenses`,
      accountBody
    );
    return response;
  } catch (error) {
    throw new Error(`Error while increasing license: ${error}`);
  }
};

export const fetchTenantList = async (partnerAccountId: string) => {
  try {
    const response: any = await apiClient.get(
      `accounts/Tenants?partnerAccountId=${partnerAccountId}`
    );
    return response;
  } catch (error) {
    throw new Error(`Error while fetching the tenat list: ${error}`);
  }
};
