import styled from "@emotion/styled";
import { fromThemeColors, ThemeColor } from "@sharefiledev/flow-web";

export const SubDomain = styled.div`
  margin-bottom: 16px;
  .availability {
    display: flex;
    align-items: flex-start;
    padding: 16px 0 8px 16px;
    .https-label {
      margin-top: 10px;
    }
    .domainSuffix {
      margin: 10px 16px 0 0;
      width: 10%;
    }
  }
`;

export const SubDomainField = styled.div`
  width: 250px;
  margin: 0 4px 0 8px;
  .subdomainError {
    div {
      div {
        background-color: ${fromThemeColors(ThemeColor.inputBackground)};
        border-radius: 4px;
        border-color: ${fromThemeColors(ThemeColor.errorBorder)};
      }
    }
  }
`;

export const Block = styled.div`
  margin: 16px 0 8px 0;
`;

export const RadioGroupElement = styled.div`
  padding: 0 0 8px 16px;
`;

export const HorizontalLine = styled.div`
  border: 1px solid;
  color: ${fromThemeColors(ThemeColor.border)};
`;

export const SectionText = styled.div`
  color: ${fromThemeColors(ThemeColor.primaryText)};
  text-align: left;
  display: inline-block;
  font-size: 14px;
  margin-top: 8px;
`;
export const SupportInforation = styled.div`
  margin: 15px 20px 0 0;
`;

export const LoaderStyle = styled.div`
  margin: 0 40px;
`;

export const SubDomainApiStatus = styled.div`
  display: flex;
  align-items: center;
  max-width: 50%;
  position: relative;
  left: 8%;
`;

export const StatusMessage = styled.div`
  color: ${fromThemeColors(ThemeColor.primaryText)};
  text-align: left;
  display: inline-block;
  font-size: 16px;
`;
