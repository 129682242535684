import styled from "@emotion/styled";
import { fromThemeColors, ThemeColor } from "@sharefiledev/flow-web";

export const Container = styled.div`
  display: flex;
`;

export const PhoneNumberField = styled.div`
  label {
    span {
      display: none !important;
    }
  }
`;

export const Label = styled.label`
  color: rgb(23, 23, 23);
  font-size: 14px;
  font-weight: 600;
  margin: 0px 0px 4px;
  span {
    color: ${fromThemeColors(ThemeColor.errorBorder)};
  }
`;

export const PhoneNumberErrorField = styled.div`
  position: relative;
  bottom: 15%;
  margin: -4px -1px 4px -1px;

  .container {
    position: absolute;
    width: 217px;
    top: 71%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    -webkit-align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    color: ${fromThemeColors(ThemeColor.primaryText)};
    border-radius: 4px;
    border-color: ${fromThemeColors(ThemeColor.errorBorder)};
    background-color: ${fromThemeColors(ThemeColor.errorBackground)};
    padding: 8px 16px 8px;
    border-style: solid;
    border-width: 1px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    box-shadow: inset 0 0 0 1px ${fromThemeColors(ThemeColor.errorBorder)};
  }
`;
